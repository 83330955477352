import { useEffect, useState } from "react";
import { ReactComponent as Search } from "../../Images/search.svg";
import WalletRequest from "../../../utils/services/walletRequests";
import { CurrencyFormatter, formatDate } from "../../../utils/helpers/helper";
const WalletDetialsTable = ({ data, onClick, dollar }) => {
  const [search, setSearch] = useState(false);
  const [dollarRate, setDollarRate] = useState([]);

  const getDollarLogs = async () => {
    await WalletRequest.getDollarLogs().then((res) => {

      setDollarRate(res.data.result);
    });
  };

  useEffect(() => {
    getDollarLogs();
  }, []);
  return (
    <div className="h-fit w-full whitespace-nowrap  overflow-x-scroll bg-white rounded-xl shadow flex-col justify-center items-start gap-[24px] inline-flex">
      {/* <div className="flex justify-between items-center w-full p-[20px]">
        {search ? (
          <div
            onClick={() => setSearch(false)}
            className="self-stretch flex md:hidden gap-[12px] w-full px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#aab0b6] justify-start items-center  text-[#6c757d] text-base font-normal leading-normal"
          >
            <Search />
            <input
              className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full"
              placeholder="Search"
            />
          </div>
        ) : (
          <div
            onClick={() => {
              setSearch(true);
            }}
            className="px-3.5 py-2.5 flex md:hidden"
          >
            <Search />
          </div>
        )}

        <div className="self-stretch gap-[12px] hidden md:flex w-[400px] px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#aab0b6] justify-start items-center  text-[#6c757d] text-base font-normal leading-normal">
          <Search />
          <input
            className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full"
            placeholder="Search"
          />
        </div>
      </div> */}

      <div className="self-stretch w-full border-t border-t-[#CDD1D4] flex justify-between items-center">
        {["ID", "Donator", "Date", "Amount Recieved", "Exchange Rate"]?.map((header) => (
          <div key={header} className="w-full flex flex-col justify-start items-start">
            <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium leading-[18px]">{header}</div>
            {data?.map((item, index) => (
              <div key={item?.id} className={`h-[72px] w-full px-6 py-4 ${index % 2 === 0 ? "bg-[#f5f6f6]" : "bg-white"} justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal leading-[18px]`}>
                {header === "ID" && item?.id}
                {header === "Donator" && <div className=" text-pretty">{item?.donator}</div>}
                {header === "Date" && formatDate(new Date(item?.date))}
                {header === "Amount Recieved" && <div>{dollar === false ? <>{CurrencyFormatter(parseInt(item?.amountReceived || 0))}</> : <>{CurrencyFormatter(parseInt(item?.amountReceived || 0), "$")}</>}</div>}

                {header === "Exchange Rate" && <div className=" text-sm font-medium leading-tight flex  gap-4">{dollarRate.length !== 0 && <>{CurrencyFormatter(dollarRate[0].amount || 0, "$")}</>}</div>}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default WalletDetialsTable;
