import { CurrencyFormatter, formatDate } from "../../../utils/helpers/helper";
import { ReactComponent as Arrow } from "../../Images/arrowUpTilt.svg";
import { ReactComponent as ArrowDown } from "../../Images/arrow-down-left-01.svg";

const TransHistory = ({ data, dollar }) => {
  ;
  const groupByDate = (data) => {
    return data.reduce((acc, item) => {
      const date = formatDate(new Date(item?.date));
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
  };

  const groupedData = groupByDate(data);
  return (
    <div className=" w-full py-2.5 flex-col justify-start items-start gap-4 inline-flex">
      {/* <h4 className="self-stretch text-[#6c757d] text-xs font-medium ">
        9th July 2024
      </h4> */}
      {Object.keys(groupedData).map((date) => (
        <>
          {/* Render the date only once for all transactions on that day */}
          <h3 className="text-[10px] text-[#3a3c3f] font-medium leading-[14px]">{date}</h3>

          {groupedData[date].map((items) => (
            <div key={items.id} className="w-full border-b py-[8px] px-[4px] border-b-[#E5E7E8] flex-col justify-start items-start gap-1.5 flex">
              <div className="w-full justify-start items-center gap-4 inline-flex">
                <div className="p-[5px] bg-[#e5e7e8] rounded-[999px] justify-center items-center gap-2.5 flex">{items.status === "RECEIVED" ? <ArrowDown /> : <Arrow />}</div>
                <div className="w-full flex-col justify-start items-start gap-1.5 inline-flex">
                  <h3 className="text-[#3a3c3f] text-xs font-normal leading-[18px]">{items?.donator === "null" ? "Anonymous" : items?.donator}</h3>
                  <h5 className={`self-stretch ${items.status === "RECEIVED" ? "text-[#58d073]" : "text-[#0C4AFF]"} text-[10px] font-medium leading-[14px]`}>{items?.status.charAt(0).toUpperCase() + items?.status.slice(1).toLowerCase()}</h5>
                </div>
                <div className="w-fit whitespace-nowrap flex-col justify-end items-end gap-1.5 inline-flex">
                  <h4 className="text-[#4a4f54] text-sm font-medium">
                    {/* {dollar === false ? "+" : "-"} */}
                    {dollar === false ? CurrencyFormatter(parseFloat(items?.amountReceived || 0)) : CurrencyFormatter(parseFloat(items?.amountReceived || 0), "$")}
                  </h4>
                  <h5 className="self-stretch text-right text-[#808990] text-[10px] font-medium">
                    Rate: <span className="text-[#4a4f54] font-medium">{CurrencyFormatter(parseFloat(items?.exchangeRate || 0))}</span>
                  </h5>
                </div>
              </div>
            </div>
          ))}
        </>
      ))}
      {/* {data.map((items) => (
        <>
          <h3 className="text-[10px] text-[#3a3c3f] font-medium  leading-[14px]"> {formatDate(new Date(items?.date))} </h3>
          <div className=" w-full border-b py-[8px] px-[4px] border-b-[#E5E7E8] flex-col justify-start items-start gap-1.5 flex">
            <div className=" w-full justify-start items-center gap-4 inline-flex">
              <div className="p-[5px] bg-[#e5e7e8] rounded-[999px] justify-center items-center gap-2.5 flex">{items.status === "RECEIVED" ? <ArrowDown /> : <Arrow />}</div>
              <div className="w-full flex-col justify-start items-start gap-1.5 inline-flex">
                <h3 className="text-[#3a3c3f] text-xs font-normal  leading-[18px]">{items?.donator === "null" ? "Anonymous" : items?.donator}</h3>
                <h5 className={`self-stretch ${items.status === "RECEIVED" ? "text-[#58d073]" : "text-[#0C4AFF]"} text-[10px] font-medium  leading-[14px]`}>
                  {items?.status.charAt(0).toUpperCase() + items?.status.slice(1).toLowerCase()}

                </h5>
              </div>
              <div className=" w-fit whitespace-nowrap flex-col justify-end items-end gap-1.5 inline-flex">
                <h4 className="text-[#4a4f54] text-sm font-medium ">
                  {dollar === false ? <>{CurrencyFormatter(parseFloat(items?.amountReceived || 0))}</> : <>{CurrencyFormatter(parseFloat(items?.amountReceived || 0), "$")}</>}
                </h4>
                <h5 className="self-stretch text-right text-[#808990] text-[10px] font-medium ">
                  Rate:
                  <span className="text-[#4a4f54] font-medium "> {CurrencyFormatter(parseFloat(items?.exchangeRate || 0))}</span>
                </h5>
              </div>
            </div>
          </div>
        </>
      ))} */}
    </div>
  );
};

export default TransHistory;
