// validators.ts
import * as yup from "yup";

export const loginMailValidator = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup.string().required("Password is required"),
});

export const loanApplicationValidator = yup.object().shape({
  // loan_amount: yup.string().required("This is required"),
  paymentTerms: yup.string().required("This is required"),
  // document: yup.string().required("This is required"),
});

export const confirmPaymentValidator = yup.object().shape({
  email: yup.string().email("Invalid email").required("This is required"),
  bank: yup.string().required("This is required"),
  accountNumber: yup.string().required("This is required"),
});


export const approveUserValidator = yup.object().shape({
  comment: yup.string().required("Comment is required"),
  // failedResponse: yup.array().required()
});

export const forgotPasswordValidator = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
});

export const userRequestValidator = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  comment: yup.string().required("Comment is required"),
});

export const visitorSubscribeValidator = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
});

export const createAccountValidator = yup.object().shape({
  firstName: yup.string().required("first name is required"),
  lastName: yup.string().required("last name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  createPassword: yup.string().required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("createPassword"), null], "Passwords must match")
    .required("Confirm password is required"),
  houseAddress: yup.string().required("Address is required"),
  photo: yup.string().required("Photo is required"),
  phoneNumber: yup.string().required("Phone Number is required"),
  // username: yup.string().required("username is required"),
  terms: yup.bool().required("terms is required"),
});

export const createCampaignValidator = yup.object().shape({
  campaignTitle: yup.string().required("Title is required"),
  description: yup.string().required("description is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  // fundingGoal: yup.string().required("Goal is required"),
  campaignVideo: yup.string().required("Video is required"),
  campaignImage: yup.string().required("Image is required"),
});

export const createEducationValidator = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  linkedInAddress: yup.string().required("this is required"),
  passportNumber: yup.string().required("this is required"),
  bvn: yup.string().length(11).required("this is required"),
  internationalPassport: yup.string().required("this is required"),
  twitterLink: yup.string().optional(),
  instagramLink: yup.string().optional(),
  facebookProfile: yup.string().optional(),
  university: yup.string().required("this is required"),
  certificate: yup.string().required("this is required"),
  transcript: yup.string().required("this is required"),
  schoolAdmitted: yup.string().required("this is required"),
  courseOfStudy: yup.string().required("this is required"),
  country: yup.string().required("this is required"),
  durationOfStudy: yup.string().required("this is required"),
  admissionLetter: yup.string().required("this is required"),
  employmentStatus: yup.string().required("this is required"),
  yearsOfWorkExperience: yup.string().required("this is required"),
  currentEmployer: yup.string().required("this is required"),
  role: yup.string().required("this is required"),
  monthsWithEmployer: yup.string().required("this is required"),
  employmentLetter: yup.string().required("this is required"),
  bankStatement: yup.string().required("this is required"),
  currency: yup.string().required("this is required"),
  tuitionFee: yup.string().required("this is required"),
  livingExpenses: yup.string().required("this is required"),
  totalFundingRequirement: yup.string().required("this is required"),
  amountAvailable: yup.string().required("this is required"),
});
