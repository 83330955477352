import { useState } from "react";
import SideBar from "./SideBar";
// import { ReactComponent as Icon } from "../Images/money.svg";
import LoanOverview from "./Loan/LoanOverview";
import NewLoan from "./Loan/NewLoan";
import History from "./Loan/History";
import RepaymentSchedule from "./Loan/RepaymentSchedule";

function Loan() {
  const [loanMenu, setLoanMenu] = useState("new-loan-application");

  const toggleLoanMenu = (menu) => {
    setLoanMenu(menu);
  };
  return (
    <>
      <SideBar />
      <div className=" flex 100:justify-center items-center lg:justify-start flex-col 100:pt-[120px] md:pt-[120px] lg:pt-[40px] md:py-[32px]  lg:pr-8 w-full lg:pl-[352px]">
        <div className="flex items-center 100:w-[85%] lg:w-full  justify-center flex-col">
          <div className="flex flex-col items-start justify-start gap-[32px] w-full">
            <h3 className="text-[#3a3c3f] text-2xl font-medium leading-[38px]">Loan </h3>
            <div className="w-full flex flex-col 100:overflow-x-scroll md:overflow-hidden justify-start border-b-[#cdd1d4] border-b-[1px]">
              <div className="md:w-fit  100:w-full whitespace-nowrap text-[#6c757d] text-sm font-medium  leading-tight flex justify-start items-center gap-[16px]">
                {/* <button className={`h-[38px] px-[4px] pb-[8px] ${loanMenu === "loan-overview" ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]" : ""}`} onClick={() => toggleLoanMenu("loan-overview")}>
                  Loan Overview
                </button> */}
                <button className={`h-[38px] px-[4px] pb-[8px] ${loanMenu === "new-loan-application" ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]" : ""}`} onClick={() => toggleLoanMenu("new-loan-application")}>
                  New Loan Application
                </button>
                <button className={`h-[38px] px-[4px] pb-[8px] ${loanMenu === "loan-history" ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]" : ""}`} onClick={() => toggleLoanMenu("loan-history")}>
                  Loan History
                </button>{" "}
                <button className={`h-[38px] px-[4px] pb-[8px] ${loanMenu === "repayment-schedule" ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]" : ""}`} onClick={() => toggleLoanMenu("repayment-schedule")}>
                  Repayment Schedule
                </button>{" "}
              </div>
            </div>
            <div className=" w-full">
              {/* {loanMenu === "loan-overview" && <LoanOverview />} */}
              {loanMenu === "new-loan-application" && <NewLoan />}
              {loanMenu === "loan-history" && <History />}
              {loanMenu === "repayment-schedule" && <RepaymentSchedule />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Loan;
