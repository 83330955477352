import { Link } from "react-router-dom";
import group from "../Images/Group.png";

function EmpowerDreams() {
  return (
    <>
      <div className=" h-fit w-full py-[96px] flex justify-center items-center">
        <section className="100:w-[85%] xl:w-[1216px] 100:flex-col lg:flex-row   lg:w-full lg:px-[32px] gap-[64px] flex justify-between items-center  ">
          <div className=" 100:w-full lg:w-[572px]  gap-[40px] text-center flex flex-col items-start justify-start">
            <section className="  gap-[20px] text-left flex flex-col items-start justify-start">
              <h2 className=" font-semibold  text-[#103A9F] text-[48px] tracking-[-2px] leading-[60px] ">Empower your dreams with Ije</h2>
              <p className="text-[20px]  leading-[30px]  text-[#6C757D] w-full font-normal">Join a community that's committed to your success. Create your campaign or contribute to someone's dream today!</p>
            </section>
            <section className=" 100:flex-col phone:flex-row  w-full  flex justify-start items-start gap-[12px]">
              <Link onClick={() => window.scrollTo(0, 0)} className=" phone:w-fit 100:w-full" to="/campaign">
                <div className=" shadow border border-gray-400 leading-[20px] text-[16px]  rounded-[8px]  text-[#4A4F54] font-medium h-fit px-[20px] py-[12px] 100:w-full phone:w-fit"> Contribute to Others</div>
              </Link>
              <Link onClick={() => window.scrollTo(0, 0)} className=" phone:w-fit 100:w-full" to="/auth/register">
                <div className="leading-[20px] border-[#0C4AFF] border-solid border-[1px] rounded-[8px]  text-[16px] bg-[#0C4AFF]  text-[#fff] font-medium h-fit px-[20px] py-[12px] 100:w-full phone:w-fit"> Create a Campaign</div>
              </Link>
            </section>
          </div>
          <div className=" lg:w-[572px] 100:w-full">
            <img className=" w-full" src={group} alt="Ije" />
          </div>
        </section>
      </div>
    </>
  );
}

export default EmpowerDreams;
