import { useState } from "react";
import { ReactComponent as Arrow } from "../Images/arrow.svg";
import { ReactComponent as Dot } from "../Images/dot.svg";

function Paginator({ total, itemsPerPage, currentPage, onPageChange }) {
    const totalPages = Math.ceil(total / itemsPerPage);
  
    // Function to generate page numbers to display
    const getPageNumbers = () => {
      const pages = [];
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
      return pages;
    };
  
    const handleNext = () => {
      if (currentPage < totalPages) onPageChange(currentPage + 1);
    };
  
    const handlePrevious = () => {
      if (currentPage > 1) onPageChange(currentPage - 1);
    };
  
    return (
      <div className="md:px-[32px] pt-[11px] pb-[16px] border-t border-t-[#CDD1D4] w-full flex justify-end items-center">
        <div className="h-10 shadow bg-white rounded-lg border divide-x-[1px] border-[#aab0b6] flex">
          {/* Previous Button */}
          <div
            className={`w-full h-full flex justify-center items-center text-sm font-medium gap-[8px] px-[16px] py-[10px] leading-tight ${
              currentPage === 1
                ? "text-[#b3dfff]"
                : "text-[#0c4aff] cursor-pointer"
            }`}
            onClick={handlePrevious}
          >
            <Arrow /> Previous
          </div>
  
          {/* Page Numbers */}
          {getPageNumbers().map((page) => (
            <div
              key={page}
              className={`w-full h-full flex justify-center items-center text-sm font-medium gap-[8px] px-[16px] py-[10px] leading-tight ${
                page === currentPage
                  ? "bg-[#e8f7ff] text-[#4a4f54]"
                  : "text-[#4a4f54] cursor-pointer"
              }`}
              onClick={() => onPageChange(page)}
            >
              {page}
            </div>
          ))}
  
          {/* Next Button */}
          <div
            className={`w-full h-full flex justify-center items-center text-sm font-medium gap-[8px] px-[16px] py-[10px] leading-tight ${
              currentPage === totalPages
                ? "text-[#b3dfff]"
                : "text-[#0c4aff] cursor-pointer"
            }`}
            onClick={handleNext}
          >
            Next <Arrow className="rotate-180" />
          </div>
        </div>
      </div>
    );
  }

export default Paginator;
