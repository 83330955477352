import { useState } from "react";
import SideBar from "./SideBar";
import { useAppSelector } from "../../utils/redux/store";
import illustration from "../Images/form.svg";
import { ReactComponent as Completed } from "../Images/complete.svg";
import { ReactComponent as Pending } from "../Images/pending.svg";
import { ReactComponent as Reject } from "../Images/reject.svg";
import BasicInfo from "./Registration/BasicInfo";
import IdentityVerification from "./Registration/IdentityVerification";
import Education from "./Registration/Education";
import EmploymentHistory from "./Registration/EmploymentHistory";
import FinancialSummary from "./Registration/FinancialSummary";

function Registration() {
  //   const [isData, setData] = useState({});
  const [stages, setStages] = useState("basic-information");
  const { firstName, acceptanceStatus, educationInformation, lastName } =
    useAppSelector((state) => state.auth);
  const toggleStages = (menu) => {
    setStages(menu);
  };

  console.log(educationInformation?.stage);

  return (
    <>
      <SideBar />
      <div className=" flex 100:justify-center items-center lg:justify-start flex-col 100:pt-[120px] md:pt-[120px] lg:pt-[40px] md:py-[32px]  lg:pr-8 w-full lg:pl-[352px]">
        <div className="flex items-center 100:w-[85%] lg:w-full gap-[32px]  justify-center flex-col">
          <div className="flex flex-col items-start justify-start gap-[20px] w-full">
            <h3 className="text-[rgb(58,60,63)] text-2xl font-medium leading-[38px]">
              Hello, {firstName} {lastName}!{" "}
            </h3>
            <div className="lg:h-[260.75px] 100:h-full w-full px-8 py-6 bg-[#d5efff] rounded-2xl shadow 100:flex-col md:flex-row 100:gap-8 lg:gap-0 justify-between items-start inline-flex">
              <div className=" 100:w-full  lg:w-[521px]  flex-col justify-start items-start gap-3 inline-flex">
                <h3 className="self-stretch text-[#3a3c3f] 100:text-base sm:text-xl font-medium font-inter leading-normal">
                  Complete Your Registration Process
                </h3>
                <p className="self-stretch text-[#565c64] 100:text-sm  sm:text-base font-normal font-inter leading-normal">
                  Please ensure your documents (ID, proof of address, etc.) are
                  valid and up-to-date. You can review and edit your information
                  anytime before final submission.
                </p>
              </div>
              <div className="100:w-full md:w-fit">
                <img src={illustration} alt="illustration" />
              </div>
            </div>
            <div className="w-full flex flex-col 100:overflow-x-scroll md:overflow-hidden justify-start border-b-[#cdd1d4] border-b-[1px]">
              <div className="md:w-fit list-decimal  100:w-full whitespace-nowrap text-[#6c757d] text-sm font-medium  leading-tight flex justify-start items-center gap-[16px]">
                <button
                  className={`h-[38px] px-[4px] pb-[8px] w-fit flex justify-center items-center gap-2 ${
                    stages === "basic-information"
                      ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]"
                      : ""
                  }`}
                  onClick={() => toggleStages("basic-information")}
                >
                  1. Basic Information
                  {/* {acceptanceStatus === "ACCEPT" ? ( */}
                  <div className="h-[20px] w-[20px] mix-blend-multiply   bg-[#F1FCF2] leading-[18px] text-center text-[#1F7634] text-xs font-medium rounded-2xl justify-center items-center flex">
                    {" "}
                    <Completed />{" "}
                  </div>
                  {/* ) : acceptanceStatus === "REJECT" ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-red-100 leading-[18px] text-center text-red-800 text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Reject />{" "}
                    </div>
                  ) : (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-[#ffffea] leading-[18px] text-center text-[#bb6902] text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Pending />
                    </div>
                  )} */}
                </button>
                <button
                  className={`h-[38px] px-[4px] pb-[8px] w-fit flex justify-center items-center gap-2 ${
                    stages === "identity-verification"
                      ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]"
                      : ""
                  }`}
                  onClick={() => toggleStages("identity-verification")}
                >
                  2. Identity Verification
                  {educationInformation?.acceptanceStatus === "PENDING" ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-[#ffffea] leading-[18px] text-center text-[#bb6902] text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Pending />
                    </div>
                  ) : educationInformation?.stage > 1 &&
                    !educationInformation?.failedPages?.includes(1) ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-[#F1FCF2] leading-[18px] text-center text-[#1F7634] text-xs font-medium rounded-2xl justify-center items-center flex">
                      {" "}
                      <Completed />{" "}
                    </div>
                  ) : educationInformation?.failedPages?.includes(1) ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-red-100 leading-[18px] text-center text-red-800 text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Reject />{" "}
                    </div>
                  ) : null}
                </button>

                <button
                  className={`h-[38px] px-[4px] pb-[8px] w-fit flex justify-center items-center gap-2 ${
                    stages === "education"
                      ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]"
                      : ""
                  }`}
                  onClick={() => toggleStages("education")}
                >
                  3. Education
                  {educationInformation?.acceptanceStatus === "PENDING" ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-[#ffffea] leading-[18px] text-center text-[#bb6902] text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Pending />
                    </div>
                  ) : educationInformation?.stage > 2 &&
                    !educationInformation?.failedPages?.includes(2) ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-[#F1FCF2] leading-[18px] text-center text-[#1F7634] text-xs font-medium rounded-2xl justify-center items-center flex">
                      {" "}
                      <Completed />{" "}
                    </div>
                  ) : educationInformation?.failedPages?.includes(2) ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-red-100 leading-[18px] text-center text-red-800 text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Reject />{" "}
                    </div>
                  ) : null}
                </button>
                <button
                  className={`h-[38px] px-[4px] pb-[8px] w-fit flex justify-center items-center gap-2 ${
                    stages === "employment-history"
                      ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]"
                      : ""
                  }`}
                  onClick={() => toggleStages("employment-history")}
                >
                  4. Employment History
                  {educationInformation?.acceptanceStatus === "PENDING" ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-[#ffffea] leading-[18px] text-center text-[#bb6902] text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Pending />
                    </div>
                  ) : educationInformation?.stage > 3 &&
                    !educationInformation?.failedPages?.includes(3) ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-[#F1FCF2] leading-[18px] text-center text-[#1F7634] text-xs font-medium rounded-2xl justify-center items-center flex">
                      {" "}
                      <Completed />{" "}
                    </div>
                  ) : educationInformation?.failedPages?.includes(3) ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-red-100 leading-[18px] text-center text-red-800 text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Reject />{" "}
                    </div>
                  ) : null}
                </button>
                <button
                  className={`h-[38px] px-[4px] pb-[8px] w-fit flex justify-center items-center gap-2 ${
                    stages === "financial-summary"
                      ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]"
                      : ""
                  }`}
                  onClick={() => toggleStages("financial-summary")}
                >
                  5. Financial Summary
                  {educationInformation?.acceptanceStatus === "PENDING" ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-[#ffffea] leading-[18px] text-center text-[#bb6902] text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Pending />
                    </div>
                  ) : educationInformation?.stage === 4 &&
                    !educationInformation?.failedPages?.includes(4) ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-[#F1FCF2] leading-[18px] text-center text-[#1F7634] text-xs font-medium rounded-2xl justify-center items-center flex">
                      {" "}
                      <Completed />{" "}
                    </div>
                  ) : educationInformation?.failedPages?.includes(4) ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-red-100 leading-[18px] text-center text-red-800 text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Reject />{" "}
                    </div>
                  ) : null}
                </button>
              </div>
            </div>
          </div>
          <div className=" pb-6 w-full">
            {stages === "basic-information" && <BasicInfo />}
            {stages === "identity-verification" && <IdentityVerification />}
            {stages === "education" && <Education />}
            {stages === "employment-history" && <EmploymentHistory />}
            {stages === "financial-summary" && <FinancialSummary />}
          </div>
        </div>
      </div>
    </>
  );
}

export default Registration;
