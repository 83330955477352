import what from "../Images/WhatWeDo.png";
import why from "../Images/WhyWeExist.png";

function WhatAndWhy() {
  return (
    <>
      <div className="h-fit w-full 100:px-8 md:px-16 py-24 bg-[#103a9f] flex-col justify-center items-center gap-[78px] inline-flex">
        <div className=" 100:w-full 2xl:w-[1280px] 100:flex-col lg:flex-row flex justify-start items-center 100:gap-[32px] 2xl:gap-[64px] ">
          <div className=" w-full">
            <img src={what} alt="What we do" />
          </div>
          <div className="w-full flex-col justify-start items-start gap-4 inline-flex">
            <h2 className="self-stretch text-[#f5f6f6] text-3xl font-semibold leading-[38px]">What We Do</h2>
            <div className="text-[#cdd1d4] text-justify text-lg font-normal  leading-7">
              <span className="text-[#e5e7e8] text-lg font-semibold ">We provide</span> a web-based co-financing platform that helps African students fund their postgraduate studies abroad. Through personalized fundraising campaigns, students can
              seek donations from family, friends, and our broader Ije community. When these donations don’t cover the full amount, we step in with tailored loans that students can repay over a period of up to 5 years.
              <br />
              <br />
              By supporting students from the moment they’re accepted into a foreign university to the completion of their studies, Ije provides a 100% financial solution, allowing students to focus on what matters most—their education.
            </div>
          </div>
        </div>
        <div className=" 100:w-full 2xl:w-[1280px] 100:flex-col lg:flex-row flex justify-start items-center 100:gap-[32px] 2xl:gap-[64px] ">
          <div className=" w-full">
            <img src={why} alt="What we do" />
          </div>
          <div className="w-full flex-col justify-start items-start gap-4 inline-flex">
            <h2 className="self-stretch text-[#f5f6f6] text-3xl font-semibold leading-[38px]">Why We Exist</h2>
            <div className="text-[#cdd1d4] text-justify text-lg font-normal  leading-7">
              <span className="text-[#e5e7e8] text-lg font-semibold ">Our Mission</span> is to make international education more accessible Africans <br />
              <br />
              For many African students, the dream of obtaining a world-class education is often out of reach due to financial barriers. The cost of tuition, travel, living expenses, and visa fees can be overwhelming, especially for students coming
              from emerging economies. Traditional financing{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhatAndWhy;
