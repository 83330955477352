import kenya from "../Images/kenya.png";
import germany from "../Images/germany.png";
import usa from "../Images/Usa.png";

function FeaturedCampaigns() {
  const features = [
    {
      picture: kenya,
      title: "Medical School in Kenya",
      description: "Support John in his journey to become a doctor at the University of Nairobi. He aims to improve healthcare in rural areas.",
      progressBar: "40%",
    },
    {
      picture: germany,
      title: "Study Abroad in Germany",
      description: "Help Maria fulfill her dream of studying engineering at TU Berlin. She's passionate about renewable energy and wants to make a difference.",
      progressBar: "60%",
    },
    {
      picture: usa,
      title: "Computer Science in the USA",
      description: "Help Aisha achieve her goal of studying computer science at MIT. She wants to develop technology for social good.",
      progressBar: "75%",
    },
  ];
  return (
    <>
      <div className=" w-full py-[96px]   flex justify-center items-center">
        <section className="100:w-[85%] xl:w-[1216px] xl:px-0  lg:w-full lg:px-[80px] gap-[64px] flex justify-start items-start  flex-col">
          <div className=" 100:w-full lg:w-[750px] gap-[20px] text-left flex flex-col items-start justify-start">
            <h2 className=" font-semibold tracking-[-2px] text-[#3A3C3F] text-[38px] leading-[42px] ">Featured Campaigns</h2>
            <p className="text-[20px]  leading-[30px] text-[#6C757D] w-full font-normal">Discover inspiring campaigns from students around the world. See their goals and progress, and find motivation for your own journey.</p>
          </div>
          <div className="w-full 100:flex-col lg:flex-row flex justify-center items-center gap-[32px]">
            {features.map((item, id) => (
              <div className=" w-full flex flex-col 100:justify-start 100:items-start lg:justify-center lg:items-center gap-[32px] ">
                <img className="w-full" src={item.picture} alt={item.title} />
                <div className=" w-full flex-col flex  justify-start items-start gap-[12px]">
                  <h3 className=" font-semibold text-[20px] text-[#3A3C3F] leading-[32px]">{item.title}</h3>
                  <p className=" font-normal text-[16px] text-[#6C757D] leading-[24px]">{item.description}</p>
                </div>
                <div className=" w-full flex justify-start flex-col items-start gap-[8px]">
                  <div className=" h-[8px] items-start flex justify-start bg-[#E8F7FF] rounded-[8px] w-full">
                    <div style={{ width: `${item.progressBar}` }} className={`h-full  bg-[#0C4CFF] rounded-[8px] `}></div>
                  </div>
                  <span className=" w-full text-[#4A4F54] font-medium leading-[24px] text-[12px] flex justify-end items-end">{item.progressBar}</span>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
}

export default FeaturedCampaigns;
