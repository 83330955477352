import SideBar from "./SideBar";

function GiveBack() {
  return (
    <>
      <SideBar />
      <div></div>
    </>
  );
}

export default GiveBack;
