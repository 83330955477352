import { useState } from "react";
import iconClose from "../Images/IconCloseWrap.svg";
import iconOpen from "../Images/IconOPenWrap.svg";

function FAQ() {
  const [open, setOpen] = useState(null);

  const handleOpenToggle = (questionId) => {
    setOpen(open === questionId ? null : questionId); // Toggle between open and close
  };
  return (
    <>
      <div className=" w-full flex flex-col justify-start bg-[#f5f6f6] items-center gap-[64px] py-[96px] 100:px-[48px] md:px-[64px] ">
        <h2 className="text-center text-[#3a3c3f] text-4xl font-semibold  leading-[44px]">Frequently asked questions</h2>
        <div className="lg:w-[768px] 100:w-full h-fit flex-col justify-start items-start gap-8 inline-flex">
          <div className=" w-full flex flex-col justify-start items-start gap-6 ">
            <div className=" w-full justify-between items-center text flex ">
              <h4 className="text-[#3a3c3f] text-lg font-medium text-pretty leading-7">What is Ije?</h4>
              <button className=" w-[50px] flex justify-end items-end">
                <img className="w-[24px]" onClick={() => handleOpenToggle("question1")} src={open === "question1" ? iconClose : iconOpen} />
              </button>
            </div>
            {open === "question1" && (
              <div className=" w-full text-[#6c757d] text-base font-normal leading-normal">
                Ije is a web-based co-financing platform designed to help African students raise funds for postgraduate studies abroad. We provide a combination of community-driven donations and flexible loans solution to cover the costs of tuition
                and living expenses of our users.
              </div>
            )}
          </div>
          <div className=" w-full flex flex-col justify-start items-start gap-6 ">
            <div className="w-full h-px bg-[#cdd1d4]" />
            <div className=" w-full justify-between items-center flex ">
              <h4 className="text-[#3a3c3f] text-lg font-medium text-pretty leading-7">How does Ije work?</h4>
              <button className=" w-[50px] flex justify-end items-end">
                <img className="w-[24px]" onClick={() => handleOpenToggle("question2")} src={open === "question2" ? iconClose : iconOpen} />
              </button>
            </div>
            {open === "question2" && (
              <div className=" w-full text-[#6c757d] text-base font-normal leading-normal">
                Ije allows prospective students to create personalized fundraising campaigns. You can share your campaign with family, friends, and a broader community to receive donations. If the funds raised through donations are not enough, Ije
                provides loans to cover the remaining amount, payable over a period of up to 5 years. Students have the option to make their campaign private or public. By private, students can only share the link for donation to their network. And
                by public, Ije will publish the campaign on its website and share on its social media platforms.
              </div>
            )}
          </div>
          <div className=" w-full flex flex-col justify-start items-start gap-6 ">
            <div className="w-full h-px bg-[#cdd1d4]" />
            <div className=" w-full justify-between items-center flex ">
              <h4 className="text-[#3a3c3f] text-lg font-medium text-pretty leading-7">Who is eligible to use Ije?</h4>
              <button className=" w-[50px] flex justify-end items-end">
                <img className="w-[24px]" onClick={() => handleOpenToggle("question3")} src={open === "question3" ? iconClose : iconOpen} />
              </button>
            </div>
            {open === "question3" && (
              <div className=" w-full text-[#6c757d] text-base font-normal leading-normal">
                For now, we are serving Nigerians who have been accepted into a recognized postgraduate program abroad (starting with the UK). Applicants are accessed and approved to create a fundraising campaign on Ije. We will expand to support
                other African students targeting UK, US, Canada and Europe.{" "}
              </div>
            )}
          </div>
          <div className=" w-full flex flex-col justify-start items-start gap-6 ">
            <div className="w-full h-px bg-[#cdd1d4]" />
            <div className=" w-full justify-between items-center flex ">
              <h4 className="text-[#3a3c3f] text-lg font-medium text-pretty leading-7">What can I raise money for on Ije?</h4>
              <button className=" w-[50px] flex justify-end items-end">
                <img className="w-[24px]" onClick={() => handleOpenToggle("question4")} src={open === "question4" ? iconClose : iconOpen} />
              </button>
            </div>
            {open === "question4" && (
              <div className=" flex flex-col gap-6 w-full text-[#6c757d] text-base font-normal leading-normal">
                <p className=" w-full">You can raise funds to cover 100% of your financing needs related to your education abroad, including:</p>
                <ul className="w-full flex flex-col list-disc gap-[10px]">
                  <li>Tuition fees</li>
                  <li>Accommodation and living expenses</li>
                </ul>
                <p className=" w-full">
                  You are also required to contribute at least 10% of your total funding requirements towards your education. Ije will also access and ensure your funding request is in line with the destination country’s funding requirement. You
                  cannot request for amount below the legal requirement for both tuition and living expenses.
                </p>
              </div>
            )}
          </div>
          <div className=" w-full flex flex-col justify-start items-start gap-6 ">
            <div className="w-full h-px bg-[#cdd1d4]" />
            <div className=" w-full justify-between items-center flex ">
              <h4 className="text-[#3a3c3f] text-lg font-medium text-pretty leading-7">How do the loans from Ije work?</h4>
              <button className=" w-[50px] flex justify-end items-end">
                <img className="w-[24px]" onClick={() => handleOpenToggle("question5")} src={open === "question5" ? iconClose : iconOpen} />
              </button>
            </div>
            {open === "question5" && (
              <div className=" w-full text-[#6c757d] text-base font-normal leading-normal">
                If donations are not enough to cover your full expenses, Ije offers loans with flexible repayment terms. You can repay the loan over a period of up to 5 years, with a moratorium of up to a year. The loans are designed to ensure you
                have financial peace of mind while studying abroad.{" "}
              </div>
            )}
          </div>
          <div className=" w-full flex flex-col justify-start items-start gap-6 ">
            <div className="w-full h-px bg-[#cdd1d4]" />
            <div className=" w-full justify-between items-center flex ">
              <h4 className="text-[#3a3c3f] text-lg font-medium text-pretty leading-7">How do I create a campaign on Ije and apply for a loan?</h4>
              <button className=" w-[50px] flex justify-end items-end">
                <img className="w-[24px]" onClick={() => handleOpenToggle("question6")} src={open === "question6" ? iconClose : iconOpen} />
              </button>
            </div>
            {open === "question6" && (
              <div className=" w-full text-[#6c757d] text-base font-normal leading-normal">
                <ul className="w-full flex flex-col list-decimal gap-[10px]">
                  <li>Sign up on Ije.com and create a profile.</li>
                  <li>Submit all the required documents and get approved.</li>
                  <li>Personalize your campaign with your story, photo, and video.</li>
                  <li>Share your campaign with family, friends, and the broader Ije community (opitional) through social media and email</li>
                  <li>Start receiving donations.</li>
                  <li>If you don’t hit the campaign target, you are automatically qualified for a loan to compliment the fund raised through donations</li>
                  <li>The tuition is directly disbursed to the school. We will disburse 30% of the living expenses once the visa has been granted. The balance is remitted after the student settles in the new country.</li>
                </ul>{" "}
              </div>
            )}
          </div>
          <div className=" w-full flex flex-col justify-start items-start gap-6 ">
            <div className="w-full h-px bg-[#cdd1d4]" />
            <div className=" w-full justify-between items-center flex ">
              <h4 className="text-[#3a3c3f] text-lg font-medium text-pretty leading-7">Are there any fees for using Ije?</h4>
              <button className=" w-[50px] flex justify-end items-end">
                <img className="w-[24px]" onClick={() => handleOpenToggle("question7")} src={open === "question7" ? iconClose : iconOpen} />
              </button>
            </div>
            {open === "question7" && (
              <div className=" flex flex-col gap-6 w-full text-[#6c757d] text-base font-normal leading-normal">
                <p className=" w-full"> Yes, we charge the following fees and interest</p>
                <ul className="w-full flex flex-col list-decimal gap-[10px]">
                  <li>2% origination fee on the total amount raised on the platform</li>
                  <li>1% annual maintenance fee charged yearly on outstanding loan amount</li>
                  <li>16% per annum interest rate on the outstanding loan amount</li>
                  <li>Users are responsible for fees associated with funds transfers to school and remittance to self after relocation</li>
                </ul>{" "}
              </div>
            )}
          </div>
          <div className=" w-full flex flex-col justify-start items-start gap-6 ">
            <div className="w-full h-px bg-[#cdd1d4]" />
            <div className=" w-full justify-between items-center flex ">
              <h4 className="text-[#3a3c3f] text-lg font-medium text-pretty leading-7">Can I withdraw money raised on my campaign directly?</h4>
              <button className=" w-[50px] flex justify-end items-end">
                <img className="w-[24px]" onClick={() => handleOpenToggle("question8")} src={open === "question8" ? iconClose : iconOpen} />
              </button>
            </div>
            {open === "question8" && (
              <div className=" w-full text-[#6c757d] text-base font-normal leading-normal">
                Donations and loans are intended for educational purposes. Therefore, funds are disbursed directly to your educational institution or in phases to cover your accommodation, living, and other necessary expenses.
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default FAQ;
