const SuccessAlert = ({ success, info }) => {
  return success === true ? (
    <div className=" w-fit h-[46px] bg-[#F6FEF9] border px-[16px] py-[8px] border-[#6CE9A6] flex justify-center items-center gap-[8px] rounded-[8px]">
      <div className="bg-white rounded-[16px] px-[8px] text-[12px] font-medium leading-[18px] py-[2px] text-[#027A48]">Success</div> <div className=" text-[12px] font-medium leading-[18px]  text-[#027A48]">{info}</div>{" "}
    </div>
  ) : (
    <div className=" w-fit h-[46px] bg-[#FEF2F2] border px-[16px] py-[8px] border-[#F9A8A8] flex justify-center items-center gap-[8px] rounded-[8px]">
      <div className="bg-[#FEE6E5] rounded-[16px] px-[8px] text-[12px] font-medium leading-[18px] py-[2px] text-[#B7192C]">Error</div> <div className=" text-[12px] font-medium leading-[18px]  text-[#B7192C]"> {info}</div>{" "}
    </div>
  );
};

export default SuccessAlert;
