import { ApiClient } from "./apiConfig";

const apiPath = ApiClient();

const LoanRequest = {
  getAllLoans: async () => {
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .get(`${process.env.REACT_APP_BASE_URL}/rest/loan/get-all-loans`)
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },


  getDetailsUser: async () => {
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .get(`${process.env.REACT_APP_BASE_URL}/rest/loan/get-details-user`)
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  getApprovedLoans: async () => {
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .get(`${process.env.REACT_APP_BASE_URL}/rest/loan/loan-approved`)
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },


  getTotalDisbursed: async () => {
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .get(`${process.env.REACT_APP_BASE_URL}/rest/loan/total-loan-disbursed`)
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },


  rejectLoan: async (email) => {
    let payload = {
      email,
    };

    let res = {
      status: 0,
      data: {},
      message: "",
    };
    return await apiPath
      .post(`${process.env.REACT_APP_BASE_URL}/rest/loan/reject`, payload)
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  approveLoan: async (email) => {
    let payload = {
      email,
    };

    let res = {
      status: 0,
      data: {},
      message: "",
    };
    return await apiPath
      .post(`${process.env.REACT_APP_BASE_URL}/rest/loan/approve`, payload)
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  getDetailsAdmin: async (email) => {
    let payload = {
      email,
    };

    let res = {
      status: 0,
      data: {},
      message: "",
    };
    return await apiPath
      .post(
        `${process.env.REACT_APP_BASE_URL}/rest/loan/get-details-admin`,
        payload
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  applyUser: async (paymentTerms) => {
    let payload = {
      paymentTerms,
    };

    let res = {
      status: 0,
      data: {},
      message: "",
    };
    return await apiPath
      .post(`${process.env.REACT_APP_BASE_URL}/rest/loan/apply-user`, payload)
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },
};

export default LoanRequest;
