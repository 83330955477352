export const SaveCancelButton = ({ cancelFunc, saveFunc }) => {
  return (
    <div className=" flex flex-row gap-x-2">
      <button
        onClick={saveFunc}
        className=" w-fit flex justify-center items-center gap-2"
      >
        <span className="text-[#0c4aff] 100:hidden sm:flex text-sm font-medium font-inter leading-tight">
          Save
        </span>
      </button>
      <button
        onClick={cancelFunc}
        className=" w-fit flex justify-center items-center gap-2"
      >
        <span className="text-gray-500 100:hidden sm:flex text-sm font-medium font-inter leading-tight">
          Cancel
        </span>
      </button>
    </div>
  );
};
