function LinkedAccouunt() {
  return (
    <>
      <div className=" flex-col gap-[32px] w-full flex justify-start items-center">
        <div className="w-full flex-col justify-start items-start gap-1 inline-flex">
          <h3 className="self-stretch text-[#3a3c3f] text-lg font-medium  leading-7">Linked Accounts</h3>
          <p className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Manage your linked accounts</p>
        </div>
        <div className=" w-full 100:flex-col sm:flex-row  justify-start items-start gap-8 inline-flex">
          <div className="flex-col w-[210px] justify-start items-start inline-flex">
            <h4 className=" w-full text-[#344053] text-sm font-medium leading-tight">Linked Accounts</h4>
            {/* <p className=" w-full text-[#667084] text-sm font-normal leading-tight">Enter current password</p> */}
          </div>
          <div className="h-fit 100:w-full md:w-[75%] p-6  bg-white flex-col justify-start items-start gap-6 rounded-lg shadow  inline-flex">
            <div className="flex-col  w-full flex gap-[24px] justify-start items-start ">
              <div className="w-fit justify-start items-start gap-2 inline-flex">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_429_14994)">
                    <path
                      d="M12.7812 12.7813H10.5587V9.30002C10.5587 8.47002 10.5438 7.40127 9.4025 7.40127C8.245 7.40127 8.0675 8.30627 8.0675 9.23877V12.7813H5.845V5.62252H7.97875V6.60127H8.00875C8.44375 5.85877 9.25375 5.41377 10.1137 5.44502C12.3662 5.44502 12.7825 6.92627 12.7825 8.85502L12.7812 12.78V12.7813ZM3.3375 4.64377C2.99609 4.64213 2.66912 4.50586 2.42759 4.26456C2.18605 4.02327 2.04947 3.69642 2.0475 3.35502C2.0475 2.64752 2.63 2.06502 3.3375 2.06502C4.045 2.06502 4.62625 2.64752 4.6275 3.35502C4.6275 4.06252 4.045 4.64377 3.3375 4.64377ZM4.44875 12.7813H2.22375V5.62252H4.44875V12.7813ZM13.8875 1.8579e-05H1.10625C0.816438 -0.00166471 0.537672 0.111093 0.330529 0.313789C0.123386 0.516484 0.00460583 0.792738 0 1.08252V13.9175C0.00460583 14.2073 0.123386 14.4836 0.330529 14.6862C0.537672 14.8889 0.816438 15.0017 1.10625 15H13.8888C14.1792 15.0024 14.4589 14.89 14.667 14.6873C14.8751 14.4846 14.9947 14.208 15 13.9175V1.08127C14.9947 0.791052 14.875 0.514679 14.6668 0.312379C14.4587 0.11008 14.179 -0.00174782 13.8888 0.00126849L13.8875 1.8579e-05Z"
                      fill="#0A66C2"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_429_14994">
                      <rect width="15" height="15.0001" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div className="w-[321px] h-10 flex-col justify-start items-start inline-flex">
                  <h3 className="self-stretch text-[#4a4f54] text-sm font-medium  leading-tight">Linkedin</h3>
                  <p className="self-stretch text-[#6c757d] text-xs font-normal  leading-tight">Last linked 17th May 2024 6:00pm</p>
                </div>
              </div>
              <div className="w-fit justify-start items-start gap-2 inline-flex">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 14L7.03226 8.96774M14 2L8.96774 7.03226M8.96774 7.03226L5.33333 2H2L7.03226 8.96774M8.96774 7.03226L14 14H10.6667L7.03226 8.96774" stroke="#242628" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

                <div className="w-[321px] h-10 flex-col justify-start items-start inline-flex">
                  <h3 className="self-stretch text-[#4a4f54] text-sm font-medium  leading-tight">X</h3>
                  <p className="self-stretch text-[#6c757d] text-xs font-normal  leading-tight">Last linked 17th May 2024 6:00pm</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LinkedAccouunt;
