import SideBar from "./SideBar";
import { ReactComponent as Search } from "../Images/search.svg";
import { ReactComponent as Completed } from "../Images/complete.svg";
import Footer from "./Footer";

export default function UserSupport() {
  return (
    <>
      <SideBar />
      <div className=" flex 100:justify-center items-center lg:justify-start flex-col 100:pt-[120px] md:pt-[120px] lg:pt-[40px] md:py-[32px]  lg:pr-8 w-full lg:pl-[362px]">
        <div className="flex gap-[32px] items-center 100:w-[85%] lg:w-full  justify-center flex-col">
          <h3 className="self-stretch  text-[#3a3c3f] text-2xl font-medium leading-[38px]">User Support and Feedback</h3>
          {/* TABLE 1 */}
          <div className=" w-full gap-[8px] flex-col justify-start items-center  inline-flex">
            <h5 className="self-stretch text-[#6c757d] text-xs font-medium ">Support Tickets</h5>
            <div className="h-fit w-full whitespace-nowrap  100:overflow-x-scroll bg-white rounded-xl shadow flex-col justify-center items-start gap-[24px] inline-flex">
              <div className=" flex justify-between items-center w-full p-[20px]">
                <div
                  // onClick={() => setSearch(false)}
                  className="self-stretch 100:flex md:hidden gap-[12px]  w-full px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#aab0b6] justify-start items-center  inline-flex text-[#6c757d] text-base font-normal leading-normal"
                >
                  <Search /> <input className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full" placeholder="Search" />
                </div>

                <div className="self-stretch gap-[12px] 100:hidden md:flex w-[400px] px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#aab0b6] justify-start items-center  inline-flex text-[#6c757d] text-base font-normal leading-normal">
                  <Search /> <input className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full" placeholder="Search" />
                </div>
              </div>
              <div className=" self-stretch w-full border-t border-t-[#CDD1D4] flex justify-between items-center ">
                {/* ROW 1*/}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium  leading-[18px]">Support Tickets</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">001</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-white  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">002</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">003</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-white  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">004</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">005</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-white  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">006</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">007</div>
                </div>
                {/* ROW 2*/}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium  leading-[18px]">User</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Celine Johnnnes</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-white  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Kareem Yomi</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Yul Josh</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-white  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Femi Olasukanmi</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Ade Ori Nnka</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-white  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Yemi Osobanjo</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Jolade Ayinde</div>
                </div>
                {/* ROW 3*/}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium  leading-[18px]">Issue Summary</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Login Issue</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-white  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Login Issue</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Login Issue</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-white  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Login Issue</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Login Issue</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-white  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Login Issue</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Login Issue</div>
                </div>
                {/* ROW 4*/}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium  leading-[18px]">Agents</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Agent CL</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-white  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Agent B</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Agent B</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-white  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Agent BD</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Agent B</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-white  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Agent B</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Agent B</div>
                </div>
                {/* ROW 5*/}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium  leading-[18px]">Status</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">
                    {" "}
                    <div className="pl-1.5 pr-2 py-0.5 bg-[#f1fcf2] rounded-2xl justify-center items-center gap-1 flex">
                      <Completed />
                      <div className="text-center text-[#1f7634] text-xs font-medium leading-[18px]">Open</div>
                    </div>
                  </div>
                  <div className="h-[72px] w-full px-6 py-4 bg-white  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">
                    <div className="pl-1.5 pr-2 py-0.5 bg-[#f1fcf2] rounded-2xl justify-center items-center gap-1 flex">
                      <Completed />
                      <div className="text-center text-[#1f7634] text-xs font-medium leading-[18px]">Open</div>
                    </div>
                  </div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">
                    <div className="pl-1.5 pr-2 py-0.5 bg-[#f1fcf2] rounded-2xl justify-center items-center gap-1 flex">
                      <Completed />
                      <div className="text-center text-[#1f7634] text-xs font-medium leading-[18px]">Open</div>
                    </div>
                  </div>
                  <div className="h-[72px] w-full px-6 py-4 bg-white  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">
                    <div className="pl-1.5 pr-2 py-0.5 bg-[#f1fcf2] rounded-2xl justify-center items-center gap-1 flex">
                      <Completed />
                      <div className="text-center text-[#1f7634] text-xs font-medium leading-[18px]">Open</div>
                    </div>
                  </div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">
                    <div className="pl-1.5 pr-2 py-0.5 bg-[#f1fcf2] rounded-2xl justify-center items-center gap-1 flex">
                      <Completed />
                      <div className="text-center text-[#1f7634] text-xs font-medium leading-[18px]">Open</div>
                    </div>
                  </div>
                  <div className="h-[72px] w-full px-6 py-4 bg-white  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">
                    <div className="pl-1.5 pr-2 py-0.5 bg-[#f1fcf2] rounded-2xl justify-center items-center gap-1 flex">
                      <Completed />
                      <div className="text-center text-[#1f7634] text-xs font-medium leading-[18px]">Open</div>
                    </div>
                  </div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">
                    <div className="pl-1.5 pr-2 py-0.5 bg-[#f1fcf2] rounded-2xl justify-center items-center gap-1 flex">
                      <Completed />
                      <div className="text-center text-[#1f7634] text-xs font-medium leading-[18px]">Open</div>
                    </div>
                  </div>
                </div>
                {/* ROW 6*/}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium  leading-[18px]">Date</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">17th May 2024</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-white  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">17th May 2024</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">17th May 2024</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-white  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">17th May 2024</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">17th May 2024</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-white  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">17th May 2024</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">17th May 2024</div>
                </div>
              </div>
            </div>
          </div>
          {/* TABLE 2 */}
          <div className=" w-full gap-[8px] flex-col justify-start items-center  inline-flex">
            <h5 className="self-stretch text-[#6c757d] text-xs font-medium ">Feedback and Reviews</h5>
            <div className="h-fit w-full whitespace-nowrap  100:overflow-x-scroll bg-white rounded-xl shadow flex-col justify-center items-start gap-[24px] inline-flex">
              <div className=" flex justify-between items-center w-full p-[20px]">
                <div
                  // onClick={() => setSearch(false)}
                  className="self-stretch 100:flex md:hidden gap-[12px]  w-full px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#aab0b6] justify-start items-center  inline-flex text-[#6c757d] text-base font-normal leading-normal"
                >
                  <Search /> <input className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full" placeholder="Search" />
                </div>

                <div className="self-stretch gap-[12px] 100:hidden md:flex w-[400px] px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#aab0b6] justify-start items-center  inline-flex text-[#6c757d] text-base font-normal leading-normal">
                  <Search /> <input className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full" placeholder="Search" />
                </div>
              </div>
              <div className=" self-stretch w-full border-t border-t-[#CDD1D4] flex justify-between items-center ">
                {/* ROW 1*/}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium  leading-[18px]">User</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Celine Johnnnes</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-white  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Kareem Yomi</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Yul Josh</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-white  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Femi Olasukanmi</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Ade Ori Nnka</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-white  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Yemi Osobanjo</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Jolade Ayinde</div>
                </div>
                {/* ROW 2*/}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium  leading-[18px]">Feedback</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Great platform!</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-white  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Great platform!</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Great platform!</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-white  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Great platform!</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Great platform!</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-white  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Great platform!</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Great platform!</div>
                </div>
                {/* ROW 3*/}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium  leading-[18px]">Date</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">17th May 2024</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-white  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">17th May 2024</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">17th May 2024</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-white  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">17th May 2024</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">17th May 2024</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-white  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">17th May 2024</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">17th May 2024</div>
                </div>
                {/* ROW 4*/}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium  leading-[18px]">Comment</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Thank you!</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-white  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Looking forward to seeing you again</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Looking forward to seeing you again</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-white  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Looking forward to seeing you again</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Looking forward to seeing you again</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-white  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Looking forward to seeing you again</div>
                  <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Looking forward to seeing you again</div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
