function PrivacySetting() {
  return (
    <>
      <div className=" flex-col gap-[32px] w-full flex justify-start items-center">
        <div className="w-full flex-col justify-start items-start gap-1 inline-flex">
          <h3 className="self-stretch text-[#3a3c3f] text-lg font-medium  leading-7">Privacy Settings</h3>
          <p className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Manage your privacy settings to control what information you share and who can see it on Ije.</p>
        </div>
        <div className=" w-full 100:flex-col sm:flex-row  justify-start items-start gap-8 inline-flex">
          <div className="flex-col w-[210px] justify-start items-start inline-flex">
            <h4 className=" w-full text-[#344053] text-sm font-medium leading-tight">Contact information visibilitys</h4>
            {/* <p className=" w-full text-[#667084] text-sm font-normal leading-tight">Enter current password</p> */}
          </div>
          <div className="h-fit 100:w-full md:w-[75%] p-6  bg-white flex-col justify-start items-start gap-6 rounded-lg shadow  inline-flex">
            <form className="flex-col  w-full flex gap-[24px] justify-start items-start ">
              <label className=" w-full flex-wrap flex justify-start items-center gap-[8px] leading-[20px] text-[#6c757d] text-sm font-normal " htmlFor="Public">
                <input name="contact" id="contact" required className="w-[20px] outline-[#0C4AFF] bg-white h-[20px]  rounded-[6px] border border-[#CDD1D4]     " type="radio" />
                Public
              </label>
              <label className=" w-full flex-wrap flex justify-start items-center gap-[8px] leading-[20px] text-[#6c757d] text-sm font-normal " htmlFor="Only me">
                <input name="contact" id="contact" required className="w-[20px] outline-[#0C4AFF] bg-white h-[20px]  rounded-[6px] border border-[#CDD1D4]     " type="radio" />
                Only me
              </label>
            </form>
          </div>
        </div>
        <div className=" w-full 100:flex-col sm:flex-row  justify-start items-start gap-8 inline-flex">
          <div className="flex-col w-[210px] justify-start items-start inline-flex">
            <h4 className=" w-full text-[#344053] text-sm font-medium leading-tight">Control who can see your posts and updates</h4>
            {/* <p className=" w-full text-[#667084] text-sm font-normal leading-tight">Enter current password</p> */}
          </div>
          <div className="h-fit 100:w-full md:w-[75%] p-6  bg-white flex-col justify-start items-start gap-6 rounded-lg shadow  inline-flex">
            <form className="flex-col  w-full flex gap-[24px] justify-start items-start ">
              <label className=" w-full flex-wrap flex justify-start items-center gap-[8px] leading-[20px] text-[#6c757d] text-sm font-normal " htmlFor="Public">
                <input name="updated" id="updated" required className="w-[20px] outline-[#0C4AFF] bg-white h-[20px]  rounded-[6px] border border-[#CDD1D4]     " type="radio" />
                Public
              </label>
              <label className=" w-full flex-wrap flex justify-start items-center gap-[8px] leading-[20px] text-[#6c757d] text-sm font-normal " htmlFor="Only me">
                <input name="updated" id="updated" required className="w-[20px] outline-[#0C4AFF] bg-white h-[20px]  rounded-[6px] border border-[#CDD1D4]     " type="radio" />
                Only me
              </label>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacySetting;
