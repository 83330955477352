import SideBar from "./SideBar";
import user from "../Images/userID.png";
import { useAppSelector } from "../../utils/redux/store";
import tick from "../Images/tick-02.svg";
// import TextInput from "../shared/input/TextInput";

function Profile() {
  const { acceptanceStatus, firstName, lastName, email, imageString, address, phoneNumber } = useAppSelector((state) => state.auth);

  return (
    <>
      <SideBar />
      <div className=" flex 100:justify-center items-center lg:justify-start flex-col pb-[80px]   w-full lg:pl-[320px]">
        <div className="bg-gradient-to-r w-full 100:h-[20vh] md:h-[30vh] from-[#0c4cff]/40  via-[#079A91]/40    to-[#0c4cff]/40"></div>
        <div className="flex gap-[32px] mt-[-70px] items-center 100:w-[85%] lg:w-full  justify-center flex-col">
          <div className=" w-full px-8 flex-col justify-center items-center gap-6 inline-flex">
            <div className={`w-40 h-40 rounded-[200px] shadow border-4 ${acceptanceStatus === "ACCEPT" ? "border-[#f1fcf2]" : "border-[#ffffea]"} `}>
              <img src={imageString ? imageString : user} alt={firstName} className=" rounded-full w-40 h-40 object-cover" />
            </div>
            {acceptanceStatus === "ACCEPT" && (
              <div className="h-[24.25px] w-[24.25px] ml-[100px] mt-[-55px]  px-[5px] py-1.5 bg-[#32b550] rounded-[100px] border border-white flex-col justify-center items-center gap-2.5 inline-flex">
                <img alt="" src={tick} />
              </div>
            )}
            <div className="w-full flex-col justify-start items-center gap-1 flex">
              <h2 className="self-stretch text-center text-[#3a3c3f] text-[28px] font-medium  leading-[38px]">
                {firstName} {lastName}
              </h2>
              <h5 className="self-stretch text-center text-[#4a4f54] text-sm font-medium  leading-normal">{email}</h5>
            </div>
            <form className="flex-col 100:w-full lg:w-[800px] flex gap-[24px] justify-start items-start ">
              <div className=" w-full flex 100:flex-col md:flex-row justify-start items-center 100:gap-[24px] md:gap-[32px]  ">
                <div className="w-full flex-col justify-start items-start gap-1.5 flex">
                  <h3 className="text-[#4a4f54] text-sm font-medium  leading-tight">First name</h3>
                  <div className="w-full px-4 py-3 bg-white rounded-lg h-[48px] shadow border border-[#cdd1d4] justify-start items-center gap-2 inline-flex  text-[#0f1728] text-base font-normal  leading-normal">{firstName}</div>
                </div>{" "}
                <div className="w-full flex-col justify-start items-start gap-1.5 flex">
                  <h3 className="text-[#4a4f54] text-sm font-medium  leading-tight">Last name</h3>
                  <div className="w-full px-4 py-3 bg-white rounded-lg h-[48px] shadow border border-[#cdd1d4] justify-start items-center gap-2 inline-flex  text-[#0f1728] text-base font-normal  leading-normal">{lastName}</div>
                </div>
              </div>
              <div className="w-full flex-col justify-start items-start gap-1.5 flex">
                <h3 className="text-[#4a4f54] text-sm font-medium  leading-tight">Email</h3>
                <div className="w-full px-4 py-3 bg-white rounded-lg h-[48px] shadow border border-[#cdd1d4] justify-start items-center gap-2 inline-flex  text-[#0f1728] text-base font-normal  leading-normal">{email}</div>
              </div>{" "}
              <div className="w-full flex-col justify-start items-start gap-1.5 flex">
                <h3 className="text-[#4a4f54] text-sm font-medium  leading-tight">Phone number</h3>
                <div className="w-full px-4 py-3 bg-white rounded-lg h-[48px] shadow border border-[#cdd1d4] justify-start items-center gap-2 inline-flex  text-[#0f1728] text-base font-normal  leading-normal">{phoneNumber}</div>
              </div>{" "}
              {/* <div className="w-full flex-col justify-start items-start gap-1.5 flex">
                <h3 className="text-[#4a4f54] text-sm font-medium  leading-tight">Date of Birth</h3>
                <div className="w-full px-4 py-3 bg-white rounded-lg h-[48px] shadow border border-[#cdd1d4] justify-start items-center gap-2 inline-flex  text-[#0f1728] text-base font-normal  leading-normal">{"16 september 1980"}</div>
              </div>{" "} */}
              <div className="w-full flex-col justify-start items-start gap-1.5 flex">
                <h3 className="text-[#4a4f54] text-sm font-medium  leading-tight">Address</h3>
                <div className="w-full px-4 py-3 bg-white rounded-lg h-[48px] shadow border border-[#cdd1d4] justify-start items-center gap-2 inline-flex  text-[#0f1728] text-base font-normal  leading-normal">{address}</div>
              </div>{" "}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
