import Select from "react-select";
import al from "../../Images/alert-circle.svg";
import cancel from "../../Images/Cancel.svg";
import { rejectionOptions } from "../../../utils/helpers/constants";
import { useState } from "react";
export default function RejectionModal({
  modal,
  isLoading,
  toggleModal,
  rejectFunc,
}) {
  const [failedArray, setFailedArray] = useState([]);
  return (
    <>
      {modal && (
        <>
          <div className="w-full h-[100%] top-0 left-0 fixed z-40  bg-slate-700 bg-opacity-60 backdrop-blur-lg flex-col justify-start items-center flex">
            <section className=" w-full flex justify-end pt-[25px] pr-[40px] items-end">
              <div
                onClick={toggleModal}
                className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  "
              >
                <img className=" " src={cancel} alt="Cancel" />
              </div>
            </section>
            <section className=" 100:w-[80%] h-full iphone:w-full  flex justify-center  items-center">
              <div className=" 100:w-full iphone:w-96 h-fit p-[24px] bg-white rounded-xl shadow flex-col justify-start items-center flex">
                <div className="w-full flex-col justify-start items-center gap-[32px] flex">
                  <div className="w-full flex-col justify-start items-center gap-5 flex">
                    <div className="w-12 h-12   justify-center flex  items-center  bg-[#fffbc5] rounded-[28px] border-8 border-[#ffffea]">
                      <img className=" " src={al} alt="Alert" />
                    </div>
                    <div className="w-full flex-col justify-start items-center gap-2 flex">
                      <h3 className="self-stretch text-center text-gray-900 text-lg font-medium  leading-7">
                        Reject Registration Request
                      </h3>
                      <p className="self-stretch text-center text-gray-500 text-sm font-normal  leading-tight">
                        Select a reason for rejection. This will be included in
                        the user's rejection email to help them make necessary
                        updates.
                      </p>
                    </div>
                  </div>

                  <label
                    className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium "
                    htmlFor="monthsWithEmployer"
                  >
                    Which part of the registration failed?
                    <Select
                      className=" w-full bg-white border-[#CDD1D4]  rounded-[8px] text-[#3A3C3F] ] font-normal leading-[24px] text-[16px"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "100%",
                          // height: "48px",
                          background: "#fff",
                          outline: "none",
                        }),
                      }}
                      placeholder="Select Option"
                      isMulti
                      options={rejectionOptions}
                      onChange={(selectedOptions) => {
                        // Populate failedArray with selected values
                        const selectedValues = selectedOptions
                          ? selectedOptions.map((option) => option.value)
                          : [];
                        setFailedArray(selectedValues);
                      }}
                    />
                  </label>

                  <div className=" w-full flex justify-start items-center flex-col gap-2.5 ">
                    <button
                      onClick={() => rejectFunc(failedArray)}
                      className="leading-[20px]  bg-[#b7192c] rounded-lg shadow border border-[#b7192c] text-base text-[#fff] flex justify-center font-medium h-fit px-[20px] py-[12px] w-full "
                    >
                      {isLoading ? "Loading" : "Proceed"}
                    </button>
                    <button
                      onClick={toggleModal}
                      className="leading-[20px]   rounded-lg shadow border border-[#AAB0B6] text-[#4a4f54] text-base flex justify-center font-medium h-fit px-[20px] py-[12px] w-full "
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
}
