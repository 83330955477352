import Footer from "../../ApproverAdmin/Footer";
import { ReactComponent as Search } from "../../Images/search.svg";
import { ReactComponent as Completed } from "../../Images/complete.svg";
import { ReactComponent as Icon } from "../../Images/money.svg";
import cancel from "../../Images/Cancel.svg";
import { useState } from "react";

function History() {
  const [modal, setModal] = useState(false);
  return (
    <>
      {/* TABLE */}
      <div className=" w-full gap-[8px] flex-col justify-start items-start flex">
        <h5 className="self-stretch text-[#3a3c3f] text-lg font-medium  ">Loan History</h5>
        <div className="h-fit w-full whitespace-nowrap  100:overflow-x-scroll bg-white rounded-xl shadow flex-col justify-center items-start gap-[24px] inline-flex">
          <div className=" flex justify-between items-center w-full p-[20px]">
            <div className="self-stretch 100:flex md:hidden gap-[12px]  w-full px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#aab0b6] justify-start items-center  inline-flex text-[#6c757d] text-base font-normal leading-normal">
              <Search /> <input className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full" placeholder="Search" />
            </div>

            <div className="self-stretch gap-[12px] 100:hidden md:flex w-[400px] px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#aab0b6] justify-start items-center  inline-flex text-[#6c757d] text-base font-normal leading-normal">
              <Search /> <input className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full" placeholder="Search" />
            </div>
          </div>
          <div className=" self-stretch w-full border-t border-t-[#CDD1D4] flex justify-between items-center ">
            {/* ROW 1*/}
            <div className=" w-full flex flex-col justify-start items-start">
              <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium  leading-[18px]">Loan ID</div>

            </div>
            {/* ROW 2*/}
            <div className=" w-full flex flex-col justify-start items-start">
              <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium  leading-[18px]">Loan Amount</div>

            </div>
            {/* ROW 3*/}
            <div className=" w-full flex flex-col justify-start items-start">
              <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium  leading-[18px]">Application Date</div>

            </div>
            {/* ROW 4*/}
            <div className=" w-full flex flex-col justify-start items-start">
              <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium  leading-[18px]">Status</div>
              
            </div>
            {/* ROW 5*/}
            <div className=" w-full flex flex-col justify-start items-start">
              <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium  leading-[18px]">Repayment Terms</div>

            </div>
            {/* ROW 6*/}
            <div className=" w-full flex flex-col justify-start items-start">
              <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium  leading-[18px]"></div>
              {/* <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-center items-center gap-3 inline-flex text-[#0c4aff] text-sm font-medium  leading-tight">
                <span className=" cursor-pointer" onClick={() => setModal(true)}>
                  View
                </span>
              </div> */}
             
            </div>
          </div>
        </div>
      </div>
      <div className=" w-full mt-[40px]">
        <Footer />
      </div>
      {modal && (
        <>
          <div className="w-full h-[100%] top-0 left-0 fixed z-40  bg-slate-700 bg-opacity-60 backdrop-blur-lg flex-col justify-start items-center flex">
            <section className=" w-full flex justify-end pt-[25px] pr-[40px] items-end">
              <div onClick={() => setModal(false)} className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  ">
                <img className=" " src={cancel} alt="Cancel" />
              </div>
            </section>
            <section className=" 100:w-[80%] h-full iphone:w-full  flex justify-center  items-center">
              <div className=" 100:w-full iphone:w-96  h-fit p-[24px] gap-[32px] bg-white rounded-xl shadow flex-col justify-center items-center flex">
                <div style={{ color: "#0C4AFF", backgroundColor: "#d5efff", borderColor: "#E8F7FF" }} className={`w-12 h-12  flex justify-center items-center rounded-[28px]  border-4 `}>
                  <Icon />
                </div>
                <h4 className=" text-[18px] font-medium text-[#3A3C3F]">Loan Details</h4>
                <div className=" w-full flex-col justify-start items-center gap-5 inline-flex">
                  {/* <img src={campaign} alt="" />*/}
                  <div className="w-full flex-col justify-start items-start gap-6 flex">
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                      <h4 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Loan ID</h4>
                      <h5 className="self-stretch text-[#4a4f54] text-sm font-medium  leading-tight">00011236789</h5>
                    </div>
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                      <h4 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Application Date</h4>
                      <h5 className="self-stretch text-[#4a4f54] text-sm font-medium  leading-tight">17th May 2024</h5>
                    </div>{" "}
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                      <h4 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Amount </h4>
                      <h5 className="self-stretch text-[#4a4f54] text-sm font-medium  leading-tight">₦40,000</h5>
                    </div>
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                      <h4 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Repayment Terms</h4>
                      <h5 className="self-stretch text-[#4a4f54] text-sm font-medium  leading-tight">12 Weeks</h5>
                    </div>
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                      <h4 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Status</h4>
                      <div className="pl-1.5 pr-2 py-0.5 bg-[#f1fcf2] rounded-2xl justify-center items-center gap-1 flex">
                        <Completed />
                        <div className="text-center text-[#1f7634] text-xs font-medium leading-[18px]">Approved</div>
                      </div>{" "}
                    </div>
                  </div>
                </div>
                <button className="h-12 flex-col w-full px-5 py-3 bg-[#0c4cff] rounded-lg shadow border border-[#0c4aff] justify-center items-center gap-2 flex text-white text-base font-medium  leading-normal">Ok</button>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
}

export default History;
