import { ReactComponent as Arrow } from "../Images/arrow.svg";
import { ReactComponent as Dot } from "../Images/dot.svg";

function Footer() {
  return (
    <>
      <div className=" md:px-[32px]  pt-[11px] pb-[16px] border-t border-t-[#CDD1D4] w-full flex justify-end items-center">
        <div className="h-10 shadow  bg-white rounded-lg  border divide-x-[1px]  border-[#aab0b6] justify-center items-center  flex">
          <div className=" w-full h-full flex justify-center items-center text-[#b3dfff] text-sm font-medium gap-[8px] px-[16px] py-[10px] leading-tight">
            <Arrow /> Previous
          </div>{" "}
          <div className="  w-full h-full flex justify-center items-center bg-[#e8f7ff] text-[#4a4f54] text-sm font-medium gap-[8px] px-[16px] py-[10px] leading-tight">1</div>
          <div className=" 100:hidden md:flex w-full h-full flex justify-center items-center  text-[#4a4f54] text-sm font-medium gap-[8px] px-[16px] py-[10px] leading-tight">2</div>
          <div className="100:hidden md:flex w-full h-full flex justify-center items-center  text-[#4a4f54] text-sm font-medium gap-[8px] px-[16px] py-[10px] leading-tight">3</div>
          <div className=" md:flex w-full h-full flex justify-center items-center  text-[#4a4f54] text-sm font-medium gap-[8px] px-[16px] py-[10px] leading-tight">
            <Dot />
          </div>
          <div className="100:hidden md:flex w-full h-full flex justify-center items-center  text-[#4a4f54] text-sm font-medium gap-[8px] px-[16px] py-[10px] leading-tight">8</div>
          <div className="100:hidden md:flex w-full h-full flex justify-center items-center  text-[#4a4f54] text-sm font-medium gap-[8px] px-[16px] py-[10px] leading-tight">9</div>
          <div className="100:hidden md:flex w-full h-full flex justify-center items-center  text-[#4a4f54] text-sm font-medium gap-[8px] px-[16px] py-[10px] leading-tight">10</div>
          <div className=" w-full h-full flex justify-center items-center text-[#0c4aff] text-sm font-medium gap-[8px] px-[16px] py-[10px] leading-tight">
            Next <Arrow className=" rotate-180" />
          </div>{" "}
        </div>
      </div>
    </>
  );
}

export default Footer;
