export const maskEmail = (email) => {
  const [user, domain] = email.split("@");
  const maskedUser =
    user.length > 3 ? `${user.substring(0, 3)}********` : `${user}********`;
  return `${maskedUser}@${domain}`;
};

export const CurrencyFormatter = (
  amount,
  currencySymbol,
  decimalSeparator = ".",
  thousandsSeparator = ","
) => {
  // Convert amount to string with 2 decimal places
  const formattedAmount = amount.toFixed(2);
  // Split the formatted amount into parts before and after the decimal separator
  const [integerPart, decimalPart] = formattedAmount.split(".");

  

  // Insert thousands separators
  const integerWithSeparators = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    thousandsSeparator
  );

  // Construct the final formatted currency string
  let result
  if (currencySymbol) {
    result =currencySymbol + integerWithSeparators + decimalSeparator + decimalPart;
  } else {
    result = "₦" + integerWithSeparators + decimalSeparator + decimalPart;
  }
  return result;


};

export const formatDate = (date) => {
  const options = { day: "numeric", month: "long", year: "numeric" };
  const day = date.getDate();

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const ordinalSuffix = getOrdinalSuffix(day);

  return `${day}${ordinalSuffix} ${
    date.toLocaleDateString("en-GB", options).split(" ")[1]
  } ${date.getFullYear()}`;
};
