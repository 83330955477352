import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SideBar from "./SideBar";
import { ReactComponent as Back } from "../Images/back.svg";
import { ReactComponent as Pending } from "../Images/pending.svg";
import { ReactComponent as Completed } from "../Images/complete.svg";
import { ReactComponent as Reject } from "../Images/reject.svg";
import illustration from "../Images/verify.svg";
import userDet from "../Images/userDetails.png";
import pick from "../Images/file.svg";
import no from "../Images/no.svg";
import emailu from "../Images/email.svg";
import user from "../Images/user.svg";
import map from "../Images/map.svg";
import linkedin from "../Images/linkedin.svg";
import x from "../Images/x.svg";
import Instagram from "../Images/instagram.svg";
import facebook from "../Images/facebook.svg";
import confirm from "../Images/Confirm.svg";
import cancel from "../Images/Cancel.svg";
import { useParams } from "react-router-dom";
import AdminRequest from "../../utils/services/adminRequests";
import { useFormik } from "formik";
import { approveUserValidator } from "../../utils/services/validation";
import TextArea from "../shared/input/TextArea";
import { toast } from "react-toastify";
import { formatDate, CurrencyFormatter } from "../../utils/helpers/helper";
import Select from "react-select";
import { rejectionOptions } from "../../utils/helpers/constants";
import BasicInfo from "./UserDetails/BasicInfo";
import IdentityVerification from "./UserDetails/IdentityVerification";
import Education from "./UserDetails/Education";
import EmploymentHistory from "./UserDetails/EmploymentHistory";
import FinancialSummary from "./UserDetails/FinancialSummary";
function UserDetails() {
  const { email } = useParams();

  const [accept, setAccept] = useState(false);
  const [reject, setReject] = useState(false);
  const [isLoading, setLoading] = useState("");
  const [isUser, setUser] = useState({});
  const [failedArray, setFailedArray] = useState([]);
  const navigate = useNavigate();
  const [stages, setStages] = useState("basic-information");
  const toggleStages = (menu) => {
    setStages(menu);
  };
  const getUserDetails = async () => {
    await AdminRequest.getUserDetails(email).then((res) => {
      setUser(res.data.result);
    });
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const formik = useFormik({
    validationSchema: approveUserValidator,
    initialValues: {
      comment: "",
    },
  });

  const declineRequest = async () => {
    setLoading(true);
    await AdminRequest.rejectRequest(
      email,
      formik.values.comment,
      failedArray
    ).then((res) => {
      setLoading(false);

      if (res.data.statusCode === 200) {
        toast.success("successful", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setReject(true);
      } else {
        setLoading(false);
        toast.error(res?.data?.message || res?.message, {
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
        });
      }
    });
  };
  const approveRequest = async () => {
    setLoading(true);
    await AdminRequest.approveRequest(email, formik.values.comment).then((res) => {
      setLoading(false);

      if (res.data.statusCode === 200) {
        toast.success("successful", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setAccept(true);
      } else {
        setLoading(false);
        toast.error(res?.data?.message || res?.message, {
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
        });
      }
    });
  };

  // console.log("isUser", failedArray);

  return (
    <>
      <SideBar />
      <div className=" flex 100:justify-center items-center lg:justify-start flex-col 100:pt-[120px] md:pt-[120px] lg:pt-[40px] md:py-[32px]  lg:pr-8 w-full lg:pl-[352px]">
        <div className="flex items-center 100:w-[85%] lg:w-full gap-[32px]  justify-center flex-col">
          <div className="flex flex-col items-start justify-start gap-[20px] w-full">
            <h3 className="self-stretch flex justify-start items-center gap-[16px] text-[#3a3c3f] text-base font-medium  leading-normal ">
              <Link onClick={() => window.scrollTo(0, 0)} className="text-[#aab0b6] text-sm font-medium leading-normal justify-start items-center gap-1 inline-flex" to="/approver/user-management">
                User Management <Back className=" w-[14px] h-[14px] rotate-180" />
              </Link>
              User Details
            </h3>
            <div className="lg:h-[260.75px] 100:h-full w-full px-8 py-6 bg-[#d5efff] rounded-2xl shadow 100:flex-col md:flex-row 100:gap-8 lg:gap-0 justify-between items-start inline-flex">
              <div className=" 100:w-full  lg:w-[521px]  flex-col justify-start items-start gap-3 inline-flex">
                <h3 className="self-stretch text-[#3a3c3f] 100:text-base sm:text-xl font-medium font-inter leading-normal">Verify User Registration</h3>
                <p className="self-stretch text-[#565c64] 100:text-sm  sm:text-base font-normal font-inter leading-normal">
                  Review the user’s details and ensure all documents, such as ID and proof of address, are valid and up-to-date. Confirm that all required information is accurate before completing the verification process.{" "}
                </p>
              </div>
              <div className="100:w-full md:w-fit">
                <img src={illustration} alt="illustration" />
              </div>
            </div>
            <div className="w-full flex flex-col 100:overflow-x-scroll md:overflow-hidden justify-start border-b-[#cdd1d4] border-b-[1px]">
              <div className="md:w-fit list-decimal  100:w-full whitespace-nowrap text-[#6c757d] text-sm font-medium  leading-tight flex justify-start items-center gap-[16px]">
                <button
                  className={`h-[38px] px-[4px] pb-[8px] w-fit flex justify-center items-center gap-2 ${stages === "basic-information" ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]" : ""}`}
                  onClick={() => toggleStages("basic-information")}
                >
                  1. Basic Information
                  {isUser?.acceptanceStatus === "ACCEPT" ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-[#F1FCF2] leading-[18px] text-center text-[#1F7634] text-xs font-medium rounded-2xl justify-center items-center flex">
                      {" "}
                      <Completed />{" "}
                    </div>
                  ) : isUser?.acceptanceStatus === "REJECT" ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-red-100 leading-[18px] text-center text-red-800 text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Reject />{" "}
                    </div>
                  ) : (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-[#ffffea] leading-[18px] text-center text-[#bb6902] text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Pending />
                    </div>
                  )}
                </button>
                <button
                  className={`h-[38px] px-[4px] pb-[8px] w-fit flex justify-center items-center gap-2 ${stages === "identity-verification" ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]" : ""}`}
                  onClick={() => toggleStages("identity-verification")}
                >
                  2. Identity Verification
                  {isUser?.acceptanceStatus === "ACCEPT" ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-[#F1FCF2] leading-[18px] text-center text-[#1F7634] text-xs font-medium rounded-2xl justify-center items-center flex">
                      {" "}
                      <Completed />{" "}
                    </div>
                  ) : isUser?.acceptanceStatus === "REJECT" ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-red-100 leading-[18px] text-center text-red-800 text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Reject />{" "}
                    </div>
                  ) : (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-[#ffffea] leading-[18px] text-center text-[#bb6902] text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Pending />
                    </div>
                  )}
                </button>
                <button className={`h-[38px] px-[4px] pb-[8px] w-fit flex justify-center items-center gap-2 ${stages === "education" ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]" : ""}`} onClick={() => toggleStages("education")}>
                  3. Education
                  {isUser?.acceptanceStatus === "ACCEPT" ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-[#F1FCF2] leading-[18px] text-center text-[#1F7634] text-xs font-medium rounded-2xl justify-center items-center flex">
                      {" "}
                      <Completed />{" "}
                    </div>
                  ) : isUser?.acceptanceStatus === "REJECT" ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-red-100 leading-[18px] text-center text-red-800 text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Reject />{" "}
                    </div>
                  ) : (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-[#ffffea] leading-[18px] text-center text-[#bb6902] text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Pending />
                    </div>
                  )}
                </button>
                <button
                  className={`h-[38px] px-[4px] pb-[8px] w-fit flex justify-center items-center gap-2 ${stages === "employment-history" ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]" : ""}`}
                  onClick={() => toggleStages("employment-history")}
                >
                  4. Employment History
                  {isUser?.acceptanceStatus === "ACCEPT" ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-[#F1FCF2] leading-[18px] text-center text-[#1F7634] text-xs font-medium rounded-2xl justify-center items-center flex">
                      {" "}
                      <Completed />{" "}
                    </div>
                  ) : isUser?.acceptanceStatus === "REJECT" ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-red-100 leading-[18px] text-center text-red-800 text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Reject />{" "}
                    </div>
                  ) : (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-[#ffffea] leading-[18px] text-center text-[#bb6902] text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Pending />
                    </div>
                  )}
                </button>
                <button
                  className={`h-[38px] px-[4px] pb-[8px] w-fit flex justify-center items-center gap-2 ${stages === "financial-summary" ? " border-b-[2px] border-b-[#0c4aff] text-[#0c4aff]" : ""}`}
                  onClick={() => toggleStages("financial-summary")}
                >
                  5. Financial Summary
                  {isUser?.acceptanceStatus === "ACCEPT" ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-[#F1FCF2] leading-[18px] text-center text-[#1F7634] text-xs font-medium rounded-2xl justify-center items-center flex">
                      {" "}
                      <Completed />{" "}
                    </div>
                  ) : isUser?.acceptanceStatus === "REJECT" ? (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-red-100 leading-[18px] text-center text-red-800 text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Reject />{" "}
                    </div>
                  ) : (
                    <div className="h-[20px] w-[20px] mix-blend-multiply   bg-[#ffffea] leading-[18px] text-center text-[#bb6902] text-xs font-medium rounded-2xl justify-center items-center flex">
                      <Pending />
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="pb-6 w-full 100:flex-col md:flex-row flex justify-start items-start gap-6">
            <div className="  w-full">
              {stages === "basic-information" && <BasicInfo  data={isUser}/>}
              {stages === "identity-verification" && <IdentityVerification data={isUser} />}
              {stages === "education" && <Education data={isUser} />}
              {stages === "employment-history" && <EmploymentHistory data={isUser} />}
              {stages === "financial-summary" && <FinancialSummary data={isUser} />}
            </div>
            <div className="h-fit  100:w-full md:w-[318px]   p-8  rounded-lg border border-[#cdd1d4] bg-white flex-col justify-start items-center gap-8 inline-flex">
              <div className="flex-col w-full border-b border-b-[#E5E7E8] pb-[16px] justify-start items-center gap-[16px] inline-flex">
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Date Submitted</h3>
                  <h4 className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">{formatDate(new Date(isUser.createdAt))}</h4>
                </div>{" "}
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Time Initiated</h3>
                  <h4 className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">
                    {new Date(isUser.createdAt).toLocaleTimeString("en-US", {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    })}
                  </h4>
                </div>
              </div>
              <div className="flex-col w-full border-b border-b-[#E5E7E8] pb-[16px] justify-start items-center gap-[16px] inline-flex">
                <div className="justify-start w-full items-center gap-3 inline-flex">
                  <img src={no} alt="" /> <h4 className="self-stretch text-[#4a4f54] text-sm font-medium leading-tight">{isUser.id}</h4>
                </div>{" "}
                <div className="justify-start w-full items-center gap-3 inline-flex">
                  <img src={emailu} alt="" /> <h4 className="self-stretch text-[#4a4f54] text-sm font-medium leading-tight">{isUser?.email}</h4>
                </div>{" "}
                <div className="justify-start w-full items-center gap-3 inline-flex">
                  <img src={user} alt="" />{" "}
                  <h4 className="self-stretch text-[#4a4f54] text-sm font-medium leading-tight">
                    {isUser?.firstName} {isUser?.lastName}
                  </h4>
                </div>{" "}
                <div className="justify-start w-full items-start gap-3 inline-flex">
                  <img src={map} alt="" /> <h4 className="self-stretch text-[#4a4f54] text-sm font-medium leading-tight">{isUser?.address}</h4>
                </div>{" "}
              </div>
              <div className="flex-col w-full border-b border-b-[#E5E7E8] pb-[16px] justify-start items-center gap-[16px] inline-flex">
                <div className="justify-start w-full items-center gap-3 inline-flex">
                  <img alt="" src={linkedin} />{" "}
                  <a
                    href={`${isUser?.educationInformation?.linkedInAddress?.startsWith("http") ? isUser.educationInformation.linkedInAddress : `https://${isUser?.educationInformation?.linkedInAddress}`}`}
                    className="truncate text-[#4a4f54] text-sm font-medium leading-tight"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {isUser?.educationInformation?.linkedInAddress}
                  </a>
                </div>{" "}
                <div className="justify-start w-full items-center gap-3 inline-flex">
                  <img alt="" src={Instagram} />{" "}
                  <a
                    href={`${isUser?.educationInformation?.instagramLink?.startsWith("http") ? isUser.educationInformation.instagramLink : `https://${isUser?.educationInformation?.instagramLink}`}`}
                    className="truncate text-[#4a4f54] text-sm font-medium leading-tight"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {isUser?.educationInformation?.instagramLink}
                  </a>
                </div>{" "}
                <div className="justify-start w-full items-center gap-3 inline-flex">
                  <img alt="" src={x} />{" "}
                  <a
                    href={`${isUser?.educationInformation?.twitterLink?.startsWith("http") ? isUser.educationInformation.twitterLink : `https://${isUser?.educationInformation?.twitterLink}`}`}
                    className="truncate text-[#4a4f54] text-sm font-medium leading-tight"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {isUser?.educationInformation?.twitterLink}
                  </a>
                </div>{" "}
                <div className="justify-start w-full items-center gap-3 inline-flex">
                  <img alt="" src={facebook} />{" "}
                  <a
                    href={`${isUser?.educationInformation?.facebookProfile?.startsWith("http") ? isUser.educationInformation.facebookProfile : `https://${isUser?.educationInformation?.facebookProfile}`}`}
                    className="truncate text-[#4a4f54] text-sm font-medium leading-tight"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {isUser?.educationInformation?.facebookProfile}
                  </a>
                </div>{" "}
              </div>
              <div className="flex-col w-full border-b border-b-[#E5E7E8] pb-[16px] justify-start items-center gap-[16px] inline-flex">
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Status</h3>
                  <div
                    className={`h-[22px] gap-[4px] mix-blend-multiply px-2 py-0.5 ${isUser?.acceptanceStatus === "ACCEPT" ? "bg-[#f1fcf2]" : isUser?.acceptanceStatus === "REJECT" ? "bg-red-100" : "bg-[#ffffea]"}  leading-[18px] ${
                      isUser?.acceptanceStatus === "ACCEPT" ? "text-[#1f7634]" : isUser?.acceptanceStatus === "REJECT" ? "text-red-500" : "text-[#bb6902]"
                    } text-center  text-xs font-medium rounded-2xl justify-center items-center flex`}
                  >
                    {" "}
                    {isUser?.acceptanceStatus === "ACCEPT" ? <Completed /> : isUser?.acceptanceStatus === "REJECT" ? <Reject /> : <Pending />}
                    {/* {isUser?.acceptanceStatus} */}
                    {isUser?.acceptanceStatus ? isUser.acceptanceStatus.charAt(0).toUpperCase() + isUser.acceptanceStatus.slice(1).toLowerCase() : ""}
                    {/* Pending Approval */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className=" flex 100:justify-center items-center lg:justify-start  100:pt-[120px] md:pt-[120px] lg:pt-[40px] md:py-[32px]  lg:pr-8 w-full  lg:pl-[352px]">
        <div className="flex  items-start 100:w-[85%] lg:w-full  justify-start 100:flex-col md:flex-row">
          <div className=" 100:w-full h-fit flex flex-col items-center justify-start pr-[24px] gap-[32px]  w-[85%]">
            <div className="flex flex-col items-center justify-start w-full gap-[16px]">
              <div className="flex flex-col items-center justify-start w-full gap-[24px]">
             
                {isUser?.acceptanceStatus !== "ACCEPT" && (
                  <>
                    <label className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium " htmlFor="monthsWithEmployer">
                      Which part of the registration failed?
                      <Select
                        className=" w-full bg-white border-[#CDD1D4] h-[48px]  rounded-[8px] text-[#3A3C3F] ] font-normal leading-[24px] text-[16px"
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            width: "100%",
                            height: "48px",
                            background: "#fff",
                            outline: "none",
                          }),
                        }}
                        placeholder="Select Option"
                        // options={currency}
                        isMulti
                        options={rejectionOptions}
                        onChange={(selectedOptions) => {
                          // Populate failedArray with selected values
                          const selectedValues = selectedOptions
                            ? selectedOptions.map((option) => option.value)
                            : [];
                          setFailedArray(selectedValues);
                        }}
                      />
                    </label>
                    <TextArea
                      label={"Comment"}
                      type="text"
                      value={formik.values.comment}
                      hint={"Not more than 200 characters"}
                      onBlur={formik.handleBlur("comment")}
                      onChange={formik.handleChange("comment")}
                      error={formik.touched.comment && formik.errors.comment}
                    />
                  </>
                )}
              </div>
            </div>
            {isUser?.acceptanceStatus !== "ACCEPT" && (
              <div className=" w-full flex  100:gap-[16px] 100:flex-col-reverse phone:flex-row  justify-between items-center">
                <div onClick={declineRequest} to={"/approver/dashboard"} className=" 100:w-full phone:w-fit">
                  <button className="leading-[20px] bg-white rounded-lg shadow border border-[#aab0b6]  text-[#4a4f54] text-base flex items-center justify-center font-medium h-[48px] px-7  100:w-full phone:w-[128px] ">Reject</button>
                </div>
                <button
                  onClick={approveRequest}
                  className="leading-[20px] border-[#0c4aff] border-solid border-[1px] rounded-[8px]  bg-[#0c4aff]   text-base text-[#fff] flex items-center justify-center font-medium h-[48px] px-[16px]  100:w-full phone:w-[128px] "
                >
                  {isLoading ? "Loading..." : "Approve"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div> */}
      {accept && (
        <>
          <div className="w-full h-[100%] top-0 left-0 fixed z-40  bg-slate-700 bg-opacity-60 backdrop-blur-lg flex-col justify-start isUsers-center flex">
            <section className=" w-full flex justify-end pt-[25px] pr-[40px] items-end">
              <div
                onClick={() => {
                  setAccept(false);
                }}
                className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  "
              >
                <img className=" " src={cancel} alt="Cancel" />
              </div>
            </section>
            <section className=" 100:w-[80%] h-full iphone:w-full flex justify-center  items-center">
              <div className=" 100:w-full iphone:w-96 100:h-fit iphone:h-64 p-[24px] bg-white rounded-xl shadow flex-col justify-start items-center flex">
                <div className="w-full flex-col justify-start items-center gap-[32px] flex">
                  <div className="w-full flex-col justify-start items-center gap-5 flex">
                    <div className="w-12 h-12   justify-center flex  items-center  bg-emerald-100 rounded-3xl border-8 border-emerald-50  ">
                      <img className=" " src={confirm} alt="Confirm" />
                    </div>
                    <div className="w-full flex-col justify-start items-center gap-2 flex">
                      <h3 className="self-stretch text-center text-gray-900 text-lg font-medium  leading-7">Approved Successfully</h3>
                      <p className="self-stretch text-center text-gray-500 text-sm font-normal  leading-tight">This user has been approved successfully..</p>
                    </div>
                  </div>
                  <div
                    className="w-full"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <div className="leading-[20px] cursor-pointer border-[#0C4AFF] border-solid border-[1px] rounded-[8px]  bg-[#0C4AFF] text-lg text-[#fff] flex justify-center font-medium h-fit px-[20px] py-[12px] w-full ">Back to Dashboard</div>
                  </div>{" "}
                </div>
              </div>
            </section>
          </div>
        </>
      )}
      {reject && (
        <>
          <div className="w-full h-[100%] top-0 left-0 fixed z-40  bg-slate-700 bg-opacity-60 backdrop-blur-lg flex-col justify-start items-center flex">
            <section className=" w-full flex justify-end pt-[25px] pr-[40px] items-end">
              <div onClick={() => setReject(false)} className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  ">
                <img className=" " src={cancel} alt="Cancel" />
              </div>
            </section>
            <section className=" 100:w-[80%] h-full iphone:w-full flex justify-center  items-center">
              <div className=" 100:w-full iphone:w-96 100:h-fit iphone:h-64 p-[24px] bg-white rounded-xl shadow flex-col justify-start items-center flex">
                <div className="w-full flex-col justify-start items-center gap-[32px] flex">
                  <div className="w-full flex-col justify-start items-center gap-5 flex">
                    <div className="w-12 h-12   justify-center flex  items-center  bg-emerald-100 rounded-3xl border-8 border-emerald-50  ">
                      <img className=" " src={confirm} alt="Confirm" />
                    </div>
                    <div className="w-full flex-col justify-start items-center gap-2 flex">
                      <h3 className="self-stretch text-center text-gray-900 text-lg font-medium  leading-7">Rejected Successfully</h3>
                      <p className="self-stretch text-center text-gray-500 text-sm font-normal  leading-tight">This user has been rejected successfully..</p>
                    </div>
                  </div>
                  <div
                    className="w-full"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <div className="leading-[20px] border-[#0C4AFF] border-solid border-[1px] rounded-[8px]  bg-[#0C4AFF] text-lg text-[#fff] flex justify-center font-medium h-fit px-[20px] py-[12px] w-full ">Back to Dashboard</div>
                  </div>{" "}
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
}

export default UserDetails;
