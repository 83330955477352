import { Link } from "react-router-dom";
import { ReactComponent as Back } from "../../Images/back.svg";
import logo from "../../Images/Logo.svg";
import { useEffect, useState } from "react";
import confirm from "../../Images/Confirm.svg";
import cancel from "../../Images/Cancel.svg";

function AddProcessor() {
  const [navBar, setNavBar] = useState(false);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    function handleScrollBackground() {
      if (window.scrollY >= 20) {
        setNavBar(true);
      } else {
        setNavBar(false);
      }
    }
    window.addEventListener("scroll", handleScrollBackground);

    return () => {
      window.removeEventListener("scroll", handleScrollBackground);
    };
  }, []);
  return (
    <>
      <div className=" bg-white flex-col h-fit w-full pb-[80px] gap-[100px]  items-center flex justify-start  ">
        <div className="fixed  top-0 z-20 w-full flex items-center p-[10px] justify-center">
          <div className={` rounded-[50px]   px-[30px] my-[10px] ${navBar ? "bg-[#D5EFFF] " : ""} h-[75px] flex justify-between items-center w-[90%] `}>
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              to={"/approver/dashboard"}
            >
              <div className="text-[#0C4AFF] gap-[8px] flex justify-start items-center ">
                <Back />
                <h4 className="  text-[16px] font-medium leading-[24px] ">Back</h4>
              </div>
            </Link>
            <div>
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                to="/"
              >
                <img className="" src={logo} alt="Ije" />
              </Link>
            </div>
            <div className=" w-[80px] 100:hidden phone:block"></div>
          </div>
        </div>
        <div className=" 100:w-[90%] pt-[180px] md:w-[716px] gap-[64px] flex-col flex justify-center items-center ">
          <div className="flex-col flex gap-[12px] items-center justify-start">
            <h4 className=" text-center text-blue-600 text-base font-semibold  leading-normal">Add Processor Administrator</h4>
            <h2 className=" text-center text-neutral-700 text-3xl font-semibold  leading-10">Assign a new Processor Admin to manage specific tasks and processes within the platform. </h2>
          </div>
          <form className="flex-col 100:w-full flex gap-[24px] md:w-[480px] justify-start items-start ">
            <div className=" w-full flex 100:flex-col md:flex-row justify-start items-center 100:gap-[24px] md:gap-[32px]  ">
              <label className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium " htmlFor="  First name">
                First name
                <input
                  required
                  className="w-full border outline-[#0C4AFF] bg-white border-[#CDD1D4] h-[48px] px-[16px] rounded-[8px] text-[#3A3C3F] placeholder:text-[#6C757D] font-normal leading-[24px] text-[16px]   "
                  type="text"
                  placeholder="First name"
                  //   value={formik.values.firstName}
                  //   onChange={formik.handleChange("firstName")}
                  //   onBlur={formik.handleBlur("firstName")}
                />
              </label>
              <label className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium " htmlFor="  Last name">
                Last name
                <input
                  required
                  className="w-full border outline-[#0C4AFF] bg-white border-[#CDD1D4] h-[48px] px-[16px] rounded-[8px] text-[#3A3C3F] placeholder:text-[#6C757D] font-normal leading-[24px] text-[16px]   "
                  type="text"
                  placeholder="Last name"
                  //   value={formik.values.lastName}
                  //   onChange={formik.handleChange("lastName")}
                  //   onBlur={formik.handleBlur("lastName")}
                />
              </label>
            </div>
            <label className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium " htmlFor="email">
              Email
              <input
                required
                className="w-full border outline-[#0C4AFF] bg-white border-[#CDD1D4] h-[48px] px-[16px] rounded-[8px] text-[#3A3C3F] placeholder:text-[#6C757D] font-normal leading-[24px] text-[16px]   "
                type="email"
                placeholder="Email your address"
                //     value={formik.values.email}
                //     onChange={formik.handleChange("email")}
                //     onBlur={formik.handleBlur("email")}
              />
            </label>
            <label className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium " htmlFor="Phone number">
              Phone number
              <input
                required
                className="w-full border outline-[#0C4AFF] bg-white border-[#CDD1D4] h-[48px] px-[16px] rounded-[8px] text-[#3A3C3F] placeholder:text-[#6C757D] font-normal leading-[24px] text-[16px]   "
                type="tel"
                placeholder="Phone number"
                // value={formik.values.phoneNumber}
                // onChange={formik.handleChange("phoneNumber")}
                // onBlur={formik.handleBlur("phoneNumber")}
              />
            </label>
            <label className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium " htmlFor="Role Description">
              Role Description
              <textarea
                required
                className="w-full border outline-[#0C4AFF] bg-white border-[#CDD1D4] h-[128px]  p-[16px] rounded-[8px] text-[#3A3C3F] placeholder:text-[#6C757D] font-normal leading-[24px] text-[16px]   "
                placeholder="Describe the role"
                // value={formik.values.phoneNumber}
                // onChange={formik.handleChange("phoneNumber")}
                // onBlur={formik.handleBlur("phoneNumber")}
              />
            </label>
            <label className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium " htmlFor="Start Date">
              Start Date
              <input
                required
                className="w-full border outline-[#0C4AFF] bg-white border-[#CDD1D4] h-[48px] px-[16px] rounded-[8px] text-[#3A3C3F]  placeholder:text-[#6C757D] font-normal leading-[24px] text-[16px]   "
                type="date"
                placeholder="Select start date"
                // value={formik.values.phoneNumber}
                // onChange={formik.handleChange("phoneNumber")}
                // onBlur={formik.handleBlur("phoneNumber")}
              />
            </label>
            <div className=" w-full flex  100:gap-[16px] 100:flex-col-reverse phone:flex-row  justify-between items-center">
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                to={"/approver/dashboard"}
                className=" 100:w-full phone:w-fit"
              >
                <button className="leading-[20px] bg-white rounded-lg shadow border border-[#aab0b6]  text-[#4a4f54] text-base flex justify-center font-medium h-[48px] px-7 py-4 100:w-full phone:w-[93px] ">Cancel</button>
              </Link>
              <button
                onClick={() => setModal(true)}
                className="leading-[20px] border-[#b3dfff] border-solid border-[1px] rounded-[8px]  bg-[#b3dfff]  text-base text-[#fff] flex justify-center font-medium h-[48px] px-[16px] py-4 100:w-full phone:w-[125px] "
              >
                Add Admin
              </button>
            </div>
          </form>
        </div>
      </div>
      {modal && (
        <>
          <div className="w-full h-[100%] top-0 left-0 fixed z-40  bg-slate-700 bg-opacity-60 backdrop-blur-lg flex-col justify-start items-center flex">
            <section className=" w-full flex justify-end pt-[25px] pr-[40px] items-end">
              <div onClick={() => setModal(false)} className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  ">
                <img className=" " src={cancel} alt="Cancel" />
              </div>
            </section>
            <section className=" 100:w-[80%] h-full iphone:w-full flex justify-center  items-center">
              <div className=" 100:w-full iphone:w-96 100:h-fit iphone:h-64 p-[24px] bg-white rounded-xl shadow flex-col justify-start items-center flex">
                <div className="w-full flex-col justify-start items-center gap-[32px] flex">
                  <div className="w-full flex-col justify-start items-center gap-5 flex">
                    <div className="w-12 h-12   justify-center flex  items-center  bg-emerald-100 rounded-3xl border-8 border-emerald-50  ">
                      <img className=" " src={confirm} alt="Confirm" />
                    </div>
                    <div className="w-full flex-col justify-start items-center gap-2 flex">
                      <h3 className="self-stretch text-center text-gray-900 text-lg font-medium  leading-7">Added Successfully</h3>
                      <p className="self-stretch text-center text-gray-500 text-sm font-normal  leading-tight">Processor Admin added successfully!</p>
                    </div>
                  </div>
                  <Link
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                    to={"/approver/dashboard"}
                    className=" w-full "
                  >
                    <div className="leading-[20px] border-[#0C4AFF] border-solid border-[1px] rounded-[8px]  bg-[#0C4AFF] text-lg text-[#fff] flex justify-center font-medium h-fit px-[20px] py-[12px] w-full ">Ok</div>
                  </Link>
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
}

export default AddProcessor;
