import support from "../Images/Support.svg";
import start from "../Images/Start.svg";
import secured from "../Images/Secured.svg";
import photo1 from "../Images/img1.png";
import photo2 from "../Images/img2.png";
import photo3 from "../Images/img3.png";
import photo4 from "../Images/img4.png";
import photo5 from "../Images/img5.png";

function WhyChooseIje() {
  const features = [
    {
      icon: start,
      title: "Start Campaign",
      description: "Students can create campaigns to raise up to 100% of their funds through donations from friends, family, and the community, offering a meaningful way to support their educational goals.",
    },
    {
      icon: secured,
      title: "Gain access to loans",
      description: "When the donations recieved is not up to thhe total funding required, ije will compliment with a loan, repayable over 5 years.",
      // Ije provides an easy way for students to secure the financial support they need to succeed in their studies.
    },
    {
      icon: support,
      title: "Support Students",
      description: "Contributors can choose to donate directly to students of their choice, offering a direct and impactful way to support education.",
    },
  ];
  return (
    <>
      <div className=" w-full px-[32px] 100:py-[62px] md:py-[96px] flex-col flex justify-between items-center">
        <section className=" xl:w-[1260px]  pb-[50px] gap-[96px] flex justify-between items-start">
          <div className=" w-full gap-[20px] flex flex-col items-start justify-start">
            {/* <section className="  gap-[12px] text-left w-full flex flex-col "> */}
            {/* <h5 className=" text-blue-700 text-base font-medium  leading-tight">Key Benefits</h5> */}

            <h2 className=" font-semibold tracking-[-2px] text-[#3A3C3F] text-[38px] leading-[42px] ">
              {" "}
              Why choose <span className=" bg-[#DFF9E4] w-fit h-[48px] px-[5px] text-[#0C4CFF]">Ije?</span>
            </h2>
            {/* </section> */}
            <p className="text-[20px] leading-[30px] text-[#6C757D] w-full font-normal text-justify">
              {/* Ije empowers African students to achieve their dreams of studying abroad through a unique co-financing service. Our platform allows prospective students to raise money for tuition fees and living expenses by combining donations through
              campaigns and loans from Ije. We provide a comprehensive solution that supports students at every step of their journey, making international education accessible and attainable.{" "} */}
              Ije empowers African students to achieve their dreams of studying abroad through a unique co-financing service. Our platform allows prospective students to raise 100% of their tuition fees and living expenses by combining donations
              through self-initiated campaigns and loans from Ije. We provide a comprehensive solution that supports students at every step of their journey, making international education more accessible and attainable.
            </p>
          </div>
          <div className=" 100:hidden lg:flex  w-full gap-[32px] flex-col justify-start items-start  ">
            {features.map((item, id) => (
              <div className=" gap-[16px] pt-[10px] flex justify-center items-start">
                <div className="w-12 h-12  bg-[#E8F7FF] flex justify-center items-center rounded-3xl">
                  <img className="w-[24px] h-[24px]" alt="Icon" src={item.icon} />
                </div>
                <section className="   text-left flex-col flex gap-[8px] items-start justify-start ">
                  <h4 className="  text-neutral-700 text-xl font-medium  leading-loose">{item.title}</h4>
                  <p className="text-gray-500 text-base text-pretty font-normal  leading-normal">{item.description}</p>
                </section>
              </div>
            ))}
          </div>
        </section>
        <section className="flex 100:w-full lg:w-full 2xl:w-[1350px] 100:flex-col lg:flex-row lg:px-[50px] gap-[10px] justify-center  items-center">
          <div className="flex w-full  flex-col gap-[10px] justify-center  items-center">
            <img className="w-full" src={photo1} alt="Student" />
            <img className="w-full" src={photo2} alt="Student" />
          </div>
          <div className="flex w-full flex-col gap-[10px] justify-center  items-center">
            <img className="w-full" src={photo3} alt="Student" />
          </div>
          <div className="flex w-full flex-col gap-[10px] justify-center  items-center">
            <img className="w-full" src={photo4} alt="Student" />
            <img className="w-full" src={photo5} alt="Student" />
          </div>
        </section>
        <div className=" 100:flex lg:hidden pt-[50px] w-full gap-[32px] flex-col justify-start items-start  ">
          {features.map((item, id) => (
            <div className=" gap-[10px] pt-[10px] flex justify-center items-start">
              <div className="w-12 h-12  bg-[#E8F7FF] flex justify-center items-center rounded-3xl">
                <img className="w-[24px]" alt="Icon" src={item.icon} />
              </div>
              <section className="100:w-full sm:w-[400px]  text-left flex-col flex gap-[8px] items-start justify-start ">
                <h4 className="  text-neutral-700 text-xl font-medium  leading-loose">{item.title}</h4>
                <p className="text-gray-500 text-base font-normal  leading-normal">{item.description}</p>
              </section>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default WhyChooseIje;
