import { useEffect, useState } from "react";
import cancel from "../Images/Cancel.svg";
import confirm from "../Images/Confirm.svg";
import { Link } from "react-router-dom";
import { ReactComponent as Back } from "../Images/back.svg";
import logo from "../Images/Logo.svg";
import upload from "../Images/upload.svg";
import { useFormik } from "formik";
import { createCampaignValidator } from "../../utils/services/validation";
import { useAppSelector } from "../../utils/redux/store";
import TextInput from "../shared/input/TextInput";
import TextArea from "../shared/input/TextArea";
import CampaignRequest from "../../utils/services/campaignRequests";
import SuccessAlert from "../shared/Alerts/success_alert";
import { toast } from "react-toastify";
import FileInput from "../shared/input/FileInput";
import { CurrencyFormatter } from "../../utils/helpers/helper";

function CampaignForm() {
  const [navBar, setNavBar] = useState(false);
  const [modal, setModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isData, setData] = useState({});
  const [isEdit, setEdit] = useState(false);
  const [success, setSuccess] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    function handleScrollBackground() {
      if (window.scrollY >= 20) {
        setNavBar(true);
      } else {
        setNavBar(false);
      }
    }
    window.addEventListener("scroll", handleScrollBackground);

    return () => {
      window.removeEventListener("scroll", handleScrollBackground);
    };
  }, []);
  const getCampaigns = async () => {
    await CampaignRequest.getCampaigns().then((res) => {
      // console.log(res.data.result);

      if (res?.data?.result?.campaignTitle !== "" || undefined || null) {
        setData(res.data.result ? res.data.result : null);
        setEdit(true);

        formik.resetForm({
          values: {
            campaignTitle: res.data.result?.campaignTitle || "",
            description: res.data.result?.description || "",
            email: email,
            // fundingGoal: res.data.result?.fundingGoal || "",
            campaignVideo: res.data.result?.campaignVideo || "",
            campaignImage: res.data.result?.campaignImage || "",
          },
        });
      }
    });
  };

  const { email, educationInformation } = useAppSelector((state) => state.auth);

  // console.log(educationInformation?.totalFundingRequirement)
  const formik = useFormik({
    validationSchema: createCampaignValidator,
    initialValues: {
      campaignTitle: "",
      description: "",
      email: email,
      // fundingGoal: "",
      campaignVideo: "",
      campaignImage: "",
    },
    onSubmit: async (values) => {
      setLoading(true);


      if (isData.length !== 0) {
        console.log("updating");

        const payload = {
          // email: email,
          campaignTitle: values.campaignTitle || isData.campaignTitle,
          description: values.description || isData.description,
          // fundingGoal: values.fundingGoal || isData.fundingGoal,
          campaignVideo: values.campaignVideo || isData.campaignVideo,
          campaignImage: values.campaignImage || isData.campaignImage,
        };

        // 
        await CampaignRequest.updateCampaign(
          payload.campaignTitle,
          payload.description,
          payload.campaignVideo,
          payload.campaignImage
        ).then((res) => {
          setLoading(false);
          // ;

          if (res.data.statusCode === 200) {
            setModal(true);
            setSuccess(true);
            toast.success("successful", {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else if (res.status === 500) {
            setErrorMessage("network error try again later");
            setSuccess(false);
            toast.error(res?.data?.message || res?.message, {
              position: "top-right",
              autoClose: 2000,
              theme: "colored",
            });
          } else {
            setErrorMessage(res.data.message);
            setSuccess(false);
            toast.error(res?.data?.message || res?.message, {
              position: "top-right",
              autoClose: 2000,
              theme: "colored",
            });
          }
        });
      } else {
        console.log("new");
        await CampaignRequest.createCampaign(
          values.campaignTitle,
          values.description,
          values.campaignVideo,
          values.campaignImage
        ).then((res) => {
          setLoading(false);
          // ;

          if (res.data.statusCode === 200) {
            setModal(true);
            setSuccess(true);
            toast.success("successful", {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else if (res.status === 500) {
            setErrorMessage("network error try again later");
            setSuccess(false);
            toast.error(res?.data?.message || res?.message, {
              position: "top-right",
              autoClose: 2000,
              theme: "colored",
            });
          } else {
            setErrorMessage(res.data.message);
            setSuccess(false);
            toast.error(res?.data?.message || res?.message, {
              position: "top-right",
              autoClose: 2000,
              theme: "colored",
            });
          }
        });
      }
    },
  });

  useEffect(() => {
    getCampaigns();
  }, []);

  // console.log(isData)
  const handleFileChange = async (e) => {
    const { name, files } = e.target;
    if (files[0]) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1]; // Remove the prefix

        formik.setFieldValue(name,base64String);
        // console.log(name, formik.values);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  return (
    <>
      <div className=" bg-white flex-col h-fit w-full gap-[74px]   items-center flex justify-start  ">
        <div className="fixed  top-0 z-20 w-full flex items-center p-[10px] justify-center">
          <div
            className={` rounded-[50px]   px-[30px] my-[10px] ${
              navBar ? "bg-[#D5EFFF] " : ""
            } h-[75px] flex justify-between items-center w-[90%] `}
          >
            <Link to={"/user/campaign"}>
              <div className="text-[#0C4AFF] gap-[8px] flex justify-start items-center ">
                <Back />
                <h4 className="  text-[16px] font-medium leading-[24px] ">
                  Back
                </h4>
              </div>
            </Link>
            <div>
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                to="/"
              >
                <img className="" src={logo} alt="Ije" />
              </Link>
            </div>
            <div className=" w-[66px] 100:hidden phone:block"></div>
          </div>
        </div>
        <div className="pb-[100px] 100:w-[85%] pt-[180px] md:w-[480px] gap-[64px] flex-col flex justify-center items-center ">
          <div className="flex-col flex gap-[12px] items-center justify-start">
            <h4 className=" text-center text-blue-600 text-base font-semibold  leading-normal">
              Create Your Campaign
            </h4>
            <h2 className=" 100:w-full md:w-[678px] text-center text-neutral-700 text-3xl font-semibold  leading-10">
              Provide the details of your campaign to get started with securing
              your funding.
            </h2>
          </div>
          <form className="flex-col w-full flex gap-[32px] justify-start items-start ">
            <TextInput
              label={"Campaign Title"}
              onBlur={formik.handleBlur("campaignTitle")}
              type="text"
              value={formik.values.campaignTitle}
              onChange={formik.handleChange("campaignTitle")}
              placeholder={
                isData?.campaignTitle || "Enter the title of your campaign"
              }
              error={
                formik.touched.campaignTitle && formik.errors.campaignTitle
              }
            />

            <TextArea
              label={"Description"}
              onBlur={formik.handleBlur("description")}
              type="text"
              value={formik.values.description}
              onChange={formik.handleChange("description")}
              placeholder={
                isData?.description || "Describe your campaign in detail"
              }
              error={formik.touched.description && formik.errors.description}
              hint={"   Not more than 1000 characters"}
              limit={1000}
            />

            {/* <TextInput
              label={"Funding Goal"}
              onBlur={formik.handleBlur("fundingGoal")}
              type="text"
              value={formik.values.fundingGoal}
              onChange={formik.handleChange("fundingGoal")}
              placeholder={isData?.fundingGoal || "Enter the amount you need"}
              error={formik.touched.fundingGoal && formik.errors.fundingGoal}
            /> */}
            <div className="w-full flex-col justify-start items-start gap-1.5 flex">
              <h3 className="text-[#4a4f54] text-sm font-medium  leading-tight">
                Funding Goal
              </h3>
              <div className="w-full px-4 py-3 bg-white rounded-lg h-[48px] shadow border border-[#cdd1d4] justify-start items-center gap-2 inline-flex  text-[#0f1728] text-base font-normal  leading-normal">
                {CurrencyFormatter(
                  parseFloat(educationInformation?.totalFundingRequirement) || 0,
                  "$"
                )}
              </div>
            </div>

            {/* <label className=" file-input-container w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium " htmlFor="campaignVideo">
              A concise 30-second campaign video
              <div className=" w-full ">
                <input onChange={handleFileChange} type="file" className="hidden" id="campaignVideo" name="campaignVideo" />
                <div style={{ width: "100%" }}>
                  <div className=" w-full h-[126px] bg-white border-[#CDD1D4] border rounded-[8px] flex-col justify-center items-center flex ">
                    <div className="h-[94px] flex-col justify-center items-center flex">
                      <div className="w-10 h-10 p-2.5 bg-[#E5E7E8] rounded-[28px] border-4 border-[#F5F6F6] justify-center items-center inline-flex">
                        <img src={upload} alt="upload" />
                      </div>
                      <div className="self-stretch justify-center items-start gap-1 flex-col flex">
                        <div className="justify-center w-full flex-col items-center gap-[12px] flex">
                          <div className="text-[#0C4AFF] text-sm font-normal  ">
                            Click to upload <span className="text-[#6C757D]">or drag and drop</span>
                          </div>
                          <div className=" text-center text-[#6C757D] text-xs font-normal  ">(max. 2mb)</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </label> */}

            <FileInput
              label={"A concise 30-second campaign video"}
              error={formik.errors.campaignVideo}
              onChange={handleFileChange}
              name="campaignVideo"
            />

            <FileInput
              label={"Campaign Image"}
              error={formik.errors.campaignImage}
              onChange={handleFileChange}
              name="campaignImage"
            />

            <label
              className=" w-full flex-wrap flex justify-start items-center gap-[6px] leading-[20px] text-[#6c757d] text-sm font-normal "
              htmlFor="private"
            >
              <input
                required
                className="w-[20px] outline-[#0C4AFF] bg-white h-[20px]  rounded-[6px] border border-[#CDD1D4]     "
                type="checkbox"
              />
              Set campaign to private
            </label>
            <div className=" w-full 100:gap-[16px] phone:gap-0 100:flex-col-reverse phone:flex-row justify-between items-start inline-flex">
              <Link className=" phone:w-full 100:w-full" to="/user/campaign">
                <div className="h-12 text-[#4a4f54] text-base font-medium leading-normal px-5 py-3 bg-white rounded-lg shadow border border-[#aab0b6] justify-center phone:w-[183px] 100:w-full items-center  flex">
                  Cancel
                </div>
              </Link>
              <button
                // onClick={() => setModal(true)}
                onClick={formik.handleSubmit}
                className=" phone:w-full 100:w-full"
                to=""
              >
                <div className="leading-[20px] border-[#0C4AFF] border-solid border-[1px] rounded-[8px]  bg-[#0C4AFF] text-base text-[#fff] flex justify-center font-medium h-[48px] items-center py-4 phone:fit 100:w-full ">
                  {!isLoading
                    ? isData.length !== 0
                      ? "Update Campaign"
                      : " Create Campaign"
                    : "Loading..."}
                </div>
              </button>
            </div>

            <div className=" w-full flex justify-center items-center">
              {success !== null && (
                <SuccessAlert
                  success={success}
                  info={success === true ? "Campaign Created" : errorMessage}
                />
              )}
            </div>
          </form>
        </div>
      </div>
      {modal && (
        <>
          <div className="w-full h-[100%] top-0 left-0 fixed z-40  bg-slate-700 bg-opacity-60 backdrop-blur-lg flex-col justify-start items-center flex">
            <section className=" w-full flex justify-end pt-[25px] pr-[40px] items-end">
              <div
                onClick={() => setModal(false)}
                className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  "
              >
                <img className=" " src={cancel} alt="Cancel" />
              </div>
            </section>
            <section className=" 100:w-[80%] h-full iphone:w-full flex justify-center  items-center">
              <div className=" 100:w-full iphone:w-96 100:h-fit iphone:h-64 p-[24px] bg-white rounded-xl shadow flex-col justify-start items-center flex">
                <div className="w-full flex-col justify-start items-center gap-[32px] flex">
                  <div className="w-full flex-col justify-start items-center gap-5 flex">
                    <div className="w-12 h-12   justify-center flex  items-center  bg-emerald-100 rounded-3xl border-8 border-emerald-50  ">
                      <img className=" " src={confirm} alt="Confirm" />
                    </div>
                    <div className="w-full flex-col justify-start items-center gap-2 flex">
                      <h3 className="self-stretch text-center text-gray-900 text-lg font-medium  leading-7">
                        Created Successfully
                      </h3>
                      <p className="self-stretch text-center text-gray-500 text-sm font-normal  leading-tight">
                        Your campaign has been created successfully. View and
                        manage it from your dashboard.
                      </p>
                    </div>
                  </div>
                  <Link className="w-full" to="/user/campaign">
                    <div className="leading-[20px] border-[#0C4AFF] border-solid border-[1px] rounded-[8px]  bg-[#0C4AFF] text-lg text-[#fff] flex justify-center font-medium h-fit px-[20px] py-[12px] w-full ">
                      Back to Dashboard
                    </div>
                  </Link>{" "}
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
}

export default CampaignForm;
