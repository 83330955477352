import { useFormik } from "formik";
import { ReactComponent as Logo } from "../Images/Logo-with-text.svg";
import { Link } from "react-router-dom";
import { visitorSubscribeValidator } from "../../utils/services/validation";
import { useState } from "react";
import MiscRequest from "../../utils/services/miscRequests";

function Footer() {
  const [isLoading, setLoading] = useState(false);
  const formik = useFormik({
    validationSchema: visitorSubscribeValidator,
    initialValues: {
      email: "",
    },
    onSubmit: async (values) => {
      console.log(values);
      setLoading(true);

      await MiscRequest.subscribe(values.email).then((res) => {
        setLoading(false);
      });
    },
    enableReinitialize: true,
  });
  return (
    <>
      <div className="mb-[-80px]  w-full h-fit py-[96px] bg-[#3A3C3F] flex-col gap-[64px] 100:px-[32px] md:px-[80px]  flex justify-center items-center">
        <div className=" 100:flex-col 100:gap-[48px] lg:gap-0 lg:flex-row flex justify-between items-start w-full">
          <div className=" flex text-[#fff] w-full flex-col justify-start items-start gap-[32px]">
            <div className=" w-full xl:justify-start xl:items-start  flex 100:justify-center 100:items-center">
              {/* <img className=" !text-white " src={logo} alt="IJE" /> */}
              <Logo style={{ color: "#fff" }} />
            </div>
            <nav className=" flex gap-[32px] 100:flex-col text-left md:flex-row justify-start 100:items-start md:items-center">
              <h4 className="text-neutral-300 text-base font-medium leading-normal">Overview</h4>
              <h4 className="text-neutral-300 text-base font-medium leading-normal">Features</h4>
              <h4 className="text-neutral-300 hover:underline hover:text-[#0C4CFF] text-base font-medium leading-normal">Pricing</h4>
              <h4 className="text-neutral-300 text-base font-medium leading-normal">Careers</h4>
              <h4 className="text-neutral-300 text-base font-medium leading-normal">Help</h4>
              <Link onClick={() => window.scrollTo(0, 0)} to="/legal/termsofuse">
                <h4 className="text-neutral-300 hover:underline hover:text-[#0C4CFF] text-base font-medium leading-normal">Terms</h4>
              </Link>
              <Link onClick={() => window.scrollTo(0, 0)} to="/legal/privacypolicy">
                <h4 className="text-neutral-300 hover:underline hover:text-[#0C4CFF] text-base font-medium leading-normal">Privacy</h4>
              </Link>
            </nav>
          </div>
          <div className=" 100:w-full phone:w-fit flex gap-[16px] flex-col justify-start items-start">
            <h4 className=" font-semibold text-white text-[14px] leading-[20px]">Stay up to date</h4>
            <form className="flex w-full 100:flex-col phone:flex-row  justify-start items-start gap-[16px]">
              <input
                required
                className=" rounded-[8px] outline-[#0C4CFF] border border-[#AAB0B6] text-[#3A3C3F] placeholder:text-[#6C757D] text-[16px] font-normal px-[14px] 100:w-full bg-[#fff] phone:w-[230px] h-[44px] "
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange("email")}
                placeholder="Enter your email"
                onBlur={formik.handleBlur("email")}
              />{" "}
              <button onClick={formik.handleSubmit} className="bg-[#0C4CFF] 100:w-full phone:w-fit border border-[#0C4CFF] text-[16px] text-[#fff] font-medium rounded-[8px] px-[18px] py-[10px] ">
                Subscribe
              </button>
            </form>
          </div>
        </div>
        <div className="w-full  border-t-gray-600 border-t pt-8 justify-center items-center gap-8 flex">
          <section className=" 100:flex-col lg:flex-row  100:gap-[20px] flex 100:justify-center 100:items-center lg:justify-between lg:items-start w-full">
            <h5 className="grow shrink basis-0 text-gray-500 text-base font-normal leading-normal">© 2024 Ije. All rights reserved.</h5>
            <div className="w-fit justify-start items-start gap-4 inline-flex">
              <a href="https://www.linkedin.com/company/ijefund/">
                <h5 className="text-neutral-300 hover:underline hover:text-[#0C4CFF] text-base font-normal  leading-normal">Linkedin</h5>
              </a>
              <a href="https://www.facebook.com/profile.php?id=61562789349575">
                <h5 className="text-neutral-300 hover:underline hover:text-[#0C4CFF] text-base font-normal  leading-normal">Facebook</h5>
              </a>
              <a href="https://www.instagram.com/ije_fund/?utm_source=ig_web_button_share_sheet">
                <h5 className="text-neutral-300 hover:underline hover:text-[#0C4CFF] text-base font-normal  leading-normal">Instagram</h5>
              </a>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Footer;
