// import ComingSoon from "./Components/ComingSoon";
import { BrowserRouter, Route, Router, RouterProvider, Routes } from "react-router-dom";
// import Homepage from "./Components/LandingPage/Homepage";
// import AboutUs from "./Components/LandingPage/AboutUs";
import { router } from "./routes";
import UserManagement from "./Components/ApproverAdmin/UserManagement";
import UserDetails from "./Components/ApproverAdmin/UserDetails";

function App() {
  return (
    <>
      <RouterProvider router={router} />
      {/* <ComingSoon /> */}
      {/* <BrowserRouter>
        <Routes>
        
        </Routes>
      </BrowserRouter> */}
      {/* <BrowserRouter> */}
      {/* <Router> */}
      {/* <Routes> */}
      {/* Main User Management Page */}
      {/* <Route path="user-management" element={<UserManagement />} /> */}

      {/* User Details Subpage */}
      {/* <Route path="user-management/user-details" element={<UserDetails />} /> */}
      {/* </Routes> */}
      {/* </Router> */}
      {/* </BrowserRouter> */}
    </>
  );
}

export default App;
