function ContactUs() {
  const features = [
    {
      icon: (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M22 6.16016C22 5.06016 21.1 4.16016 20 4.16016H4C2.9 4.16016 2 5.06016 2 6.16016M22 6.16016V18.1602C22 19.2602 21.1 20.1602 20 20.1602H4C2.9 20.1602 2 19.2602 2 18.1602V6.16016M22 6.16016L12 13.1602L2 6.16016"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      title: "Chat to sales",
      description: "Speak to our friendly team.",
      contact: "ije@ijefund.com",
      type: "email",
    },
    {
      icon: (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M21.3333 11.6602C21.3368 12.9801 21.0284 14.2821 20.4333 15.4602C19.7278 16.8719 18.6431 18.0594 17.3008 18.8895C15.9585 19.7196 14.4116 20.1596 12.8333 20.1602C11.5135 20.1636 10.2115 19.8553 9.03334 19.2602L3.33334 21.1602L5.23334 15.4602C4.63827 14.2821 4.3299 12.9801 4.33334 11.6602C4.33395 10.0819 4.77396 8.53504 5.60406 7.19273C6.43417 5.85043 7.6216 4.76575 9.03334 4.06018C10.2115 3.46512 11.5135 3.15674 12.8333 3.16018H13.3333C15.4177 3.27518 17.3864 4.15495 18.8625 5.63104C20.3386 7.10714 21.2184 9.07584 21.3333 11.1602V11.6602Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      title: "Chat to support",
      description: "We’re here to help.",
      contact: "ije@ijefund.com",
      type: "email",
    },
    {
      icon: (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M22.6666 17.0803V20.0803C22.6678 20.3588 22.6107 20.6344 22.4991 20.8896C22.3876 21.1448 22.2239 21.3738 22.0187 21.5621C21.8135 21.7504 21.5712 21.8937 21.3074 21.983C21.0435 22.0722 20.764 22.1053 20.4866 22.0803C17.4095 21.7459 14.4536 20.6944 11.8566 19.0103C9.44045 17.4749 7.39196 15.4264 5.85662 13.0103C4.1666 10.4015 3.11486 7.43125 2.78662 4.34025C2.76163 4.06372 2.7945 3.78501 2.88312 3.52188C2.97175 3.25874 3.11419 3.01694 3.30139 2.81187C3.48858 2.60681 3.71642 2.44296 3.97041 2.33078C4.2244 2.21859 4.49896 2.16051 4.77662 2.16025H7.77662C8.26193 2.15548 8.73241 2.32733 9.10038 2.64379C9.46835 2.96024 9.7087 3.3997 9.77662 3.88025C9.90324 4.84032 10.1381 5.78298 10.4766 6.69025C10.6112 7.04818 10.6403 7.43717 10.5605 7.81113C10.4808 8.1851 10.2955 8.52836 10.0266 8.80025L8.75662 10.0703C10.1802 12.5738 12.2531 14.6467 14.7566 16.0703L16.0266 14.8003C16.2985 14.5314 16.6418 14.3461 17.0157 14.2663C17.3897 14.1866 17.7787 14.2157 18.1366 14.3503C19.0439 14.6888 19.9866 14.9236 20.9466 15.0503C21.4324 15.1188 21.876 15.3635 22.1932 15.7377C22.5103 16.112 22.6788 16.5898 22.6666 17.0803Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      title: "Call Us",
      description: "Mon-Fri from 8am to 5pm.",
      contact: "+234 816 315 3270",
      type: "tel",
    },
  ];

  return (
    <>
      <div className=" w-full py-[96px] bg-white  flex justify-center items-center">
        <section className="100:w-[85%] xl:w-[1216px] xl:px-0  lg:w-full lg:px-[80px] gap-[64px] flex justify-start items-start  flex-col">
          <div className="100:w-full lg:w-[750px] gap-[20px] text-left flex flex-col items-start justify-start">
            <div className="flex flex-col gap-[12px] items-start justify-start">
              <h2 className=" font-semibold  text-[#0C4AFF] text-[16px] leading-[24px] ">Contact us</h2>

              <h2 className=" font-semibold tracking-[-2px] text-[#3A3C3F] text-[38px] leading-[42px] ">Get in Touch with Us</h2>
            </div>
            <p className="text-[20px]  leading-[30px] text-[#6C757D] w-full font-normal">We're here to help! Reach out with any questions or concerns, and our team will assist you promptly.</p>
          </div>
          <div className="w-full 100:flex-col lg:flex-row flex justify-center items-center gap-[24px]">
            {features.map((item, id) => (
              <div key={id} className=" w-full flex p-[24px] flex-col h-fit justify-start items-start gap-[64px] rounded-[8px] bg-[#F5F6F6] ">
                <div className=" h-[48px] flex justify-center items-center rounded-[10px] bg-[#2F7AFF] w-[48px]">{item.icon}</div>
                <section className="flex-col justify-start items-start gap-5 inline-flex">
                  <div className=" flex-col justify-start items-start gap-2 flex">
                    <h4 className=" text-blue-800 text-xl font-medium  leading-loose">{item.title}</h4>
                    <p className=" text-blue-600 text-base font-normal  leading-normal">{item.description}</p>
                  </div>
                  {item.type === "email" ? (
                    <a href={`mailto:${item.contact}`}>
                      <h3 className="text-blue-600 text-base font-medium leading-normal">{item.contact}</h3>
                    </a>
                  ) : item.type === "tel" ? (
                    <a href={`tel:${item.contact}`}>
                      <h3 className="text-blue-600 text-base font-medium leading-normal">{item.contact}</h3>
                    </a>
                  ) : (
                    <h3 className="text-base text-blue-600 font-medium leading-normal">{item.contact}</h3>
                  )}
                </section>
              </div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
}

export default ContactUs;
