import axios from "axios";

const MiscRequest = {
  subscribe: async (email) => {
    let payload = {
      email,
    };

    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await axios
      .post(
        process.env.REACT_APP_BASE_URL + "/rest/visitor/subscribe",
        payload,
        {
          headers: {},
          method: "POST",
        }
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  getCampaigns: async (id) => {
    let payload = {
      id,
    };
    let res = {
      status: 0,
      data: {},
      message: "",
    };
    return await axios
      .get(`${process.env.REACT_APP_BASE_URL}/usercampaign/${id}`)
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  getAllCampaigns: async () => {
    let res = {
      status: 0,
      data: {},
      message: "",
    };
    return await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/rest/campaign/get-public-campaigns`
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  getCampaignWallet: async (id) => {
    let payload = {
      id,
    };
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/rest/wallet/get-campaign-wallet-details`,
        payload
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  getBanks: async () => {
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/rest/wallet/get-banks
`
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  postConfirmPayment: async (bank, email, accountNumber) => {
    let payload = {
      bank,
      email,
      accountNumber,
    };
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/rest/wallet/authorizer-confirm-payment`,
        payload
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },
};

export default MiscRequest;
