import { useState } from "react";
import open from "../../Images/open.svg";
import viewOff from "../../Images/viewOff.svg";
const TextInput = ({ type, value, onChange, placeholder, onBlur, label, error, hint, limit, readOnly }) => {
  const [view, setView] = useState(false);
  return (
    <>
      <label className={` w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium `}>
        {label}
        <div
          className={`w-full border ${
            error ? "outline-red-600 border-red-600" : "outline-[#0C4AFF] border-[#CDD1D4]"
          }  bg-white flex flex-row  items-center h-[48px]  rounded-[8px] text-[#3A3C3F] placeholder:text-[#6C757D] font-normal leading-[24px] text-[16px]   `}
        >
          <input
            required
            // type={type}
            readOnly={readOnly}
            type={type === "password" && view ? "text" : type}
            value={value}
            className="w-full h-full outline-none  px-[16px] rounded-[8px] text-[#3A3C3F] placeholder:text-[#6C757D] font-normal leading-[24px] text-[16px]   "
            onChange={onChange}
            placeholder={placeholder}
            onBlur={onBlur}
            maxLength={limit}
          />

          {type === "password" && (
            <span onClick={() => setView(!view)} className=" cursor-pointer w-[40px]">
              {view ? <img alt="view" src={open} /> : <img alt="view-off" src={viewOff} />}
            </span>
          )}
        </div>
        {hint && <h4 className="text-[#808990] text-xs font-normal leading-tight">{hint}</h4>}
        {error && <div className="text-red-500">{error}</div>}
      </label>
    </>
  );
};

export default TextInput;
