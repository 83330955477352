import successor1 from "../Images/successor1.png";
import successor2 from "../Images/successor2.png";
import successor3 from "../Images/successor3.png";

function SuccessStory() {
  const features = [
    {
      picture: successor1,
      description: "Ije helped me to secure the funding I needed to study in the UK for my masters degree. Thanks to Ije for helping me make my dream a reality.",
      title: "Student",
      name: "Temitope Ogunsanya, UK",
    },
    {
      picture: successor2,
      description: "With Ije’s flexible repayment terms, I am able to pursue my academic goals of having an international education.",
      title: "Supply Chain Specialist",
      name: "Titilope Ogunsesimi, Malta",
    },
    // {
    //   picture: successor3,
    //   description: "Thanks to Ije, I was able to pursue my computer science degree abroad. The support I received was overwhelming and life-changing.",
    //   title: "Software Engineer",
    //   name: "Akinmoye Olayemi",
    // },
  ];
  return (
    <>
      <div className=" h-fit w-full py-[96px] gap-[64px] bg-[#079A91] flex-col flex justify-center items-center">
        <section className="100:w-[95%] sm:w-[85%] xl:w-[1216px] xl:px-0  lg:w-full lg:px-[80px] gap-[64px] flex justify-center items-center  flex-col">
          <div className=" 100:w-full lg:w-[750px] gap-[20px] text-center flex flex-col items-center justify-center">
            <h2 className=" font-semibold  text-[#E5E7E8] text-[16px] leading-[24px] ">Our success stories</h2>
            <p className="text-[36px]  leading-[44px] tracking-[-2px] text-[#fff] w-full font-normal">Build credibility and trust through the stories of our successful users.</p>
          </div>
          <div className="w-full flex-col flex justify-center items-center gap-[32px]">
            {features.map((item, id) => (
              <div key={id} className=" flex flex-col justify-center text-center items-center rounded-[16px] 100:w-[85%] xl:w-[1216px] h-fit gap-[32px] bg-[#CAFDF3] p-[64px]">
                {/* <img className="w-[64px] h-[64px] rounded-full" src={item.picture} alt={item.title} /> */}
                <div className=" flex flex-col w-full justify-center items-center gap-[8px]">
                  <h3 className="text-[#0c4aff] text-xl font-medium  leading-tight">{item.name}</h3>
                  <h4 className=" text-[14px] text-[#6C757D] font-normal leading-[20px]  ">{item.title}</h4>
                </div>
                <p className=" w-full 100:text-[30px] md:text-[36px] text-[#4A4F54] font-medium leading-[44px] tracking-[-2px] ">"{item.description}"</p>
              </div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
}

export default SuccessStory;
