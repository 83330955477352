import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AdminRequest from "../../../utils/services/adminRequests";
import user from "../../Images/userID.png";

function BasicInfo({data}) {
 
  return (
    <>
      <div className="h-fit w-full flex-col justify-start items-start inline-flex">
        <div className=" w-full  100:h-fit 100:flex-col sm:flex-row  sm:h-[400px] sm:pr-6 bg-white rounded-lg shadow border border-[#cdd1d4] justify-start items-start gap-8 inline-flex">
          <div className=" 100:w-full   h-full sm:w-[175px]  p-5 bg-[#e5e7e8] rounded-tl-lg md:rounded-bl-lg justify-center items-start gap-2.5 flex">
            <img className="w-24 h-24 relative rounded-[200px] shadow border-4 border-white" src={data?.imageString ? data.imageString : user} alt={(data?.firstName, data?.lastName)} />
          </div>
          <div className="h-fit 100:px-[24px] sm:px-0 w-full 100:pb-6 sm:py-6 flex-col justify-start items-start gap-4 inline-flex">
            <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
              <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
                <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                  <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">Full Name</h4>
                  <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                    {data?.firstName} {data?.lastName}
                  </h3>
                </div>
              </div>
            </div>
            <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
              <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
                <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                  <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">Email Address</h4>
                  <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">{data?.email}</h3>
                </div>
              </div>
            </div>
            <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
              <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
                <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                  <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">Phone Number</h4>
                  <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">{data?.phoneNumber}</h3>
                </div>
              </div>
            </div>
            <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
              <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
                <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                  <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">Address</h4>
                  <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">{data?.address}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BasicInfo;
