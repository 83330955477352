import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AdminRequest from "../../../utils/services/adminRequests";
import pick from "../../Images/file.svg";

function IdentityVerification({data}) {
 
  return (
    <>
      <div className=" w-full p-6 bg-white flex-col rounded-lg shadow border border-[#cdd1d4] justify-start items-start gap-[16px] inline-flex">
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
              <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">Linkedin profile</h4>
              <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                <a
                  href={`${data?.educationInformation?.linkedInAddress?.startsWith("http") ? data.educationInformation.linkedInAddress : `https://${data?.educationInformation?.linkedInAddress}`}`}
                  className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data?.educationInformation?.linkedInAddress}
                </a>
              </h3>
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
              <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">International Passport Number</h4>
              <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">{data?.educationInformation?.passportNumber}</h3>
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
              <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">Bank Verification Number (BVN)</h4>
              <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">{data?.educationInformation?.bvn}</h3>
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
              <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">Copy of International Passport</h4>
              <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                {" "}
                <a className="w-full" href={data?.educationInformation?.internationalPassport} download={true} target="_blank" rel="noopener noreferrer">
                  <div className="w-full gap-4 justify-start items-center  inline-flex">
                    <div className="w-8 h-8  bg-[#d5efff] flex justify-center items-center rounded-[28px] border-4 border-[#e8f7ff] ">
                      <img src={pick} alt="" />
                    </div>
                    <div className="gap-1 flex-col justify-start items-start inline-flex">
                      <h4 className="text-[#3a3c3f] text-base font-medium  leading-tight">International Passport</h4>
                      <h5 className="text-[#6c757d] text-xs font-normal leading-tight">200 KB</h5>
                    </div>
                  </div>{" "}
                </a>
              </h3>
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
              <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">X Profile</h4>
              <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                <a
                  href={`${data?.educationInformation?.twitterLink?.startsWith("http") ? data?.educationInformation?.twitterLink : `https://${data?.educationInformation?.twitterLink}`}`}
                  className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data?.educationInformation?.twitterLink}
                </a>
              </h3>
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
              <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">Instagram Profile</h4>
              <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                <a
                  href={`${data?.educationInformation?.instagramLink?.startsWith("http") ? data?.educationInformation?.instagramLink : `https://${data?.educationInformation?.instagramLink}`}`}
                  className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data?.educationInformation?.instagramLink}
                </a>
              </h3>
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
            <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
              <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">Facebook Profile</h4>
              <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                <a
                  href={`${data?.educationInformation?.facebookProfile?.startsWith("http") ? data?.educationInformation?.facebookProfile : `https://${data?.educationInformation?.facebookProfile}`}`}
                  className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data?.educationInformation?.facebookProfile}
                </a>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default IdentityVerification;
