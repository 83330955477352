import { Link } from "react-router-dom";
import SideBar from "./SideBar";
import { ReactComponent as Add } from "../Images/add.svg";
import { ReactComponent as Icon } from "../Images/Usersmanagement.svg";
import {
  AreaChart,
  Area,
  XAxis,
  PieChart,
  Pie,
  Cell,
  Legend,
  CartesianGrid,
  Tooltip,
} from "recharts";
// import { ReactComponent as Search } from "../Images/search.svg";
// import { ReactComponent as Calendar } from "../Images/calendar.svg";
// import { ReactComponent as Completed } from "../Images/complete.svg";
import CampaignRequest from "../../utils/services/campaignRequests";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../utils/redux/store";
import AdminRequest from "../../utils/services/adminRequests";
import UserManagementTable from "./component/userManagementTable";
import { formatDate } from "../../utils/helpers/helper";
import useDebounce from "../../utils/helpers/debounce";

function Dashboard() {
  const [calendar, setCalendar] = useState(false);
  const [isProgress, setProgress] = useState(0);
  const [approvedUsers, setapprovedUsers] = useState([]);
  const [pendingUsers, setpendingUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [allRejectedUsers, setRejectedUsers] = useState([]);
  const [isTopfive, setTopFive] = useState([]);
  const [isChart, setChart] = useState();
  const [allSearch, setAllSearch] = useState("");
  const getApproved = async () => {
    await AdminRequest.getApprovedUsers({search:""}).then((res) => {
      if (res?.data?.result !== undefined) {
        setapprovedUsers(res?.data?.result);
      }
    });
  };
  const getInprogress = async () => {
    await CampaignRequest.getAllCampaignInprogress().then((res) => {
      setProgress(res?.data?.numbers || 0);
    });
  };
  const getPending = async () => {
    await AdminRequest.getPendingUsers({search:""}).then((res) => {
      if (res?.data?.result !== undefined) {
        setpendingUsers(res?.data?.result);
      }
    });
  };

  const getallUser = async () => {
    await AdminRequest.getAllUsers({ search: allSearch }).then((res) => {
      if (res?.data?.result !== undefined) {
        setAllUsers(res?.data?.result);
      }
    });
  };

  const getFiveCampaign = async () => {
    await CampaignRequest.getFiveCampaigns().then((res) => {
      // console.log("5 camp", res);

      setTopFive(res.data.result);
    });
  };

  const getrejectedUser = async () => {
    await AdminRequest.getRejectedUsers({search:""}).then((res) => {
      if (res?.data?.result !== undefined) {
        setRejectedUsers(res?.data?.result);
      }
    });
  };

  const getMetrics = async () => {
    await AdminRequest.adminDashData().then((res) => {
      setChart(res.data.result);
    });
  };
  useEffect(() => {
    getFiveCampaign();
    getApproved();
    getPending();
    getrejectedUser();
    getInprogress();
    getallUser();
    getMetrics();
  }, []);

  const { firstName } = useAppSelector((state) => state.auth);

  const cards = [
    {
      id: 1,
      name: "Total Number of Users",
      icon: <Icon />,
      amount: ` ${allUsers?.length}`,
      back: "#D5EFFF",
      border: "#E8F7FF",
      text: "#0C4AFF",
    },
    {
      id: 2,
      name: "Active Users",
      icon: <Icon />,
      amount: `${approvedUsers?.length}`,
      back: "#DFF9E4",
      border: "#F1FCF2",
      text: "#1F7634",
    },
    {
      id: 3,
      name: "Inactive Users",
      icon: <Icon />,
      amount: `${allRejectedUsers.length + pendingUsers.length}`,
      back: "#FEE6E5",
      border: "#FEF2F2",
      text: "#B7192C",
    },
  ];

  const data = [
    {
      name: "Total Loans",
      value: isChart?.pieChartResponse?.totalLoan || 0,
    },
    {
      name: "Loans Approved",
      value: isChart?.pieChartResponse?.loanApproved || 0,
    },
  ];
  const COLORS = ["#0C4CFF", "#B3DFFF", "#D5EFFF"];

  // all search
  const debouncedSearchProduct = useDebounce(getallUser, 500);
  const handleAllSearch = (text) => {
    setAllSearch(text);
    debouncedSearchProduct(text);
  };

  return (
    <>
      <SideBar />
      <div className=" flex 100:justify-center items-center lg:justify-start flex-col 100:pt-[120px] md:pt-[120px] lg:pt-[40px] md:py-[32px]  lg:pr-8 w-full lg:pl-[362px]">
        <div className="flex gap-[32px] items-center 100:w-[85%] lg:w-full  justify-center flex-col">
          <div className=" w-full 100:flex-col 100:gap-[24px] md:gap-0 md:flex-row flex justify-between items-start">
            <div className=" w-full flex-col justify-start items-start gap-[15px] inline-flex">
              <h3 className="self-stretch text-[#0c4aff] text-2xl font-medium  leading-[38px]">
                Welcome
                <span className=" font-bold capitalize ">, {firstName}!</span>
              </h3>
              <h3 className="self-stretch  text-[#6c757d] text-base font-normal  leading-normal">
                View platform activity and manage users, campaigns, and loans.{" "}
              </h3>
            </div>
            <Link to="/approver/add-processor">
              <div className="h-10 w-fit whitespace-nowrap text-white text-sm font-medium flex-grow leading-tight px-4 py-2.5 bg-[#0c4cff] rounded-lg shadow border border-[#0c4cff] justify-center items-center gap-2 flex">
                <Add />
                Add Processor
              </div>
            </Link>
          </div>
          {/* CONTRIBUTION CARDS */}
          <div className=" w-full flex-col justify-start items-center gap-[8px] inline-flex">
            <h5 className="self-stretch text-[#6c757d] text-xs font-medium ">
              User Overview
            </h5>
            <div className=" w-full flex-1 flex 100:flex-wrap gap-[16px] xl:flex-nowrap justify-start items-start">
              {cards.map((item) => (
                <div
                  key={item.id}
                  className="h-fit  flex-grow lg:w-[348px] p-6 bg-white rounded-lg shadow border border-[#cdd1d4] justify-between items-start  inline-flex"
                >
                  <div className=" w-fit flex-col justify-start items-start gap-2.5 inline-flex">
                    <h4 className="text-[#4a4f54] text-sm font-normal leading-tight">
                      {item.name}
                    </h4>
                    <h3 className="text-[#3a3c3f] text-2xl font-semibold ">
                      {item.amount}
                    </h3>
                  </div>
                  <div
                    style={{
                      color: item.text,
                      backgroundColor: item.back,
                      borderColor: item.border,
                    }}
                    className={`w-12 h-12  flex justify-center items-center rounded-[28px]  border-8 `}
                  >
                    {item.icon}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* CHARTS */}
          <div className="100:flex-col z-10 xl:flex-row gap-[16px] flex w-full justify-start items-start">
            {/* AREA CHART */}
            <div className=" w-full flex-col justify-start items-center gap-[8px] inline-flex">
              <h5 className="self-stretch text-[#6c757d] text-xs font-medium ">
                User Engagement Metrics
              </h5>

              <div className="bg-white 100:p-4 md:p-6 rounded-lg shadow border border-[#cdd1d4] w-full overflow-x-scroll">
                <AreaChart
                  className="text-[12px] text-[#6C757D]"
                  width={630}
                  height={298}
                  data={isChart?.barChartResponses}
                  margin={{
                    left: 15,
                  }}
                >
                  <defs>
                    <linearGradient
                      id="gradientActiveUsers"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="0%" stopColor="#063CCD" stopOpacity={0.7} />
                      <stop
                        offset="70%"
                        stopColor="#56A1FF"
                        stopOpacity={0.2}
                      />
                    </linearGradient>
                    <linearGradient
                      id="gradientAverageTime"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="0%" stopColor="#56A1FF" stopOpacity={0.7} />
                      <stop
                        offset="70%"
                        stopColor="#A3C9FF"
                        stopOpacity={0.2}
                      />
                    </linearGradient>
                  </defs>
                  <Legend
                    color="#6C757D"
                    layout="horizontal"
                    align="right"
                    verticalAlign="top"
                  />
                  <CartesianGrid
                    vertical={false}
                    horizontal={true}
                    stroke="#E5E7E8"
                  />
                  <XAxis
                    dataKey="presentDate"
                    tickFormatter={(data) => formatDate(new Date(data))}
                  />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="activeCampaign"
                    stackId="1"
                    strokeWidth="2"
                    fill="url(#gradientActiveUsers)"
                    stroke="#063CCD"
                  />
                  <Area
                    type="monotone"
                    dataKey="activeUsers"
                    stackId="1"
                    strokeWidth="2"
                    fill="url(#gradientAverageTime)"
                    stroke="#56A1FF"
                  />
                  <Area
                    type="monotone"
                    dataKey="totalAmount"
                    stackId="1"
                    strokeWidth="2"
                    fill="url(#gradientAverageTime)"
                    stroke="#0C4CFF"
                  />{" "}
                </AreaChart>
              </div>
            </div>
            <div className=" w-full flex-col justify-start items-start gap-[8px] inline-flex">
              <h5 className="self-stretch text-[#6c757d] text-xs font-medium ">
                Campaign Success Rates
              </h5>

              <div className="bg-white p-6  rounded-lg shadow border border-[#cdd1d4] w-fit">
                <PieChart className=" flex  " width={210} height={258}>
                  {/* <Legend layout="horizontal" align="center" verticalAlign="bottom" /> */}

                  <Pie
                    data={data}
                    cx={105}
                    cy={100}
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                  >
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Pie
                    data={data}
                    cx={420}
                    cy={200}
                    startAngle={180}
                    endAngle={0}
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                  >
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
                <div className=" flex flex-col justify-start items-start gap-[4px] w-full">
                  <div className="justify-start items-center whitespace-nowrap gap-2 inline-flex text-[#6c757d] text-sm font-normal leading-tight">
                    {" "}
                    <div className="w-2 h-2 bg-[#0c4cff] rounded-full"></div>
                    Successful Loans:
                    <span className="text-[#4a4f54] font-medium ">
                      {" "}
                      {Math.round(
                        isChart?.pieChartResponse?.totalLoan || 0
                      ).toLocaleString()}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* TOP CAMPAIGNS */}
          <div className=" w-full flex-col justify-start items-center gap-[8px] inline-flex">
            <h5 className="self-stretch text-[#6c757d] text-xs font-medium ">
              Top 5 Campaigns
            </h5>
            <div className="h-fit w-full p-8 bg-white rounded-xl shadow flex-col justify-center items-start gap-[24px] inline-flex">
              {isTopfive.map((item) => (
                <div
                  key={item.id}
                  className="justify-center w-full flex-col items-start gap-2.5 inline-flex"
                >
                  <h3 className="text-[#4a4f54] text-base font-medium  leading-[38px]">
                    {item?.campaignTitle}
                  </h3>
                  <div className="h-9 flex-col w-full justify-start items-end gap-2 inline-flex">
                    <div className=" h-[8px] items-start flex justify-start bg-[#E8F7FF] rounded-[8px] w-full">
                      <div
                        style={{ width: `${item.progress}%` }}
                        className={`h-full  bg-[#0C4CFF] rounded-[8px] `}
                      ></div>
                    </div>
                    <div className=" w-full flex justify-end items-center">
                      <h5 className="text-[#4a4f54] text-xs font-medium  leading-tight">
                        {item.progress}% funded
                      </h5>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* TABLE */}
          <div className=" w-full gap-[24px] flex-col justify-start items-center  inline-flex">
            <h5 className="self-stretch text-[#6c757d] text-xs font-medium ">
              Onboarded Users
            </h5>

            <UserManagementTable
              completed={true}
              data={allUsers}
              searcher={handleAllSearch}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
