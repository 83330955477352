import { ApiClient } from "./apiConfig";

const apiPath = ApiClient();

const AdminRequest = {
  approveRequest: async (email, comment) => {
    let payload = {
      email,
      comment,
    };

    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .post(`${process.env.REACT_APP_BASE_URL}/rest/admin/approval`, payload)
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },
  rejectRequest: async (email, comment, failedResponse) => {
    let payload = {
      email,
      comment,
      failedResponse,
    };

    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .post(`${process.env.REACT_APP_BASE_URL}/rest/admin/reject`, payload)
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },
  getPendingUsers: async ({ search, page, size }) => {
    let payload = {
      search,
    };
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .post(
        `${process.env.REACT_APP_BASE_URL}/rest/admin/pending-users`,
        payload
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  getAllUsers: async ({ search, page, size }) => {
    let payload = {
      search,
    };
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .post(
        `${process.env.REACT_APP_BASE_URL}/rest/admin/get-all-users`,
        payload
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  getRejectedUsers: async ({ search, page, size }) => {
    let payload = {
      search,
    };
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .post(
        `${process.env.REACT_APP_BASE_URL}/rest/admin/reject-users`,
        payload
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  getApprovedUsers: async ({ search, page, size }) => {
    let payload = {
      search,
    };
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .post(
        `${process.env.REACT_APP_BASE_URL}/rest/admin/approve-users`,
        payload
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  getUserDetails: async (email) => {
    let payload = {
      email,
    };

    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .post(`${process.env.REACT_APP_BASE_URL}/rest/admin/get-user`, payload)
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },
  // gets transactions
  getUserWallet: async (email) => {
    let payload = {
      email,
    };

    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .post(
        `${process.env.REACT_APP_BASE_URL}/rest/wallet/user-naira-transactions-admin`,
        payload
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },
  getUserWalletDollar: async (email) => {
    let payload = {
      email,
    };

    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .post(
        `${process.env.REACT_APP_BASE_URL}/rest/wallet/user-dollar-transactions-admin`,
        payload
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },
  getUserWalletDetails: async (email) => {
    let payload = {
      email,
    };

    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .post(
        `${process.env.REACT_APP_BASE_URL}/rest/wallet/admin-get-wallet-details`,
        payload
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  updateDollarRate: async (amount) => {
    let payload = {
      amount,
    };

    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .post(
        `${process.env.REACT_APP_BASE_URL}/rest/wallet/admin-update-dollar-rate`,
        payload
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  adminDashData: async () => {
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .get(`${process.env.REACT_APP_BASE_URL}/rest/loan/chart-dashboard`)
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },
};

export default AdminRequest;
