import Header from "../LandingPage/Header";
import ContactUs from "../LandingPage/ContactUs";
import Footer from "../LandingPage/Footer";
import heroImg from "../Images/campaignHomepaage.png";
import { Link } from "react-router-dom";
import MakeDonation from "./MakeDonation";
import { useRef } from "react";

function CampaignPage() {
  const contact = useRef(null);
  const faq = useRef(null);
  const home = useRef(null);
  const howIjeWorks = useRef(null);
  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className=" bg-white w-full h-fit">
        <Header
          scrollToSection={scrollToSection}
          home={home}
          contact={contact}
          faq={faq}
          howIjeWorks={howIjeWorks}
        />
        <section className="w-full pt-[240px] py-[96px] h-fit flex justify-center items-center ">
          <div className=" 100:w-[95%] gap-[64px] h-fit flex 100:justify-start lg:items-center lg:justify-center px-[10px] 100:items-start 100:flex-col xl:flex-row xl:w-[1200px] ">
            <div className=" flex flex-col 100:justify-start xl:justify-start xl:items-start lg:justify-center lg:items-center 100:items-start gap-[40px] ">
              <section className="100:w-full  md:w-[628px] gap-[24px] lg:text-center xl:text-left 100:text-left w-full flex flex-col justify-start items-start">
                <h1 className=" font-medium tracking-[-2px] text-[#3A3C3F] 100:text-[40px] 100:leading-[48px] md:leading-[72px] md:text-[64px]">
                  Join the <span className="text-[#0C4CFF]"> Ije </span>
                  Campaign: Transform Lives Today{" "}
                </h1>
                <p className="md:text-[20px] 100:text-[16px] md:leading-[30px] 100:leading-[23px] text-[#6C757D]  font-normal">
                  Be a part of something bigger. Support educational missions
                  and bring hope to those in need. Your contribution makes a
                  difference!
                </p>
              </section>
              <div className="100:flex xl:hidden w-full h-full ">
                <img className=" h-full w-full" src={heroImg} alt="Campaign" />
              </div>
              <section className="  100:w-full phone:w-fit ">
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  className=" phone:w-fit 100:w-full"
                  to="/auth/register"
                >
                  <div className="leading-[20px] border-[#0C4AFF] border-solid border-[1px] rounded-[8px] 100:text-sm bg-[#0C4AFF] md:text-lg text-[#fff] flex justify-center font-medium h-fit px-7 py-4 100:w-full phone:w-fit">
                    Start Your Campaign
                  </div>
                </Link>
              </section>
            </div>
            <div className="100:hidden xl:flex w-full h-full ">
              <img className=" h-full w-full" src={heroImg} alt="Campaign" />
            </div>
          </div>
        </section>
      </div>
      <MakeDonation />

      <section ref={contact}>
        <ContactUs />
      </section>
      <Footer />
    </>
  );
}

export default CampaignPage;
