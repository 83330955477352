import { ApiClient } from "./apiConfig";

const apiPath = ApiClient();

const WalletRequest = {
  // get transactions
  getWallet: async () => {
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .get(`${process.env.REACT_APP_BASE_URL}/rest/wallet/get-user`)
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  getWalletDetails: async () => {
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .get(
        `${process.env.REACT_APP_BASE_URL}/rest/wallet/get-user-wallet-details`
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },
  // get current dollar rate
  getCurrentDollarRate: async () => {
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .get(`${process.env.REACT_APP_BASE_URL}/rest/wallet/current-dollar-rate`)
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },
  getDollarLogs: async () => {
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .get(`${process.env.REACT_APP_BASE_URL}/rest/wallet/get-dollar-logs`)
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  getUserTransactions: async () => {
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .get(
        `${process.env.REACT_APP_BASE_URL}/rest/wallet/get-users-transactions`
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  // user
  getNairaTransactions: async () => {
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .get(
        `${process.env.REACT_APP_BASE_URL}/rest/wallet/user-naira-transactions`
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  // user
  getDollarTransactions: async () => {
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .get(
        `${process.env.REACT_APP_BASE_URL}/rest/wallet/user-dollar-transactions`
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  // admin

  getDollarBalanceAdmin: async () => {
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .get(`${process.env.REACT_APP_BASE_URL}/rest/wallet/dollar-balance-admin`)
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  // admin

  getNairaBalanceAdmin: async () => {
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .get(`${process.env.REACT_APP_BASE_URL}/rest/wallet/naira-balance-admin`)
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },
};

export default WalletRequest;
