import { Link } from "react-router-dom";
import ContactUs from "../LandingPage/ContactUs";
import Footer from "../LandingPage/Footer";
import Header from "../LandingPage/Header";
import { useRef } from "react";

function Page404() {
  const contact = useRef(null);
  const faq = useRef(null);
  const home = useRef(null);
  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Header scrollToSection={scrollToSection} home={home} contact={contact} faq={faq} />
      <section className="w-full mt-[150px] py-[96px] h-fit flex justify-center items-center ">
        <div className=" flex flex-col justify-center items-center gap-[64px] 100:w-[90%] lg:w-[1000px]">
          <section className=" gap-[24px] text-center w-full flex flex-col justify-center items-center">
            <section className=" gap-[12px] text-center w-full flex flex-col justify-center items-center">
              <h3 className="text-center text-blue-600 text-[16px] font-medium  leading-[24px]">404 error</h3>

              <h1 className=" 100:w-full md:w-[668px] font-medium tracking-[-2px] text-[#3A3C3F] 100:text-[40px] 100:leading-[48px] md:leading-[82px] md:text-[64px]"> We lost this page</h1>
            </section>
            <p className="md:text-[20px] text-center 100:text-[16px] md:leading-[30px] 100:leading-[23px] text-[#6C757D] 100:w-[80%] xl:w-[639px] font-normal">
              We searched high and low, but couldn’t find what you’re looking for.Let’s find a better place for you to go.
            </p>
            <section className=" 100:flex-col 100:w-full phone:w-fit phone:flex-row  flex justify-center items-center gap-[12px]">
              <Link className=" phone:w-fit 100:w-full" to="/">
                <div className="leading-[20px] border-[#0C4AFF] border-solid border-[1px] rounded-[8px] 100:text-sm bg-[#0C4AFF] md:text-lg text-[#fff] flex justify-center font-medium h-fit px-7 py-4 100:w-full phone:w-fit"> Take me home</div>
              </Link>
            </section>
          </section>
        </div>
      </section>
      <section ref={contact}>
        <ContactUs />
      </section>
      <Footer />
    </>
  );
}

export default Page404;
