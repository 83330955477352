import Footer from "../../ApproverAdmin/Footer";
import { ReactComponent as Completed } from "../../Images/complete.svg";
import { ReactComponent as Search } from "../../Images/search.svg";

function RepaymentSchedule() {
  return (
    <>
      {/* TABLE */}
      <div className=" w-full gap-[8px] flex-col justify-start items-start flex">
        <h5 className="self-stretch text-[#3a3c3f] text-lg font-medium  ">Repayment Schedule</h5>
        <div className="h-fit w-full whitespace-nowrap  100:overflow-x-scroll bg-white rounded-xl shadow flex-col justify-center items-start gap-[24px] inline-flex">
          <div className=" flex justify-between items-center w-full p-[20px]">
            <div className="self-stretch 100:flex md:hidden gap-[12px]  w-full px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#aab0b6] justify-start items-center  inline-flex text-[#6c757d] text-base font-normal leading-normal">
              <Search /> <input className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full" placeholder="Search" />
            </div>

            <div className="self-stretch gap-[12px] 100:hidden md:flex w-[400px] px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#aab0b6] justify-start items-center  inline-flex text-[#6c757d] text-base font-normal leading-normal">
              <Search /> <input className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full" placeholder="Search" />
            </div>
          </div>
          <div className=" self-stretch w-full border-t border-t-[#CDD1D4] flex justify-between items-center ">
            {/* ROW 1*/}
            <div className=" w-full flex flex-col justify-start items-start">
              <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium  leading-[18px]">ID</div>

            </div>
            {/* ROW 2*/}
            <div className=" w-full flex flex-col justify-start items-start">
              <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium  leading-[18px]">Due Date</div>

            </div>
            {/* ROW 3*/}
            <div className=" w-full flex flex-col justify-start items-start">
              <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium  leading-[18px]">Payment Amount</div>

            </div>

            {/* ROW 4*/}
            <div className=" w-full flex flex-col justify-start items-start">
              <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium  leading-[18px]">Status</div>
              {/* <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">
                {" "}
                <div className="pl-1.5 pr-2 py-0.5 bg-[#f1fcf2] rounded-2xl justify-center items-center gap-1 flex">
                  <Completed />
                  <div className="text-center text-[#1f7634] text-xs font-medium leading-[18px]">Approved</div>
                </div>
              </div> */}
              
            </div>
            {/* ROW 5*/}
            <div className=" w-full flex flex-col justify-start items-start">
              <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6c757d] text-xs font-medium  leading-[18px]">Payment Method</div>
              {/* <div className="h-[72px] w-full px-6 py-4 bg-[#f5f6f6]  justify-start items-center gap-3 inline-flex text-[#3a3c3f] text-sm font-normal  leading-[18px]">Bank Transfer</div> */}

            </div>
          </div>
        </div>
      </div>
      <div className=" w-full mt-[40px]">
        <Footer />
      </div>
    </>
  );
}

export default RepaymentSchedule;
