import edit from "../../Images/editReg.svg";

export const EditButton = ({ func }) => {
  return (
    <button
      onClick={func}
      className=" w-fit flex justify-center items-center gap-2"
    >
      <img src={edit} alt="edit" />{" "}
      <span className="text-[#0c4aff] 100:hidden sm:flex text-sm font-medium font-inter leading-tight">
        Edit
      </span>
    </button>
  );
};
