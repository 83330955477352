import SideBar from "./SideBar";
import { ReactComponent as Icon } from "../Images/flag.svg";
// import { ReactComponent as Search } from "../Images/search.svg";
import { ReactComponent as Completed } from "../Images/complete.svg";
// import { ReactComponent as Eyes } from "../Images/eyes.svg";
import cancel from "../Images/Cancel.svg";
import Footer from "./Footer";
import { useEffect, useState } from "react";
import al from "../Images/alert-circle.svg";
import CampaignRequest from "../../utils/services/campaignRequests";
import CampaignManagementTable from "./component/campaignManagementTable";
import { formatDate } from "../../utils/helpers/helper";
import { toast } from "react-toastify";
import left from "../Images/arrow-left-01.svg";
import right from "../Images/arrow-right-01.svg";
export default function CampaignManagement() {
  const [modal, setModal] = useState(false);
  const [closeCampaign, setCloseCampaign] = useState(false);
  const [isData, setData] = useState([]);
  const [isSelected, setSelected] = useState({});
  const [isCompleted, setCompleted] = useState(0);
  const [isProgress, setProgress] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const getAllData = async () => {
    await CampaignRequest.getAllCampaigns().then((res) => {
      // console.log("get all data", res);
      setData(res.data.result);
    });
  };

  const getCompleted = async () => {
    await CampaignRequest.getAllCampaignCompleted().then((res) => {
      // setData(res.data.result);
      setCompleted(res?.data?.numbers || 0);
    });
  };

  const getInprogress = async () => {
    await CampaignRequest.getAllCampaignInprogress().then((res) => {
      setProgress(res?.data?.numbers || 0);
    });
  };

  useEffect(() => {
    getAllData();
    getInprogress();
    getCompleted();
  }, []);
  const cards = [
    {
      id: 1,
      name: "Total Campaigns",
      icon: <Icon />,
      amount: isData?.length,
      back: "#D5EFFF",
      border: "#E8F7FF",
      text: "#0C4AFF",
    },
    {
      id: 2,
      name: "Active Campaigns",
      icon: <Icon />,
      amount: isProgress,
      back: "#DFF9E4",
      border: "#F1FCF2",
      text: "#1F7634",
    },
    {
      id: 3,
      name: "Completed Campaigns",
      icon: <Icon />,
      amount: isCompleted,
      back: "#CAFDF3",
      border: "#EFFEFA",
      text: "#0A7B75",
    },
  ];

  const openFunc = (item) => {
    setModal(true);
    setSelected(item);
  };

  const closeCampaignFunc = async () => {
    setLoading(true);

    await CampaignRequest.closeCampaignAdmin(isSelected?.email).then((res) => {
      setLoading(false);

      if (res.data.statusCode === 200) {
        toast.success("successful", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setCloseCampaign(false);
        setModal(false);
      } else {
        toast.error(res?.data?.message || "an error occures", {
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
        });
      }
    });
  };

  const [activeSlide, setActiveSlide] = useState(0);
  const slides = [
    { src: isSelected?.campaignImage, alt: "Campaign Image" },
    { src: isSelected?.campaignVideo, alt: "Campaign Video" },
  ];

  const isVideo = (src) => {
    const videoExtensions = ["mp4", "webm", "ogg"];
    const extension = src?.split(".").pop();
    return videoExtensions.includes(extension);
  };
  const handleNextSlide = () => {
    setActiveSlide((prev) => (prev + 1) % slides.length);
  };

  const handlePrevSlide = () => {
    setActiveSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const handleDotClick = (index) => {
    setActiveSlide(index);
  };
  return (
    <>
      <SideBar />
      <div className=" flex 100:justify-center items-center lg:justify-start flex-col 100:pt-[120px] md:pt-[120px] lg:pt-[40px] md:py-[32px]  lg:pr-8 w-full lg:pl-[362px]">
        <div className="flex gap-[32px] items-center 100:w-[85%] lg:w-full  justify-center flex-col">
          <h3 className="self-stretch  text-[#3a3c3f] text-2xl font-medium leading-[38px]">
            Campaign Management
          </h3>
          {/* CONTRIBUTION CARDS */}
          <div className=" w-full flex-col justify-start items-center gap-[8px] inline-flex">
            <h5 className="self-stretch text-[#6c757d] text-xs font-medium ">
              Campaign Overview
            </h5>
            <div className=" w-full flex-1 flex 100:flex-wrap gap-[16px] xl:flex-nowrap justify-start items-start">
              {cards.map((item) => (
                <div
                  key={item.id}
                  className="h-fit  flex-grow lg:w-[348px] p-6 bg-white rounded-lg shadow border border-[#cdd1d4] justify-between items-start  inline-flex"
                >
                  <div className=" w-fit flex-col justify-start items-start gap-2.5 inline-flex">
                    <h4 className="text-[#4a4f54] text-sm font-normal leading-tight">
                      {item.name}
                    </h4>
                    <h3 className="text-[#3a3c3f] text-2xl font-semibold ">
                      {item.amount}
                    </h3>
                  </div>
                  <div
                    style={{
                      color: item.text,
                      backgroundColor: item.back,
                      borderColor: item.border,
                    }}
                    className={`w-12 h-12  flex justify-center items-center rounded-[28px]  border-8 `}
                  >
                    {item.icon}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <CampaignManagementTable data={isData} action={openFunc} />
          {/* TABLE */}

          <Footer />
        </div>
      </div>
      {modal && (
        <>
          <div className="w-full h-[100%] top-0 left-0 fixed z-40  bg-slate-700 bg-opacity-60 backdrop-blur-lg flex-col justify-start items-center flex">
            <section className=" w-full flex justify-end pt-[25px] pr-[40px] items-end">
              <div
                onClick={() => setModal(false)}
                className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  "
              >
                <img className=" " src={cancel} alt="Cancel" />
              </div>
            </section>
            <section className=" 100:w-[80%] h-full iphone:w-full  flex justify-center  items-center">
              {closeCampaign ? (
                <div className=" 100:w-full iphone:w-96 h-fit p-[24px] bg-white rounded-xl shadow flex-col justify-start items-center flex">
                  <div className="w-full flex-col justify-start items-center gap-[32px] flex">
                    <div className="w-full flex-col justify-start items-center gap-5 flex">
                      <div className="w-12 h-12   justify-center flex  items-center  bg-[#fffbc5] rounded-[28px] border-8 border-[#ffffea]">
                        <img className=" " src={al} alt="Alert" />
                      </div>
                      <div className="w-full flex-col justify-start items-center gap-2 flex">
                        <h3 className="self-stretch text-center text-gray-900 text-lg font-medium  leading-7">
                          Confirmation
                        </h3>
                        <p className="self-stretch text-center text-gray-500 text-sm font-normal  leading-tight">
                          Are you sure you want to close this campaign?
                        </p>
                      </div>
                    </div>
                    <div className=" w-full flex justify-start items-center flex-col gap-2.5">
                      <button
                        onClick={closeCampaignFunc}
                        className="leading-[20px]  bg-[#b7192c] rounded-lg shadow border border-[#b7192c] text-base text-[#fff] flex justify-center font-medium h-fit px-[20px] py-[12px] w-full "
                      >
                        {isLoading ? "Loading" : "Proceed"}
                      </button>
                      <button
                        onClick={() => {
                          setCloseCampaign(false);
                          // closeCampaignFunc();
                        }}
                        className="leading-[20px]   rounded-lg shadow border border-[#AAB0B6] text-[#4a4f54] text-base flex justify-center font-medium h-fit px-[20px] py-[12px] w-full "
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className=" 100:w-full iphone:w-96  h-[500px] overflow-y-scroll p-[24px]  bg-white rounded-xl shadow ">
                  <div className="gap-[32px] h-fit w-full flex-col justify-center items-center flex">
                    <h4 className=" text-[18px] font-medium text-[#3A3C3F]">
                      Campaign Details
                    </h4>
                    <div className=" w-full flex-col justify-start items-center gap-5 inline-flex">
                      {/* <img
                        className=" w-full rounded-xl"
                        src={isSelected?.campaignImage}
                        alt=""
                      /> */}

                      <div className="relative w-full">
                        {isVideo(slides[activeSlide].src) ? (
                          <video
                            className="w-full h-[220px] object-cover rounded-xl"
                            controls
                          >
                            <source
                              src={slides[activeSlide].src}
                              type={`video/${slides[activeSlide].src
                                .split(".")
                                .pop()}`}
                            />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img
                            className="w-full rounded-xl"
                            src={slides[activeSlide].src}
                            alt={slides[activeSlide].alt}
                          />
                        )}

                        {/* Navigation buttons */}
                        <button
                          onClick={handlePrevSlide}
                          className="absolute left-0 top-1/2 transform -translate-y-1/2"
                        >
                          <div className="w-12 h-12 justify-center flex  items-center bg-gray-400 rounded-3xl  ">
                            <img className=" " src={left} alt="Arrow" />
                          </div>
                        </button>
                        <button
                          onClick={handleNextSlide}
                          className="absolute right-0 top-1/2 transform -translate-y-1/2 p-2 "
                        >
                          <div className="w-12 h-12 justify-center flex  items-center bg-gray-400 rounded-3xl  ">
                            <img className=" " src={right} alt="Arrow" />
                          </div>
                        </button>
                      </div>
                      <div className="w-full flex-col justify-start items-start gap-6 flex">
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                          <h4 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">
                            Creator
                          </h4>
                          <h5 className="self-stretch text-[#4a4f54] text-sm font-medium  leading-tight capitalize">
                            {isSelected?.creatorName}
                          </h5>
                        </div>
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                          <h4 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">
                            Campaign Title
                          </h4>
                          <h5 className="self-stretch text-[#4a4f54] text-sm font-medium  leading-tight">
                            {isSelected?.campaignTitle}
                          </h5>
                        </div>{" "}
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                          <h4 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">
                            Start Date
                          </h4>
                          <h5 className="self-stretch text-[#4a4f54] text-sm font-medium  leading-tight">
                            {formatDate(new Date())}
                          </h5>
                        </div>
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                          <h4 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">
                            Status
                          </h4>{" "}
                          <div className="pl-1.5 pr-2 py-0.5 bg-[#f1fcf2] rounded-2xl justify-center items-center gap-1 flex">
                            <Completed />
                            <div className="text-center text-[#1f7634] text-xs font-medium leading-[18px]">
                              {isSelected?.status}
                            </div>
                          </div>
                        </div>
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                          <h4 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">
                            Campaign Url
                          </h4>
                          <h5 className="self-stretch text-[#4a4f54] text-sm font-medium  leading-tight">{`${window.location.origin}/usercampaign/${isSelected.campaignUuid}`}</h5>
                        </div>
                        <div className="w-full flex-col justify-start items-start gap-1 inline-flex">
                          <h4 className="w-full text-[#6c757d] text-sm font-normal  leading-tight">
                            Progress
                          </h4>{" "}
                          <div className=" w-full justify-start items-end gap-2 inline-flex">
                            <div className="  h-[8px] items-start flex justify-start bg-[#E8F7FF] rounded-[8px] w-full">
                              <div
                                style={{
                                  width: `${isSelected?.progress || 0}%`,
                                }}
                                className={`h-full  bg-[#0C4CFF] rounded-[8px] `}
                              ></div>
                            </div>
                            <div className=" w-fit flex justify-end items-center">
                              <h5 className="text-[#4a4f54] text-xs font-medium  leading-tight">
                                {isSelected?.progress || 0}%{" "}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" w-full flex justify-start items-center flex-col gap-2.5">
                      <button
                        onClick={() => setCloseCampaign(true)}
                        className="leading-[20px]  bg-[#b7192c] rounded-lg shadow border border-[#b7192c] text-base text-[#fff] flex justify-center font-medium h-fit px-[20px] py-[12px] w-full "
                      >
                        Close Campaign
                      </button>
                      <button
                        onClick={() => setModal(false)}
                        className="leading-[20px]   rounded-lg shadow border border-[#AAB0B6] text-[#4a4f54] text-base flex justify-center font-medium h-fit px-[20px] py-[12px] w-full "
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </section>
          </div>
        </>
      )}
    </>
  );
}
