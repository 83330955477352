import SideBar from "./SideBar";

function EducationalResources() {
  return (
    <>
      <SideBar />
      <div></div>
    </>
  );
}

export default EducationalResources;
