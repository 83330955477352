import { ApiClient } from "./apiConfig";

const apiPath = ApiClient();

const CampaignRequest = {
  createCampaign: async (
    campaignTitle,
    description,
    campaignVideo,
    campaignImage
  ) => {
    let payload = {
      campaignTitle,
      description,
      campaignVideo,
      campaignImage,
      isPrivate: true,
    };

    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .post(`${process.env.REACT_APP_BASE_URL}/rest/campaign/user`, payload)
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  getCampaignWallet: async (id) => {
    let payload = {
      id,
    };

    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .post(
        `${process.env.REACT_APP_BASE_URL}/rest/wallet/get-campaign-wallet-details`,
        payload
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  closeCampaignAdmin: async (email) => {
    let payload = {
      email,
    };

    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .post(
        `${process.env.REACT_APP_BASE_URL}/rest/campaign/admin-close-campaign`,
        payload
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  closeCampaignUser: async (email) => {
    let payload = {
      email,
    };

    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .post(
        `${process.env.REACT_APP_BASE_URL}/rest/campaign/user-close-campaign`,
        payload
      )
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  updateCampaign: async (
    // email,
    campaignTitle,
    description,
    // fundingGoal,
    campaignVideo,
    campaignImage
  ) => {
    let payload = {
      campaignTitle,
      description,
      campaignVideo,
      campaignImage,
      isPrivate: true,
    };

    let res = {
      status: 0,
      data: {},
      message: "",
    };

    // ;

    return await apiPath
      .put(`${process.env.REACT_APP_BASE_URL}/rest/campaign/update`, payload)
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },
  getCampaigns: async () => {
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .get(`${process.env.REACT_APP_BASE_URL}/rest/campaign`)
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },
  getAllCampaigns: async () => {
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .get(`${process.env.REACT_APP_BASE_URL}/rest/campaign/get-all`)
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  getAllCampaignInprogress: async () => {
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .get(`${process.env.REACT_APP_BASE_URL}/rest/campaign/all-inprogress`)
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },
  getAllCampaignCompleted: async () => {
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .get(`${process.env.REACT_APP_BASE_URL}/rest/campaign/all-completed`)
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  getCampaignContributors: async () => {
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .get(`${process.env.REACT_APP_BASE_URL}/rest/campaign/get-contributors`)
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },

  getFiveCampaigns: async () => {
    let res = {
      status: 0,
      data: {},
      message: "",
    };

    return await apiPath
      .get(`${process.env.REACT_APP_BASE_URL}/rest/campaign/get-five-campaigns`)
      .then((response) => {
        res.status = response.data.status;
        res.data = response.data;
        res.message = response.data.message;
        return res;
      })
      .catch((err) => {
        if (err.response) {
          res.status = err.response.status;
          res.data = err.response.data;
        } else {
          res.status = 500;
          res.data = {};
        }
        res.message = err.message;
        return res;
      });
  },
};

export default CampaignRequest;
