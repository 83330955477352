import { Link } from "react-router-dom";
import helpIcon from "../Images/helpIcon.svg";
import { useEffect, useState } from "react";
import MiscRequest from "../../utils/services/miscRequests";
import { CurrencyFormatter } from "../../utils/helpers/helper";

function MakeDonation() {
  const [isCampaigns, setCampaigns] = useState([]);

  const getAll = async () => {
    await MiscRequest.getAllCampaigns().then((res) => {
      // console.log("campaigns", res.data.result);
      setCampaigns(res.data.result);
    });
  };

  useEffect(() => {
    getAll();
  }, []);

  return (
    <>
      <div className=" w-full h-fit bg-[#E8F7FF]  py-[62px] flex justify-center items-center ">
        <section className=" 100:w-[90%] gap-[32px] h-fit flex justify-start items-start flex-col  xl:w-[1270px] ">
          <h1 className=" w-full justify-start font-medium tracking-[-2px] text-[#3A3C3F] leading-[44px] text-[36px]">Make a Donation Today!</h1>
          <div className=" w-full flex gap-[32px] flex-wrap items-center justify-start">
            {Array.isArray(isCampaigns) &&
              isCampaigns.map((item, id) => (
                <div
                  key={id}
                  className=" bg-white flex-grow iphone:w-[100%] iphone:h-fit flex-1 md:w-[400px] md:h-full  flex justify-start flex-col items-center 100:w-full lg:h-[526px] 100:h-fit gap-[24px] px-[24px] pt-[24px] pb-[32px] rounded-[12px]  "
                >
                  <div className=" flex flex-col h-[324px] bg-gradient-to-b from-black to-black rounded-lg items-start justify-start w-full">
                    <div className="pl-1.5 whitespace-break-spaces absolute mt-[15px] ml-[15px]  pr-2 py-0.5 bg-blue-50 rounded-2xl justify-center items-center gap-1 flex">
                      <span>
                        <img src={helpIcon} alt="Donate" />
                      </span>{" "}
                      <h5 className="text-center text-blue-600 text-xs font-medium capitalize ">Help {item.creatorName}</h5>
                    </div>
                    <div className="rounded-lg  w-full">
                      {" "}
                      <img className="rounded-lg h-[324px] object-fill bg-center w-full" alt={item.title} src={item.campaignImage} />
                    </div>
                    <div className="w-full 100:mt-[-80px] md:mt-[-57px] sm:mt-[-50px] h-fit px-6 py-4 bg-white bg-opacity-30 rounded-bl-lg rounded-br-lg backdrop-blur-xl  items-start inline-flex">
                      <Link onClick={() => window.scrollTo(0, 0)} className="w-full" to="usercampaign/:id">
                        <h3 className="self-stretch text-white text-xl font-bold ">{item.campaignTitle}</h3>
                      </Link>
                    </div>
                  </div>
                  {/* progressBar */}
                  <div className=" w-full flex-grow flex justify-start flex-col items-start gap-[10px]">
                    <div className=" h-[8px] items-start flex justify-start bg-[#E8F7FF] rounded-[8px] w-full">
                      <div style={{ width: `${item.progress}%` }} className={`h-full  bg-[#0C4CFF] rounded-[8px] `}></div>
                    </div>
                    <div className=" w-full flex justify-between items-center">
                      <h4 className="text-neutral-600 text-xs font-semibold">
                        {" "}
                        {CurrencyFormatter(0, "$")} of {CurrencyFormatter(item.fundingGoal, "$")} Raised
                      </h4>
                      <span className=" text-[#4A4F54] font-medium leading-[24px] text-[12px] ">{item.progress}%</span>
                    </div>{" "}
                  </div>
                  <section className="  100:w-full phone:w-fit ">
                    <Link onClick={() => window.scrollTo(0, 0)} className=" phone:w-fit 100:w-full" to={`/usercampaign/${item?.campaignUuid}`}>
                      <div className=" border-teal-600 bg-teal-600 border-solid border-[1px] rounded-[8px] 100:text-sm  md:text-lg text-white text-sm font-medium leading-tight flex justify-center  h-[40px] items-center 100:w-full phone:w-[305px]">
                        Donate now
                      </div>
                    </Link>
                  </section>
                </div>
              ))}
          </div>
        </section>
      </div>
    </>
  );
}

export default MakeDonation;
