import { Navigate, createBrowserRouter } from "react-router-dom";
import Login from "../Components/Authentication/Login";
import Register from "../Components/Authentication/Register";
import ForgotPassword from "../Components/Authentication/ForgotPassword";
import Otp from "../Components/Authentication/Otp";
import CreatePassword from "../Components/Authentication/CreatePassword";
import Homepage from "../Components/LandingPage/Homepage";
import AboutUs from "../Components/LandingPage/AboutUs";
import TermsOfUse from "../Components/Legal/TermsOfUse";
import Privacy from "../Components/Legal/Privacy";
import SignUp from "../Components/Authentication/SignUp";
import OtpForgotpassword from "../Components/Authentication/OtpForgotpassword";
import Page404 from "../Components/404Page/Page404";
import CampaignPage from "../Components/Campaign/CampaignPage";
import IndividualCampaign from "../Components/Campaign/IndividualCampaign";
import Dashboard from "../Components/UserDashboard/Dashboard";
import CampaignUser from "../Components/UserDashboard/CampaignUser";
import Contribution from "../Components/UserDashboard/Contribution";
import EducationalResources from "../Components/UserDashboard/EducationalResources";
import GiveBack from "../Components/UserDashboard/GiveBack";
import Loan from "../Components/UserDashboard/Loan";
import Reports from "../Components/UserDashboard/Reports";
import Support from "../Components/UserDashboard/Support";
import Wallet from "../Components/UserDashboard/Wallet";
import ProfileUser from "../Components/UserDashboard/Profile";
import Settings from "../Components/UserDashboard/Settings";
import CampaignForm from "../Components/UserDashboard/CampaignForm";
import DashboardA from "../Components/ApproverAdmin/Dashboard";
import CampaignManagement from "../Components/ApproverAdmin/CampaignManagement";
import LoanManagement from "../Components/ApproverAdmin/LoanManagement";
import UserManagement from "../Components/ApproverAdmin/UserManagement";
import ReportAndAnalytics from "../Components/ApproverAdmin/ReportAndAnalytics";
import UserSupport from "../Components/ApproverAdmin/UserSupport";
import SettingApprover from "../Components/ApproverAdmin/Settings";
import ProfileApprover from "../Components/ApproverAdmin/Profile";
import AddProcessor from "../Components/ApproverAdmin/Form/AddProcessor";
import UserDetails from "../Components/ApproverAdmin/UserDetails";
import WalletApprover from "../Components/ApproverAdmin/Wallet";
import Donate from "../Components/PaymentPage/Donate";
import { AdminProtectedRoute, UserProtectedRoute } from "./protectedRoutes";
import Registration from "../Components/UserDashboard/Registration";

export const router = createBrowserRouter([
  // AUTH ROUTE
  {
    path: "auth",
    children: [
      { path: "login", element: <Login /> },
      { path: "register", element: <Register /> },
      { path: "forgot-password", element: <ForgotPassword /> },
      { path: "otp:id/:token", element: <Otp /> },
      { path: "otpforgotpassword", element: <OtpForgotpassword /> },
      { path: "create-password", element: <CreatePassword /> },
      { path: "signup", element: <SignUp /> },
    ],
  },
  //   HOME ROUTE
  {
    path: "",
    children: [
      { path: "/", element: <Homepage /> },
      { path: "aboutije", element: <AboutUs /> },
    ],
  },

  // LEGAL ROUTE
  {
    path: "legal",
    children: [
      { path: "termsofuse", element: <TermsOfUse /> },
      { path: "privacypolicy", element: <Privacy /> },
    ],
  },

  // CAMPAIGN ROUTE
  {
    path: "",
    children: [
      { path: "campaign", element: <CampaignPage /> },
      { path: "usercampaign/:id", element: <IndividualCampaign /> },
    ],
  },
  // USER DASHBOARD
  {
    path: "user",
    children: [
      {
        path: "dashboard",
        element: (
          <UserProtectedRoute>
            <Dashboard />
          </UserProtectedRoute>
        ),
      },
      {
        path: "registeration",
        element: (
          <UserProtectedRoute>
            <Registration />
          </UserProtectedRoute>
        ),
      },
      {
        path: "campaign",
        element: (
          <UserProtectedRoute>
            <CampaignUser />
          </UserProtectedRoute>
        ),
      },
      {
        path: "contribution",
        element: (
          <UserProtectedRoute>
            <Contribution />
          </UserProtectedRoute>
        ),
      },
      {
        path: "educational-resources",
        element: (
          <UserProtectedRoute>
            <EducationalResources />
          </UserProtectedRoute>
        ),
      },
      {
        path: "give-back",
        element: (
          <UserProtectedRoute>
            <GiveBack />
          </UserProtectedRoute>
        ),
      },
      {
        path: "loan",
        element: (
          <UserProtectedRoute>
            <Loan />
          </UserProtectedRoute>
        ),
      },
      {
        path: "profile",
        element: (
          <UserProtectedRoute>
            <ProfileUser />
          </UserProtectedRoute>
        ),
      },
      {
        path: "settings",
        element: (
          <UserProtectedRoute>
            <Settings />
          </UserProtectedRoute>
        ),
      },
      {
        path: "reports",
        element: (
          <UserProtectedRoute>
            {" "}
            <Reports />{" "}
          </UserProtectedRoute>
        ),
      },
      {
        path: "wallet",
        element: (
          <UserProtectedRoute>
            <Wallet />
          </UserProtectedRoute>
        ),
      },
      {
        path: "support",
        element: (
          <UserProtectedRoute>
            <Support />
          </UserProtectedRoute>
        ),
      },
      {
        path: "campaign-form",
        element: (
          <UserProtectedRoute>
            <CampaignForm />
          </UserProtectedRoute>
        ),
      },
    ],
  },

  // APPROVER DASHBOARD
  {
    path: "approver",
    children: [
      {
        path: "dashboard",
        element: (
          <AdminProtectedRoute>
            <DashboardA />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "campaign",
        element: (
          <AdminProtectedRoute>
            <CampaignManagement />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "loan-management",
        element: (
          <AdminProtectedRoute>
            <LoanManagement />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "user-management",
        element: (
          <AdminProtectedRoute>
            <UserManagement />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "user-management/user-details/:email",
        element: (
          <AdminProtectedRoute>
            <UserDetails />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "user-support",
        element: (
          <AdminProtectedRoute>
            <UserSupport />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "setting",
        element: (
          <AdminProtectedRoute>
            <SettingApprover />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "profile",
        element: (
          <AdminProtectedRoute>
            <ProfileApprover />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "report",
        element: (
          <AdminProtectedRoute>
            <ReportAndAnalytics />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "add-processor",
        element: (
          <AdminProtectedRoute>
            <AddProcessor />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "wallet",
        element: (
          <AdminProtectedRoute>
            <WalletApprover />
          </AdminProtectedRoute>
        ),
      },
    ],
  },
  // USER PAYMENT
  {
    path: "payment",
    children: [{ path: "donate/:id", element: <Donate /> }],
  },

  // MAIN ROUTE
  {
    path: "",
    children: [
      { path: "404", element: <Page404 /> },
      { path: "*", element: <Navigate to="/404" replace /> },
    ],
  },
  { path: "/", element: <Navigate to="/" replace /> },
  { path: "*", element: <Navigate to="/404" replace /> },
]);
