import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AdminRequest from "../../../utils/services/adminRequests";
import { formatDate, CurrencyFormatter } from "../../../utils/helpers/helper";
import { approveUserValidator } from "../../../utils/services/validation";
import TextArea from "../../shared/input/TextArea";
import { toast } from "react-toastify";
import Select from "react-select";
import { useFormik } from "formik";
import { rejectionOptions } from "../../../utils/helpers/constants";
import RejectionModal from "./CloseModal";

function FinancialSummary({ data }) {
  const [accept, setAccept] = useState(false);
  const [reject, setReject] = useState(false);
  const [isLoading, setLoading] = useState("");
  // const [failedArray, setFailedArray] = useState([]);
  const { email } = useParams();
  const [modal, setModal] = useState(false);

  const formik = useFormik({
    validationSchema: approveUserValidator,
    initialValues: {
      comment: "",
    },
  });
  const declineInit = () => {
    setModal(true);
  };

  const declineRequest = async (data) => {
    // console.log(data);
    setLoading(true);
    await AdminRequest.rejectRequest(email, formik.values.comment, data).then(
      (res) => {
        setLoading(false);

        if (res.data.statusCode === 200) {
          toast.success("successful", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setModal(false);
        } else {
          setLoading(false);
          toast.error(res?.data?.message || res?.message, {
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
        }
      }
    );
  };
  const approveRequest = async () => {
    setLoading(true);
    await AdminRequest.approveRequest(email, formik.values.comment).then(
      (res) => {
        setLoading(false);

        if (res.data.statusCode === 200) {
          toast.success("successful", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setAccept(true);
        } else {
          setLoading(false);
          toast.error(res?.data?.message || res?.message, {
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
        }
      }
    );
  };
  return (
    <>
      <div className=" items-start justify-start gap-8 w-full flex flex-col">
        <div className=" w-full p-6 bg-white flex-col rounded-lg shadow border border-[#cdd1d4] justify-start items-start gap-[16px] inline-flex">
          <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
            <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
              <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                  Currency
                </h4>
                <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                  {data?.educationInformation?.currency}
                </h3>
              </div>
            </div>
          </div>
          <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
            <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
              <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                  Tuition Fee
                </h4>
                <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                  {" "}
                  {CurrencyFormatter(
                    parseFloat(data?.educationInformation?.tuitionFee || 0),
                    "$"
                  )}
                </h3>
              </div>
            </div>
          </div>
          <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
            <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
              <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                  Living Expenses
                </h4>
                <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                  {CurrencyFormatter(
                    parseFloat(data?.educationInformation?.livingExpenses || 0),
                    "$"
                  )}
                </h3>
              </div>
            </div>
          </div>
          <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
            <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
              <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                  Total Funding Requirement{" "}
                </h4>
                <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                  {CurrencyFormatter(
                    parseFloat(
                      data?.educationInformation?.totalFundingRequirement || 0
                    ),
                    "$"
                  )}
                </h3>
              </div>
            </div>
          </div>
          <div className="w-full flex-col justify-start pb-[20px] border-b border-b-[#e5e7e8] items-start gap-5 flex">
            <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
              <div className="grow shrink basis-0 flex-col justify-end items-start gap-2 inline-flex">
                <h4 className="self-stretch text-[#6c757d] text-sm font-normal font-inter leading-tight">
                  Amount available
                </h4>
                <h3 className="self-stretch text-[#3a3c3f] text-base font-medium font-inter leading-tight">
                  {" "}
                  {CurrencyFormatter(
                    parseFloat(
                      data?.educationInformation?.amountAvailable || 0
                    ),
                    "$"
                  )}
                </h3>
              </div>
            </div>
          </div>
          {/* {data?.acceptanceStatus !== "ACCEPT" && ( */}
            <>
              <TextArea
                label={"Leave a comment"}
                type="text"
                value={formik.values.comment}
                hint={"Not more than 200 characters"}
                onBlur={formik.handleBlur("comment")}
                onChange={formik.handleChange("comment")}
                error={formik.touched.comment && formik.errors.comment}
              />
            </>
          {/* )} */}
        </div>
        {/* {data?.acceptanceStatus !== "ACCEPT" && ( */}
          <div className=" w-full flex  100:gap-[16px] 100:flex-col-reverse phone:flex-row  justify-end items-end">
            <div
              onClick={declineInit}
              to={"/approver/dashboard"}
              className=" 100:w-full phone:w-fit"
            >
              <button className="leading-[20px] bg-white rounded-lg shadow border border-[#aab0b6]  text-[#4a4f54] text-base flex items-center justify-center font-medium h-[48px] px-7  100:w-full phone:w-[128px] ">
                {isLoading ? "Loading..." : "Reject"}
              </button>
            </div>
            <button
              onClick={approveRequest}
              className="leading-[20px] border-[#0c4aff] border-solid border-[1px] rounded-[8px]  bg-[#0c4aff]   text-base text-[#fff] flex items-center justify-center font-medium h-[48px] px-[16px]  100:w-full phone:w-[128px] "
            >
              {isLoading ? "Loading..." : "Approve"}
            </button>
          </div>
        {/* )} */}

        <RejectionModal
          isLoading={isLoading}
          modal={modal}
          rejectFunc={declineRequest}
          toggleModal={() => {
            setModal(!modal);
          }}
        />
      </div>
    </>
  );
}

export default FinancialSummary;
