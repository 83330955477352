import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import avatar from "../Images/unsplash1.png";
import { ReactComponent as Back } from "../Images/back.svg";
import { ReactComponent as Logo } from "../Images/Logo-with-text.svg";
import logotxt from "../Images/Logo.svg";
// import open from "../Images/open.svg";
// import viewOff from "../Images/viewOff.svg";
import media from "../Images/media.svg";
import unticked from "../Images/unTicked.svg";
import ticked from "../Images/ticked.svg";
import avatarIcon from "../Images/avatarIcon.svg";
import upload from "../Images/upload.svg";
import cancel from "../Images/Cancel.svg";
import confirm from "../Images/Confirm.svg";
import { useFormik } from "formik";
import { createAccountValidator } from "../../utils/services/validation";
import AuthRequest from "../../utils/services/authRequests";
import SuccessAlert from "../shared/Alerts/success_alert";
import TextInput from "../shared/input/TextInput";
import { toast } from "react-toastify";
import FileInput from "../shared/input/FileInput";
import user from "../Images/userID.png";

function Register() {
  const [view, setView] = useState(false);
  const [view1, setView1] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [success, setSuccess] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const [validFormValues, setValidFormValues] = useState({
    length: false,
    numbers: false,
    uppercase: false,
    lowercase: false,
    specialChar: false,
  });

  const formik = useFormik({
    validationSchema: createAccountValidator,
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      createPassword: "",
      confirmPassword: "",
      houseAddress: "",
      photo: "",
      phoneNumber: "",
      // username: "",
      terms: false,
    },
    onSubmit: async (values) => {
      // navigate("/auth/otp:id/:token");
      setLoading(true);
      // console.log("Form submitted with values:", values);
      await AuthRequest.createAccount(values.firstName, values.lastName, values.email, values.createPassword, values.confirmPassword, values.houseAddress, values.photo, values.phoneNumber, values.terms).then((res) => {
        setLoading(false);

        ;
        localStorage.setItem("emailData", values.email);
        // navigate("/auth/otp:id/:token");
        if (res.data.statusCode === 200) {
          // localStorage.setItem('emailData', values.email);
          // setModal(true);
          // setSuccess(true);
          toast.success("successful", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          navigate("/auth/otp:id/:token");
        } else {
          console.log("error");
          // setErrorMessage(res.data.message);
          toast.error(res?.data?.message || res?.message, {
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
          setSuccess(false);
        }
      });
    },
    // enableReinitialize: true,
  });
  const progressBar = "100%";
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // const base64String = reader.result
        const base64String = reader.result.split(",")[1]; // Remove the prefix
        setSelectedImage(base64String);

        formik.setFieldValue("photo", base64String);
        // console.log("Base64 String:", base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  const updateValidationStatus = () => {
    const { createPassword } = formik.values;

    setValidFormValues({
      length: createPassword.length >= 8,
      uppercase: /[A-Z]/.test(createPassword),
      lowercase: /[a-z]/.test(createPassword),
      specialChar: /[@#$%^&*()._-]/.test(createPassword),
      numbers: /[0-9]/.test(createPassword),
    });
  };

  useEffect(() => {
    updateValidationStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);
  return (
    <>
      <div className=" bg-white 100:flex-col lg:flex-row h-full w-full  100:justify-start 100:items-center flex xl:justify-start xl:items-start p-[30px]">
        <div className=" 100:hidden overflow-y-scroll xl:flex bg-[#0A205C] fixed top-[20px] left-[30px] xl:w-[685px]  rounded-[30px] p-[50px]  flex-col gap-[60px] justify-between h-[95%] ">
          <div className=" flex w-full flex-col justify-start items-start gap-[53px]">
            <Link onClick={() => window.scrollTo(0, 0)} to="/">
              <Logo style={{ color: "#fff" }} />
            </Link>
            <div className=" flex w-full flex-col justify-start items-start gap-[32px]">
              <h2 className=" font-semibold text-[60px] leading-[110%] tracking-[-2px] text-[#fff] ">Welcome to Ije: Begin Your Educational Journey</h2>
              <p className=" font-normal text-[18px] leading-[145%]  text-[#E5E7E8] ">
                Join our community of learners and start your path towards achieving your educational dreams. Please provide your basic information to get started. We respect your privacy and ensure your data is secure.
              </p>
            </div>
          </div>
          <div className=" flex-col flex justify-start items-start gap-[16px] bg-[#56A1FF] h-[150px] p-[20px] w-full rounded-[20px]">
            <p className=" font-normal text-[16px]  leading-[145%]  text-[#D5EFFF] ">"With Ije’s flexible repayment terms, I am able to pursue my academic goals of having an international education."</p>
            <div className="flex w-full gap-[12px] justify-start items-center">
              <img src={user} alt="User" />
              <span className="flex flex-col w-full gap-[5px] justify-start items-start">
                <h4 className=" font-normal text-[14px] leading-[110%]  text-[#fff] ">Titilope Ogunsesimi</h4>
                <p className=" font-normal text-[10px] leading-[110%]  text-[#E5E7E8] ">Malta</p>
              </span>
            </div>
          </div>
        </div>
        <div className=" 100:flex xl:hidden bg-[#0A205C] fixed top-[20px] w-[90%] gap-[12px]  rounded-[30px] p-[24px] item-center justify-center h-fit ">
          <div className="w-[50px] flex justify-center items-center ">
            <Link onClick={() => window.scrollTo(0, 0)} to="/">
              <img className=" " src={logotxt} alt="IJE" />
            </Link>
          </div>
          <div className="w-fit flex justify-center items-center ">
            <h2 className=" font-semibold text-[20px] leading-[24px]   text-[#fff] ">Welcome to Ije: Begin Your Educational Journey</h2>
          </div>
        </div>
        <div className="100:w-[95%] 100:pt-[120px] xl:pt-[50px] h-fit xl:w-full gap-[70px] xl:pl-[715px] flex flex-col justify-center items-center py-[50px] pr-[10px]">
          <div className=" w-full flex justify-start items-start ">
            <Link to={"/"}>
              <div className="text-[#0C4AFF] gap-[8px] flex justify-start items-center ">
                <Back />
                <h4 className="  text-[16px] font-medium leading-[24px] ">Back</h4>
              </div>
            </Link>
          </div>
          <div className=" 100:w-full md:w-[480px] gap-[32px] flex-col flex justify-start items-start ">
            <h4 className=" font-semibold text-[30px] text-[#3A3C3F] leading-[38px] ">Sign Up</h4>

            {success !== null && (
              <div className=" w-full flex justify-center items-center">
                <SuccessAlert success={success} info={success === true ? "Account Created!" : errorMessage} />
              </div>
            )}
            <div className="flex-col  w-full flex gap-[32px] justify-start items-start ">
              <form className="flex-col w-full flex gap-[24px] justify-start items-start ">
                <div className=" w-full flex 100:flex-col md:flex-row justify-start items-center 100:gap-[24px] md:gap-[32px]  ">
                  <TextInput label={"First name"} type="text" value={formik.values.firstName} onChange={formik.handleChange("firstName")} placeholder="First name" error={formik.touched.firstName && formik.errors.firstName} />

                  <TextInput label={"Last name"} type="text" value={formik.values.lastName} onChange={formik.handleChange("lastName")} placeholder="Last name" error={formik.touched.lastName && formik.errors.lastName} />
                </div>

                <TextInput label={"Email"} type="text" value={formik.values.email} onChange={formik.handleChange("email")} placeholder="Email your address" error={formik.touched.email && formik.errors.email} />

                <TextInput label={"Phone number"} type="text" value={formik.values.phoneNumber} onChange={formik.handleChange("phoneNumber")} placeholder="Phone number" error={formik.touched.phoneNumber && formik.errors.phoneNumber} />

                <TextInput
                  label={"     House address"}
                  type="text"
                  value={formik.values.houseAddress}
                  onChange={formik.handleChange("houseAddress")}
                  placeholder="     House address"
                  error={formik.touched.houseAddress && formik.errors.houseAddress}
                />

                <label className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium " htmlFor="Profile Picture">
                  Profile Picture
                  <div className=" w-full 100:h-fit sm:h-[126px] 100:flex-col 100:justify-center 100:item-center md:flex-row md:justify-start md:items-start gap-5 flex">
                    <img alt="" className="w-16 h-16 object-cover bg-center  rounded-[200px]" name="avatar" src={selectedImage ? `data:image/png;base64,${selectedImage}` : avatarIcon} />
                    <div className="w-full">
                      <div className="file-input-container w-full ">
                        <input onChange={handleFileChange} type="file" id="file-input" className="hidden" />
                        <label style={{ width: "100%" }} htmlFor="file-input">
                          <div className=" w-full h-[126px] bg-white border-[#CDD1D4] border rounded-[8px] flex-col justify-center items-center flex ">
                            <div className="h-[94px] flex-col justify-center items-center flex">
                              <div className="w-10 h-10 p-2.5 bg-[#E5E7E8] rounded-[28px] border-4 border-[#F5F6F6] justify-center items-center inline-flex">
                                <img src={upload} alt="upload" />
                              </div>
                              <div className="self-stretch justify-center items-start gap-1 flex-col flex">
                                <div className="justify-center w-full flex-col items-center gap-2 flex">
                                  <div className="text-[#0C4AFF] text-sm font-normal  ">
                                    Click to upload <span className="text-[#6C757D]">or drag and drop</span>
                                  </div>
                                  <div className=" text-center text-[#6C757D] text-xs font-normal  ">(max. 2mb)</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  {selectedImage && (
                    <>
                      <div className=" mt-[16px] gap-[16px] w-full h-fit border border-[#2F7AFF] p-[16px] justify-start items-start flex rounded-[8px]">
                        <div className=" w-[28px] h-[28px] rounded-full bg-[#D5EFFF] border-[4px] flex justify-center items-center border-[#E8F7FF] ">
                          <img src={media} alt="" />
                        </div>
                        <div className="h-16 w-full flex-col justify-start items-start gap-1 inline-flex">
                          <div className="self-stretch h-10 flex-col justify-start items-start flex">
                            <div className=" text-[#4a4f54] text-sm font-medium leading-tight">Uploaded File</div>
                          </div>
                          <div className=" w-full flex-grow justify-start items-center gap-3 inline-flex">
                            <div className=" h-[8px] items-start flex justify-start bg-[#E8F7FF] rounded-[8px] w-full">
                              <div style={{ width: `${progressBar}` }} className={`h-full  bg-[#0C4CFF] rounded-[8px] `}></div>
                            </div>
                            <span className=" text-[#4A4F54] font-medium leading-[24px] text-[12px] ">{progressBar}</span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </label>
                <TextInput
                  label={"   Create Password"}
                  type="password"
                  value={formik.values.createPassword}
                  onChange={formik.handleChange("createPassword")}
                  placeholder="   Create Password"
                  error={formik.touched.createPassword && formik.errors.createPassword}
                />

                <div className=" flex flex-col pl-[10px] gap-[10px] justify-start w-full items-start">
                  <div className="flex   gap-[10px] justify-start w-fit items-center">
                    <img src={validFormValues.uppercase ? ticked : unticked} alt="" />
                    <h4 className={`text-[10px] ${validFormValues.uppercase ? "text-[#0C4CFF]" : " text-[#808990]"} tracking-[0.9%] leading-[17px] font-normal `}>One capital letter</h4>
                  </div>
                  <div className="flex   gap-[10px] justify-start w-fit items-center">
                    <img src={validFormValues.lowercase ? ticked : unticked} alt="" />
                    <h4 className={`text-[10px] ${validFormValues.lowercase ? "text-[#0C4CFF]" : " text-[#808990]"} tracking-[0.9%] leading-[17px] font-normal `}>One small letter</h4>
                  </div>
                  <div className="flex   gap-[10px] justify-start w-fit items-center">
                    <img src={validFormValues.length ? ticked : unticked} alt="" />
                    <h4 className={`text-[10px] ${validFormValues.length ? "text-[#0C4CFF]" : " text-[#808990]"} tracking-[0.9%] leading-[17px] font-normal `}>At least 8 characters long</h4>
                  </div>{" "}
                  <div className="flex   gap-[10px] justify-start w-fit items-center">
                    <img src={validFormValues.numbers ? ticked : unticked} alt="" />
                    <h4 className={`text-[10px] ${validFormValues.numbers ? "text-[#0C4CFF]" : " text-[#808990]"} tracking-[0.9%] leading-[17px] font-normal `}>At least 1 digit</h4>
                  </div>{" "}
                  <div className="flex   gap-[10px] justify-start w-fit items-center">
                    <img src={validFormValues.specialChar ? ticked : unticked} alt="" />
                    <h4 className={`text-[10px] ${validFormValues.specialChar ? "text-[#0C4CFF]" : " text-[#808990]"} tracking-[0.9%] leading-[17px] font-normal `}>At least 1 symbol (@, &, $...)</h4>
                  </div>
                </div>

                <TextInput
                  label={" Confirm password"}
                  type="password"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange("confirmPassword")}
                  placeholder=" Confirm password"
                  error={formik.touched.confirmPassword && formik.errors.confirmPassword}
                />

                <label className=" w-full flex-wrap flex justify-start items-center gap-[6px] leading-[20px] text-[#6C757D] text-[14px] font-normal " htmlFor="  Terms">
                  <input required className="w-[20px] outline-[#0C4AFF] bg-white h-[20px]  rounded-[6px] border border-[#CDD1D4]     " type="checkbox" value={formik.values.terms} onChange={formik.handleChange("terms")} />
                  You agree to our friendly{" "}
                  <Link onClick={() => window.scrollTo(0, 0)} className="text-[#4A4F54] underline" to="/legal/termsofuse">
                    Terms
                  </Link>{" "}
                  and{" "}
                  <Link onClick={() => window.scrollTo(0, 0)} className="text-[#4A4F54] underline" to="/legal/privacypolicy">
                    Privacy policy.
                  </Link>
                </label>
              </form>
              <div className=" w-full flex gap-[16px] flex-col justify-center items-center">
                {/* <Link className="w-full" to="/auth/otp:id/:token"> */}
                <button
                  onClick={
                    // () => setModal(true)
                    formik.handleSubmit
                  }
                  className="leading-[20px] border-[#0C4AFF] border-solid border-[1px] rounded-[8px]  bg-[#0C4AFF] text-lg text-[#fff] flex justify-center font-medium h-fit px-7 py-4 w-full "
                >
                  {isLoading ? "Loading..." : "Get Started"}
                </button>
                {/* </Link> */}
              </div>

              {success !== null && (
                <div className=" w-full flex justify-center items-center">
                  <SuccessAlert success={success} info={success === true ? "Account Created!" : errorMessage} />
                </div>
              )}
              <div className="   text-[#6C757D] leading-[20px] text-[14px] gap-[4px] items-center flex justify-start font-medium  w-full ">
                Have an account?{" "}
                <Link onClick={() => window.scrollTo(0, 0)} className="text-[#0C4AFF]" to="/auth/login">
                  Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modal && (
        <>
          <div className="w-full h-[100%] top-0 left-0 fixed z-40  bg-slate-700 bg-opacity-60 backdrop-blur-lg flex-col justify-start items-center flex">
            <section className=" w-full flex justify-end pt-[25px] pr-[40px] items-end">
              <div onClick={() => setModal(false)} className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  ">
                <img className=" " src={cancel} alt="Cancel" />
              </div>
            </section>
            <section className=" 100:w-[80%] h-full iphone:w-full flex justify-center  items-center">
              <div className=" 100:w-full iphone:w-96 100:h-fit iphone:h-64 p-[24px] bg-white rounded-xl shadow flex-col justify-start items-center flex">
                <div className="w-full flex-col justify-start items-center gap-[32px] flex">
                  <div className="w-full flex-col justify-start items-center gap-5 flex">
                    <div className="w-12 h-12   justify-center flex  items-center  bg-emerald-100 rounded-3xl border-8 border-emerald-50  ">
                      <img className=" " src={confirm} alt="Confirm" />
                    </div>
                    <div className="w-full flex-col justify-start items-center gap-2 flex">
                      <h3 className="self-stretch text-center text-gray-900 text-lg font-medium  leading-7">Verification Email Sent</h3>
                      <p className="self-stretch text-center text-gray-500 text-sm font-normal  leading-tight">An email has been sent. Please check your inbox and follow the instructions to verify your address.</p>
                    </div>
                  </div>
                  <div className="leading-[20px] border-[#0C4AFF] border-solid border-[1px] rounded-[8px]  bg-[#0C4AFF] text-lg text-[#fff] flex justify-center font-medium h-fit px-[20px] py-[12px] w-full ">Ok</div>
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
}

export default Register;
