import { Link, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as Back } from "../Images/back.svg";
import logo from "../Images/Logo.svg";
import { useEffect, useState } from "react";
import copy from "../Images/copy.svg";
import CampaignRequest from "../../utils/services/campaignRequests";
import { useAppSelector } from "../../utils/redux/store";
import { CurrencyFormatter, formatDate } from "../../utils/helpers/helper";
import MiscRequest from "../../utils/services/miscRequests";
import Select from "react-select";
import TextInput from "../shared/input/TextInput";
import { useFormik } from "formik";
import { confirmPaymentValidator } from "../../utils/services/validation";
import { toast } from "react-toastify";

function Donate() {
  const [navBar, setNavBar] = useState(false);
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [counter, setCounter] = useState(3600);
  const [isBank, setBank] = useState([]);
  const [walletDetails, setWalletDetails] = useState({});
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { educationInformation } = useAppSelector((state) => state.auth);

  const getCampaignwallet = async () => {
    const sendId = parseFloat(id);
    await MiscRequest.getCampaignWallet(sendId).then((res) => {
      setWalletDetails(res?.data?.result);
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => clearInterval(interval); 
  }, []);

  const formatTime = () => {
    const minutes = String(Math.floor(counter / 60)).padStart(2, "0");
    const seconds = String(counter % 60).padStart(2, "0");
    return `${minutes}:${seconds}`;
  };
  const getBanks = async () => {
    await MiscRequest.getBanks().then((res) => {
      const formattedBanks = res.data.map((bank) => ({
        label: bank.name,
        value: bank.name,
      }));

      setBank(formattedBanks);
    });
  };

  useEffect(() => {
    getCampaignwallet();
    getBanks();
  }, []);
  useEffect(() => {
    function handleScrollBackground() {
      if (window.scrollY >= 20) {
        setNavBar(true);
      } else {
        setNavBar(false);
      }
    }
    window.addEventListener("scroll", handleScrollBackground);

    return () => {
      window.removeEventListener("scroll", handleScrollBackground);
    };
  }, []);

  const handleNavigation = () => {
    if (page > 1) {
      setPage(page - 1);
    } else {
      navigate(-1);
      window.scrollTo(0, 0);
    }
  };

  const formik = useFormik({
    validationSchema: confirmPaymentValidator,
    initialValues: {
      email: "",
      bank: "",
      accountNumber: "",
    },
    onSubmit: async (values) => {
      console.log(values);
      setPage(page + 1);
    },
  });

  const handleSubmit = async () => {
    setLoading(true);
    setTimeout(async () => {
      await MiscRequest.postConfirmPayment(
        formik.values.bank,
        formik.values.email,
        formik.values.accountNumber
      ).then((res) => {
        setLoading(false);

        if (res.data.statusCode !== 200) {
          toast.error(res?.data?.message || "An error occurred", {
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
        } else {
          toast.success("Successful", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
    }, 5000); // 5-second delay
  };
  return (
    <>
      <div className=" bg-white flex-col h-[100%] w-full pb-[100px] gap-[100px]  items-center flex justify-start  ">
        <div className="fixed  top-0 z-20 w-full flex items-center p-[10px] justify-center">
          <div
            className={` rounded-[50px]   px-[30px] my-[10px] ${
              navBar ? "bg-[#D5EFFF] " : ""
            } h-[75px] flex justify-between items-center w-[90%] `}
          >
            <Link onClick={handleNavigation} to={""}>
              <div className="text-[#0C4AFF] gap-[8px] flex justify-start items-center ">
                <Back />
                <h4 className="  text-[16px] font-medium leading-[24px] ">
                  Back
                </h4>
              </div>
            </Link>
            <div>
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                to="/"
              >
                <img className="" src={logo} alt="Ije" />
              </Link>
            </div>
            <div className="w-[66px] 100:hidden phone:block"></div>
          </div>
        </div>
        <div className=" 100:w-[90%] pt-[180px]   md:w-[480px] gap-[64px] flex-col flex justify-center items-center ">
          <div className="flex-col flex gap-[12px] items-center justify-start">
            <h4 className=" text-center text-blue-600 text-base font-semibold  leading-normal">
              Donate
            </h4>
            <h2 className=" text-center text-neutral-700 text-3xl font-semibold  leading-10">
              {page === 2
                ? "Support via Bank Transfer"
                : "Enter your payment details to securely donate."}
            </h2>
          </div>

          {page === 1 && (
            <form className="flex-col w-full flex gap-[32px] justify-start items-start ">
              <label
                className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium "
                htmlFor="Name on Bank"
              >
                Name of Bank
                <Select
                  className=" w-full bg-white border-[#CDD1D4] h-[48px]  rounded-[8px] text-[#3A3C3F] ] font-normal leading-[24px] text-[16px"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      width: "100%",
                      height: "48px",
                      background: "#fff",
                      outline: "none",
                    }),
                  }}
                  placeholder="Select Status"
                  // options={currency}
                  options={isBank || []}
                  onChange={(e) => {
                    formik.setFieldValue("bank", e.value);
                    formik.setFieldValue("bank", e.value);
                  }}
                />
              </label>

              <TextInput
                label={"Account Number"}
                type="text"
                value={formik.values.accountNumber}
                onChange={formik.handleChange("accountNumber")}
                onBlur={formik.handleBlur("accountNumber")}
                placeholder="0000000000"
                error={
                  formik.touched.accountNumber && formik.errors.accountNumber
                }
              />

              <TextInput
                label={"Email"}
                type="text"
                value={formik.values.email}
                onChange={formik.handleChange("email")}
                onBlur={formik.handleBlur("email")}
                placeholder="example@email.com"
                error={formik.touched.email && formik.errors.email}
              />

              <label
                className=" w-full flex-wrap flex justify-start items-center gap-[12px] leading-[20px] text-[#6c757d] text-sm font-normal "
                htmlFor="private"
              >
                <input
                  required
                  className="w-[20px] appearance-none checked:bg-[#0C4AFF] bg-white h-[20px]  rounded-[6px] border border-[#CDD1D4]     "
                  type="checkbox"
                />
                Make an anonymous donation
              </label>
              <div className="w-full 100: flex-col-reverse 100:gap-[16px] phone:gap-0 phone:flex-row justify-between items-center inline-flex">
                <button
                  onClick={handleNavigation}
                  className="h-12 w-fit px-5 py-3 bg-white rounded-lg shadow border border-[#aab0b6] justify-center items-center gap-2 flex text-[#4a4f54] text-base font-medium  leading-normal"
                >
                  Cancel
                </button>
                <button
                  onClick={formik.handleSubmit}
                  className="leading-[20px] w-fit border-[#0C4AFF] border-solid border-[1px] rounded-[8px]  bg-[#0C4AFF] text-lg text-[#fff] flex justify-center font-medium h-12 px-5 py-3 "
                >
                  Submit
                </button>
              </div>
            </form>
          )}
          {page === 2 && (
            <div className="h-fit w-full p-2.5 flex-col justify-start items-center gap-2 inline-flex">
              <div className=" w-full px-1 py-2.5 justify-between items-start inline-flex">
                <h4 className="text-[#4a4f54] text-sm font-medium ">
                  Funding Goal:
                </h4>
                <h3 className="text-[#4a4f54] text-base font-semibold ">
                  {CurrencyFormatter(
                    parseFloat(
                      educationInformation?.totalFundingRequirement || 0
                    ),
                    "$"
                  )}
                </h3>
              </div>
              <div className="w-full h-fit px-6 py-8 rounded-lg border border-[#cdd1d4] flex-col justify-start items-center gap-4 inline-flex">
                <h2 className="text-[#3a3c3f] text-sm font-semibold  leading-[30px]">
                  Transfer to the account below
                </h2>
                <div className="w-full flex-col justify-start items-center gap-4 inline-flex">
                  <div className=" w-full p-2.5 flex-col justify-start items-start gap-1.5 inline-flex">
                    <div className=" w-fit px-1 py-2.5 justify-start items-start gap-1 inline-flex">
                      <h4 className="text-[#4a4f54] text-sm font-medium ">
                        Bank:
                      </h4>
                      <h5 className="text-[#4a4f54] text-sm font-normal ">
                        {walletDetails?.bank}
                      </h5>
                    </div>
                    <div className=" w-full bg-[#E5E7E8] h-[1px]"></div>
                    <div className=" w-full  px-1 py-2.5 justify-between items-start inline-flex">
                      <div className=" w-full flex justify-start items-center gap-[4px] ">
                        <h4 className="text-[#4a4f54] text-sm font-medium ">
                          Account Number:
                        </h4>
                        <h5 className="text-[#4a4f54] text-sm font-normal ">
                          {walletDetails?.virtualAccountNumber}
                        </h5>
                      </div>
                      <img src={copy} alt="" />
                    </div>
                    <div className=" w-full bg-[#E5E7E8] h-[1px]"></div>
                    <div className=" w-fit px-1 py-2.5 justify-start items-start gap-1 inline-flex">
                      <h4 className="text-[#4a4f54] text-sm font-medium ">
                        Account Name:
                      </h4>
                      <h5 className="text-[#4a4f54] text-sm font-normal ">
                        {walletDetails?.customerName}
                      </h5>
                    </div>
                    <div className=" w-full bg-[#E5E7E8] h-[1px]"></div>
                  </div>
                  <button
                    onClick={handleSubmit}
                    className="leading-[20px] w-fit border-[#0C4AFF] border-solid border-[1px] rounded-[8px]  bg-[#0C4AFF] text-lg text-[#fff] flex justify-center font-medium h-12 px-5 py-3 "
                  >
                    {isLoading ? "Loading..." : "Click here after transfer"}
                  </button>
                </div>
              </div>
              <div className=" w-full p-1 flex-col justify-start items-center gap-4 inline-flex">
                <div className="w-[80%] text-center text-[#3a3c3f] text-xs font-medium  ">
                  <span className="font-medium text-[#4a4f54]">Note:</span>{" "}
                  Please note that a transaction fee will be deducted from the
                  transferred amount.
                </div>
                <div className="text-[#3a3c3f] text-base font-semibold ">
                {formatTime()}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Donate;
