import { ApiClient } from "./apiConfig";

const apiPath = ApiClient();
const UserRequest ={
    getUserDetials: async(email)=>{
        let payload ={
            email 
        }
        let res = {
            status: 0,
            data: {},
            message: "",
          };


        //   

          return await apiPath
          .get(`${process.env.REACT_APP_BASE_URL}/rest/user/get-user-details`)
          .then((response) => {
            res.status = response.data.status;
            res.data = response.data;
            res.message = response.data.message;
            return res;
          })
          .catch((err) => {
            if (err.response) {
              res.status = err.response.status;
              res.data = err.response.data;
            } else {
              res.status = 500;
              res.data = {};
            }
            res.message = err.message;
            return res;
          });

    }, 
    
}

export default UserRequest