import SideBar from "./SideBar";

function ReportAndAnalytics() {
  return (
    <>
      <SideBar />
      <div className=" flex 100:justify-center items-center lg:justify-start flex-col 100:pt-[120px] md:pt-[120px] lg:pt-[40px] md:py-[32px]  lg:pr-8 w-full lg:pl-[332px]"></div>
    </>
  );
}

export default ReportAndAnalytics;
