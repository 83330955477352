import SideBar from "./SideBar";

function Contribution() {
  return (
    <>
      <SideBar />
      <div></div>
    </>
  );
}

export default Contribution;
